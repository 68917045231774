import styles from '../../assets/styles/LandingPage/Footer.module.scss';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import React from 'react';

import {
	FaInstagram,
	FaTwitterSquare,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FashWhiteLogo } from '../../assets/img/ImgList';
import { MimaText } from '../../components';

const Footer = () => {
	const navigate = useNavigate();
	const switchToLogin = () => {
		navigate(constant.Routes.Login);
	};

	const footerNav = [
		{
			main: 'Company',
			subs: [
				{
					title: 'About',
					link: constant.Routes.About,
				},
				{
					title: 'FAQs',
					link: constant.Routes.Faqs,
				},
				{
					title: 'Terms',
					link: constant.Routes.Terms,
				},
				{
					title: 'Privacy',
					link: constant.Routes.Privacy,
				},
			],
		},
		{
			main: 'Menu',
			subs: [
				{
					title: 'Create Style',
					link: constant.Routes.SignUp,
				},
				{
					title: 'Public Catalog',
					link: '#',
				},
				{
					title: 'Save Measurements',
					link: constant.Routes.SignUp,
				},
				{
					title: 'Receive foreign payments',
					link: constant.Routes.SignUp,
				},
			],
		},
		// {
		//   main: 'Legal',
		//   subs: [
		//     {
		//       title: 'Terms',
		//       link: constant.Routes.Terms,
		//     },
		//     {
		//       title: 'Privacy',
		//       link: constant.Routes.Privacy,
		//     },
		//   ],
		// },
	];
	return (
		<footer className={styles.footer}>
			<div className={styles.footer__line}></div>
			<div className={styles.footer__item}>
				<div>
					<img src={FashWhiteLogo} alt="msell Logo" />
				</div>

				<>
					{footerNav.map((nav, i) => (
						<div key={i} className={styles.footer__nav}>
							<div className={styles.footer__nav__title}>{nav.main}</div>
							<ul>
								{nav.subs.map((x, i) => (
									<li key={i}>
										<Link to={x.link}>{x.title}</Link>
									</li>
								))}
							</ul>
						</div>
					))}
				</>

				<div className={styles.footer__socials}>
					{/* <a
						href="https://web.facebook.com/mimabusinessinc"
						target="_blank"
						rel="noreferrer">
						<FaFacebookSquare />
					</a> */}
					<a
						href="https://www.instagram.com/_fashcreatives/"
						target="_blank"
						rel="noreferrer">
						<FaInstagram />
					</a>
					<a
						href="https://twitter.com/_fashcreatives"
						target="_blank"
						rel="noreferrer">
						<FaTwitterSquare />
					</a>
					{/* <a
						href="https://www.linkedin.com/company/trymima/"
						target="_blank"
						rel="noreferrer">
						<FaLinkedin />
					</a> */}
				</div>
			</div>

			<MimaText
				variant="bodyBold"
				color="var(--color-white)"
				mt={5}
				align="center">
				&copy; 2024, Fash Creatives, Powered By Mima 360 Limited
			</MimaText>
		</footer>
	);
};

export default Footer;
