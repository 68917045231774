import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { catalogStore } from '../stores';
import countriesCode from '../utils/countriesCode.json';

const useGeoLocation = () => {
	const { locationInfo, metaDetails, primaryLocation } = catalogStore;

	const [locationData, setLocationData] = useState(
		{ country: locationInfo?.countryCode } || null
	);

	useEffect(() => {
		const locationFn = () => {
			if (locationInfo?.countryCode && primaryLocation) {
				return setLocationData({ country: locationInfo?.countryCode });
			}
			return getLocation();
		};

		locationFn();
	}, [locationInfo, primaryLocation]);

	async function getLocation() {
		try {
			const res = await axios.get('https://api.country.is/');
			if (res.status === 200) {
				setLocationData(res.data);
				catalogStore.setPrimaryLocation(res.data.country);
			}
		} catch (error) {
			if (metaDetails?.localCurrency) {
				const siteOwnerLocal = countriesCode.filter(
					country => country.currencyCode === metaDetails?.localCurrency
				)[0];
				return setLocationData({ country: siteOwnerLocal?.countryCode });
			}

			return setLocationData({ country: 'US' });
		}
	}

	return {
		// city: locationData?.city,
		country: locationData?.country,
		// ip: locationData?.ip,
		// countryCode: locationData?.countryCode,
		// lat: locationData?.lat,
		// lon: locationData?.lon,
		// region: locationData?.regionName,
		// regionCode: locationData?.region,
		// timezone: locationData?.timezone,
		// zip: locationData?.zip,
	};
};

export default useGeoLocation;
