import * as yup from 'yup';
import { handleImageUpload } from '../../utils/utils';

const useEditMode = () => {
	const generateInitialValues = sections => {
		const initialValues = {};

		sections.forEach(section => {
			initialValues[section.id] = {
				...(section.isVisible !== undefined && {
					isVisible: section.isVisible,
				}), // Track visibility for each section
				...(section.name !== undefined && {
					name: section.name,
				}),
			};

			if (section.defaultContent) {
				Object.keys(section.defaultContent).forEach(key => {
					if (
						key === 'imageUrls' &&
						Array.isArray(section.defaultContent[key])
					) {
						initialValues[section.id][key] = section.defaultContent[key].map(
							img => ({
								name: img.imageUrl,
								preview: img.imageUrl,
								originalUrl: img.imageUrl,
							})
						);
					} else if (
						key === 'slides' &&
						Array.isArray(section.defaultContent[key])
					) {
						initialValues[section.id][key] = section.defaultContent[key].map(
							slide => ({
								...slide,
								imageUrls: slide?.imageUrls?.map(img => ({
									name: img.imageUrl,
									preview: img.imageUrl,
									originalUrl: img.imageUrl,
								})),
							})
						);
					} else {
						initialValues[section.id][key] = section.defaultContent[key];
					}
				});
			}
		});

		return initialValues;
	};

	const generateValidationSchema = sections => {
		const schemaFields = {};

		sections.forEach(section => {
			if (section.validation) {
				Object.keys(section.validation).forEach(key => {
					const fieldValidation = section.validation[key];

					switch (fieldValidation.type) {
						case 'string':
							schemaFields[key] = yup.string();
							if (fieldValidation.required) {
								schemaFields[key] = schemaFields[key].required(
									fieldValidation.errorMessage || 'This field is required'
								);
							}
							break;
						case 'image':
							schemaFields[key] = yup.string(); // or yup.mixed() if you're uploading
							if (fieldValidation.required) {
								schemaFields[key] = schemaFields[key].required(
									fieldValidation.errorMessage || 'Image is required'
								);
							}
							break;
						case 'enum':
							schemaFields[key] = yup
								.string()
								.oneOf(
									fieldValidation.options,
									fieldValidation.errorMessage || 'Invalid option'
								);
							if (fieldValidation.required) {
								schemaFields[key] = schemaFields[key].required(
									fieldValidation.errorMessage || 'This field is required'
								);
							}
							break;
						case 'array':
							schemaFields[key] = yup
								.array()
								.of(
									yup
										.object()
										.shape(
											generateValidationSchema([
												{ validation: fieldValidation.items },
											])
										)
								)
								.min(
									fieldValidation.minItems,
									`Minimum ${fieldValidation.minItems} items required`
								)
								.max(
									fieldValidation.maxItems,
									`Maximum ${fieldValidation.maxItems} items allowed`
								);
							break;
						default:
							break;
					}
				});
			}
		});

		return yup.object().shape(schemaFields);
	};

	const convertFormData = (values, name, id) => {
		const sections = Object.keys(values).map(key => {
			const { isVisible, name, ...defaultContent } = values[key];

			// Handle imageUrls conversion inside defaultContent
			const updatedContent = Object.keys(defaultContent).reduce(
				(acc, field) => {
					if (field === 'imageUrls') {
						acc[field] = defaultContent[field].map(urlObj => ({
							imageUrl: urlObj.originalUrl, // same here
						}));
					} else if (Array.isArray(defaultContent[field])) {
						acc[field] = defaultContent[field].map(item => {
							if (item.imageUrls) {
								return {
									...item,
									imageUrls: item.imageUrls.map(urlObj => ({
										imageUrl: urlObj.originalUrl, // or use `name`/`preview` if needed
									})),
								};
							}

							return item;
						});
					} else {
						acc[field] = defaultContent[field];
					}
					return acc;
				},
				{}
			);

			return {
				id: key,
				isVisible,
				name,
				defaultContent: updatedContent,
			};
		});

		return {
			name,
			id,
			sections,
		};
	};

	const findImagesToUpload = data => {
		const imagesToUpload = [];

		// Recursive function to search for imageUrls in any key-value pair
		function searchForImages(
			section,
			sectionKey,
			parentKey = null,
			slideIndex = null
		) {
			if (typeof section !== 'object' || section === null) return;

			Object.keys(section).forEach(key => {
				const value = section[key];

				// Check for imageUrls arrays
				if (key === 'imageUrls' && Array.isArray(value)) {
					value.forEach((image, imgIndex) => {
						if (!image.originalUrl) {
							imagesToUpload.push({
								section: parentKey || sectionKey,
								slideIndex: slideIndex !== null ? slideIndex : undefined,
								imgIndex,
								image,
							});
						}
					});
				}

				// If the current value is an array (e.g., slides or collectionImages), traverse deeper
				if (Array.isArray(value)) {
					value.forEach((item, idx) => {
						searchForImages(item, sectionKey, sectionKey, idx);
					});
				} else if (typeof value === 'object') {
					// Traverse deeper for nested objects
					searchForImages(value, sectionKey, sectionKey, slideIndex);
				}
			});
		}

		// Start searching for imageUrls in the root level data object
		Object.keys(data).forEach(key => {
			searchForImages(data[key], key);
		});

		return imagesToUpload;
	};

	const prepareImagesForUpload = imagesToUpload => {
		return imagesToUpload.map(item => item.image);
	};

	const uploadFiles = async files => {
		return await handleImageUpload(files);
	};

	const linkUploadedUrls = (imagesToUpload, uploadedUrls) => {
		return imagesToUpload.map((item, index) => {
			const uploadedUrl = uploadedUrls[index];
			return {
				...item,
				image: {
					name: uploadedUrl,
					preview: uploadedUrl,
					originalUrl: uploadedUrl,
				},
			};
		});
	};

	const insertUpdatedImages = (updatedImages, values) => {
		updatedImages.forEach(({ section, slideIndex, imgIndex, image }) => {
			if (slideIndex !== undefined) {
				// If it's part of a slide
				values[section].slides[slideIndex].imageUrls[imgIndex] = image;
			} else {
				// If it's part of a regular section with imageUrls array
				values[section].imageUrls[imgIndex] = image;
			}
		});

		return values;
	};

	const contentToKeyValueArray = data => {
		const result = { ...data };

		function convertDefaultContent(obj) {
			if (typeof obj !== 'object' || obj === null) return obj;

			// Loop through the object to find 'defaultContent' keys
			for (const key in obj) {
				if (key === 'defaultContent' && typeof obj[key] === 'object') {
					obj[key] = transformToKeyValue(obj[key]); // Convert the defaultContent
				} else if (typeof obj[key] === 'object') {
					convertDefaultContent(obj[key]); // Recursively handle nested objects
				}
			}
		}

		// Helper function to convert defaultContent to key-value array
		function transformToKeyValue(content) {
			const keyValueArray = [];

			function traverse(obj, parentKey = '') {
				Object.entries(obj).forEach(([key, value]) => {
					const fullKey = parentKey ? `${parentKey}.${key}` : key;

					if (typeof value === 'object' && !Array.isArray(value)) {
						traverse(value, fullKey); // Recursively handle nested objects
					} else if (Array.isArray(value)) {
						// Handle arrays, flattening each item with its index
						value.forEach((item, index) => {
							if (typeof item === 'object') {
								traverse(item, `${fullKey}.${index}`);
							} else {
								keyValueArray.push({ key: `${fullKey}.${index}`, value: item });
							}
						});
					} else {
						// Base case: push primitive value
						keyValueArray.push({ key: fullKey, value: value });
					}
				});
			}

			traverse(content);
			return keyValueArray;
		}

		convertDefaultContent(result); // Initiate the transformation
		return result;
	};

	const keyValueArrayToContent = data => {
		const result = { ...data };

		function restoreDefaultContent(obj) {
			if (typeof obj !== 'object' || obj === null) return obj;

			for (const key in obj) {
				if (key === 'defaultContent' && Array.isArray(obj[key])) {
					obj[key] = restoreFromKeyValueArray(obj[key]); // Convert key-value array back
				} else if (typeof obj[key] === 'object') {
					restoreDefaultContent(obj[key]); // Recursively handle nested objects
				}
			}
		}

		// Helper function to restore the original object/array structure from key-value array
		function restoreFromKeyValueArray(keyValueArray) {
			const restored = {};

			keyValueArray.forEach(({ key, value }) => {
				const keys = key.split('.');

				keys.reduce((acc, currKey, idx) => {
					const isLastKey = idx === keys.length - 1;

					// Detect numeric keys (which indicate arrays)
					const isArray = !isNaN(currKey);

					if (isArray) {
						const index = Number(currKey);
						if (!Array.isArray(acc)) {
							acc = []; // Initialize as array if not already
						}

						if (isLastKey) {
							acc[index] = value; // Assign final value for array element
						} else {
							if (!acc[index]) {
								acc[index] = isNaN(keys[idx + 1]) ? {} : []; // Handle object or array initialization
							}
						}

						return acc[index];
					} else {
						if (isLastKey) {
							acc[currKey] = value; // Assign final value for object property
						} else {
							if (!acc[currKey]) {
								acc[currKey] = isNaN(keys[idx + 1]) ? {} : []; // Initialize next as object or array
							}
						}

						return acc[currKey];
					}
				}, restored);
			});

			return restored;
		}

		restoreDefaultContent(result); // Start restoring the defaultContent
		return result;
	};

	const removeValidationKey = obj => {
		if (typeof obj !== 'object' || obj === null) return obj;

		if (Array.isArray(obj)) {
			return obj.map(removeValidationKey);
		}

		const newObj = {};
		for (const [key, value] of Object.entries(obj)) {
			if (key !== 'validation' && key !== 'compulsory') {
				newObj[key] = removeValidationKey(value);
			}
		}
		return newObj;
	};

	const removeValidation = originalObject => {
		return {
			generalSettings: {
				...originalObject.generalSettings,
				data: originalObject.generalSettings.data.map(removeValidationKey),
			},
			pages: originalObject.pages.map(removeValidationKey),
		};
	};

	const updateContentWithValidation = (objectA, objectB) => {
		function ensureDefaultContentKeys(defaultContent, validation) {
			// Helper function to get default values based on type
			const getDefaultForType = type => {
				switch (type) {
					case 'string':
						return '';
					case 'array':
						return [];
					case 'image':
						return [{ imageUrl: '' }];
					case 'enum':
						return ''; // Default for enums can be empty string or specific value based on context
					default:
						return null;
				}
			};

			// Recursive function to traverse and add defaults
			const addDefaults = (target, schema) => {
				for (const key in schema) {
					if (schema.hasOwnProperty(key)) {
						if (!target.hasOwnProperty(key)) {
							target[key] = getDefaultForType(schema[key].type);
						} else if (
							typeof target[key] === 'object' &&
							target[key] !== null
						) {
							if (Array.isArray(target[key])) {
								target[key].forEach((item, index) => {
									if (schema[key].items) {
										addDefaults(item, schema[key].items);
									}
								});
							} else {
								addDefaults(target[key], schema[key]);
							}
						}
					}
				}
			};

			// Start the recursive default addition
			addDefaults(defaultContent, validation);
		}

		// Update validation and compulsory fields
		function updateSections(sectionsA, sectionsB) {
			sectionsB.forEach(sectionB => {
				const sectionA = sectionsA.find(section => section.id === sectionB.id);
				if (sectionA) {
					// Add missing validation and compulsory fields
					if (!sectionA.validation && sectionB.validation) {
						sectionA.validation = sectionB.validation;
					}
					if (sectionB.compulsory !== undefined) {
						sectionA.compulsory = sectionB.compulsory;
					}

					// Ensure defaultContent has all required keys
					if (sectionB.validation) {
						ensureDefaultContentKeys(
							sectionA.defaultContent,
							sectionB.validation
						);
					}
				}
			});
		}

		function updatePages(pagesA, pagesB) {
			pagesB.forEach(pageB => {
				const pageA = pagesA.find(page => page.id === pageB.id);
				if (pageA) {
					// Update sections within each page
					updateSections(pageA.sections, pageB.sections);
				}
			});
		}

		function updateGeneralSettings(dataA, dataB) {
			dataB.forEach(settingB => {
				const settingA = dataA.find(setting => setting.id === settingB.id);
				if (settingA) {
					// Add missing validation and compulsory fields
					if (!settingA.validation && settingB.validation) {
						settingA.validation = settingB.validation;
					}
					if (settingB.compulsory !== undefined) {
						settingA.compulsory = settingB.compulsory;
					}

					// Ensure defaultContent has all required keys
					if (settingB.validation) {
						ensureDefaultContentKeys(
							settingA.defaultContent,
							settingB.validation
						);
					}
				}
			});
		}

		// Main update function
		function updateObjectA() {
			if (objectA.pages && objectB.pages) {
				updatePages(objectA.pages, objectB.pages);
			}

			if (objectA.generalSettings && objectB.generalSettings) {
				updateGeneralSettings(
					objectA.generalSettings.data,
					objectB.generalSettings.data
				);
			}
		}

		updateObjectA();
		return objectA;
	};

	const removeIdKeys = obj => {
		if (Array.isArray(obj)) {
			return obj.map(removeIdKeys); // Recursively handle arrays
		} else if (typeof obj === 'object' && obj !== null) {
			return Object.keys(obj).reduce((newObj, key) => {
				if (key !== '_id') {
					// Skip the '_id' key
					newObj[key] = removeIdKeys(obj[key]); // Recursively handle nested objects
				}
				return newObj;
			}, {});
		}
		return obj; // Return the value if it's not an array or object
	};

	return {
		generateInitialValues,
		generateValidationSchema,
		convertFormData,
		findImagesToUpload,
		prepareImagesForUpload,
		uploadFiles,
		linkUploadedUrls,
		insertUpdatedImages,
		contentToKeyValueArray,
		keyValueArrayToContent,
		removeValidation,
		updateContentWithValidation,
		removeIdKeys,
	};
};

export default useEditMode;
