import styles from '../../assets/styles/Catalogue/StyleCatalogue.module.scss';
import { MimaText, PageLoader } from '../../components';
import React, { useMemo } from 'react';
import Footer from './Footer';
import { useCatalogUtility } from '../../hooks';
import CatalogHomeHeader from './CatalogHomeHeader';
import DOMPurify from 'dompurify';

const Terms = () => {
	const {
		filtering,
		setFiltering,
		setCollectionQuery,
		data,
		setSearchQuery,
		setCurrencyCode,
		showSearch,
		setShowSearch,
		isLoading,
	} = useCatalogUtility();

	const termsData = useMemo(() => {
		return data?.data?.websiteSettings?.content?.termsOfService || '';
	}, [data]);

	const sanitizedTermsData = useMemo(
		() => DOMPurify.sanitize(termsData),
		[termsData]
	);

	return (
		<main>
			<CatalogHomeHeader
				filtering={filtering}
				setFiltering={setFiltering}
				setCollectionQuery={setCollectionQuery}
				data={data}
				setSearchQuery={setSearchQuery}
				setCurrencyCode={setCurrencyCode}
				showSearch={showSearch}
				setShowSearch={setShowSearch}
			/>

			{isLoading ? (
				<div style={{ marginTop: '15rem' }}>
					<PageLoader />
				</div>
			) : (
				<>
					<div className={styles.content}>
						<MimaText
							variant="h3"
							align="center"
							color="var(--color-dark)"
							mb={3}>
							Terms Of Service
						</MimaText>
						<div dangerouslySetInnerHTML={{ __html: sanitizedTermsData }} />
					</div>

					<Footer data={data} />
				</>
			)}
		</main>
	);
};

export default Terms;
