// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReceiptCheckBox_rcb__Yo-Pn {
  display: inline-flex;
  align-items: center;
  font-size: var(--default-font-size);
  cursor: pointer;
  position: relative;
  max-width: 40rem;
}
@media only screen and (max-width: 37.5em) {
  .ReceiptCheckBox_rcb__Yo-Pn {
    font-size: var(--default-font-small);
  }
}
.ReceiptCheckBox_rcb__input__lkW2Q {
  display: none;
}
.ReceiptCheckBox_rcb__check__rnr3G {
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid var(--color-primary);
  cursor: pointer;
  border-radius: 0.5rem;
  box-sizing: border-box;
  margin-right: 1rem;
  padding: 3px;
}
.ReceiptCheckBox_rcb__check__rnr3G::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--color-primary);
  display: block;
  border-radius: 0.3rem;
  transform: scale(0);
  transition: transform 0.2s;
}
.ReceiptCheckBox_rcb__input__lkW2Q:checked ~ .ReceiptCheckBox_rcb__check__rnr3G::after {
  transform: scale(1);
}
.ReceiptCheckBox_rcb__Yo-Pn:hover .ReceiptCheckBox_rcb__input__lkW2Q ~ .ReceiptCheckBox_rcb__check__rnr3G {
  border: 3px solid var(--color-primary-4);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Components/ReceiptCheckBox.module.scss"],"names":[],"mappings":"AACA;EACC,oBAAA;EACA,mBAAA;EACA,mCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAAD;AAEC;EARD;IASE,oCAAA;EACA;AACF;AACC;EACC,aAAA;AACF;AAEC;EACC,aAAA;EACA,cAAA;EACA,sCAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AAAF;AAEE;EACC,WAAA;EACA,WAAA;EACA,YAAA;EACA,sCAAA;EACA,cAAA;EACA,qBAAA;EAEA,mBAAA;EACA,0BAAA;AADH;AAKC;EACC,mBAAA;AAHF;AAMC;EACC,wCAAA;AAJF","sourcesContent":["@import '../variables/breakpoints';\n.rcb {\n\tdisplay: inline-flex;\n\talign-items: center;\n\tfont-size: var(--default-font-size);\n\tcursor: pointer;\n\tposition: relative;\n\tmax-width: 40rem;\n\n\t@media only screen and (max-width: $bp-600) {\n\t\tfont-size: var(--default-font-small);\n\t}\n\n\t&__input {\n\t\tdisplay: none;\n\t}\n\n\t&__check {\n\t\twidth: 2.5rem;\n\t\theight: 2.5rem;\n\t\tborder: 2px solid var(--color-primary);\n\t\tcursor: pointer;\n\t\tborder-radius: 0.5rem;\n\t\tbox-sizing: border-box;\n\t\tmargin-right: 1rem;\n\t\tpadding: 3px;\n\n\t\t&::after {\n\t\t\tcontent: '';\n\t\t\twidth: 100%;\n\t\t\theight: 100%;\n\t\t\tbackground-color: var(--color-primary);\n\t\t\tdisplay: block;\n\t\t\tborder-radius: 0.3rem;\n\n\t\t\ttransform: scale(0);\n\t\t\ttransition: transform 0.2s;\n\t\t}\n\t}\n\n\t&__input:checked ~ &__check::after {\n\t\ttransform: scale(1);\n\t}\n\n\t&:hover &__input ~ &__check {\n\t\tborder: 3px solid var(--color-primary-4);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rcb": `ReceiptCheckBox_rcb__Yo-Pn`,
	"rcb__input": `ReceiptCheckBox_rcb__input__lkW2Q`,
	"rcb__check": `ReceiptCheckBox_rcb__check__rnr3G`
};
export default ___CSS_LOADER_EXPORT___;
