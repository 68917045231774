import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import styled from 'styled-components';
import * as searchStyle from './styles';
import PropTypes from 'prop-types';
import { BiSearchAlt } from 'react-icons/bi';

const propTypes = {
    placeholder: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(['regular', 'wide']),
    width: PropTypes.any,
    icon: PropTypes.element,
    mt: PropTypes.number,
    mr: PropTypes.number,
    mb: PropTypes.number,
    ml: PropTypes.number,
    error: PropTypes.any,
    touched: PropTypes.any,
    input: PropTypes.array,
    output: PropTypes.func,
    loading: PropTypes.bool,
    handleFilter: PropTypes.func,
    showIcon: PropTypes.bool,
    setSearchQuery: PropTypes.func,
};

const defaultProps = {
    placeholder: '',
    id: '',
    name: '',
    onBlur: () => { },
    disabled: false,
    variant: 'regular',
    width: '',
    loading: false,
    showIcon: true,
    setSearchQuery: () => { },
};

const TableSearch = ({
    filter,
    setFilter,
    labelTitle,
    placeholder,
    onBlur,
    disabled,
    variant,
    width,
    height,
    id,
    name,
    mt,
    mr,
    mb,
    ml,
    loading,
    showIcon,
    setSearchQuery,
    ...props }) => {
    const [value, setValue] = useState(filter)
    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined);
        setSearchQuery(value || undefined);
    }, 1000)
    return (

        <DivSkeleton>
            <SearchSkeleton
                {...props}
                placeholder={placeholder}
                onBlur={onBlur}
                disabled={disabled}
                variant={variant}
                width={width}
                id={id}
                name={name}
                height={height}
                value={value || ''}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
            {showIcon && <SearchIcon><BiSearchAlt /></SearchIcon>}

        </DivSkeleton>
    )
}

const SearchSkeleton = styled.input`
	${searchStyle.base};
	${props => searchStyle[props.variant]};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
	${touched => (touched ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div`
	${searchStyle.divBase};
	${props => searchStyle[props.variant]};
	${props => (props.mt ? `margin-top: ${props.mt}rem` : '')};
	${props => (props.mr ? `margin-right: ${props.mr}rem` : '')};
	${props => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
	${props => (props.ml ? `margin-left: ${props.ml}rem` : '')};
	${props => (props.width ? `width: ${props.width}rem` : '')};
`;

const SearchIcon = styled.div`
    ${searchStyle.iconBase};
`;
TableSearch.propTypes = propTypes;

TableSearch.defaultProps = defaultProps;

export default TableSearch