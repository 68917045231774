import { IoCloseCircle } from 'react-icons/io5';
import GridSearch from '../../components/MimaGridView/GridSearch';
import { motion } from 'framer-motion';
import styles from '../../assets/styles/Catalogue/CatalogSearch.module.scss';
import { useCatalogUtility } from '../../hooks';
import { useMemo } from 'react';
import constant from '../../utils/constant';
import { MimaText } from '../../components';
import { useNavigate } from 'react-router-dom';

const CatalogSearch = ({ showSearchHandler }) => {
	const {
		filtering,
		setFiltering,
		data,
		searchQuery,
		setSearchQuery,
		onLimitChange,
	} = useCatalogUtility();

	const stylesFound = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles || [];
		}
		return [];
	}, [data]);

	const navigate = useNavigate();

	const handleTableClick = row => {
		const navLink = `/catalog/${row.slug}`;
		navigate(navLink);
		showSearchHandler();
	};

	return (
		<motion.div
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
				transition: {
					duration: 0.2,
				},
			}}
			exit={{
				opacity: 0,
				transition: {
					duration: 0.2,
				},
			}}
			className={styles.search__bg}>
			<div className={styles.field}>
				<GridSearch
					filter={filtering}
					setFilter={setFiltering}
					placeholder="Search styles"
					variant="container"
					setSearchQuery={setSearchQuery}
					onLimitChange={onLimitChange}
				/>

				<div onClick={showSearchHandler} className={styles.close}>
					<IoCloseCircle />
				</div>
			</div>
			<div className={styles.search__found}>
				{searchQuery !== '' ? (
					<>
						{stylesFound?.map((style, i) => (
							<div
								className={styles.search__items}
								key={i}
								onClick={() => {
									handleTableClick(style);
								}}>
								<img src={style.imageUrls[0]} alt="Product" />
								<MimaText>{style.name}</MimaText>
							</div>
						))}
					</>
				) : (
					<div>
						<MimaText align="center">Enter Item to search for</MimaText>
					</div>
				)}
			</div>
			<div onClick={showSearchHandler} className={styles.search__empty}></div>
		</motion.div>
	);
};

export default CatalogSearch;
