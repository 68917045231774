import React, { useMemo } from 'react';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import {
	MimaButton,
	MimaInput,
	MimaText,
	MimaDropdown,
} from '../../components';
import { amountFormatter } from '../../utils/utils';
import { Formik } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';

const SingleCatalogDetails = ({
	singleStyle,
	onSubmit,
	loading2,
	loading3,
	validationSchema,
	shippingInfo,
	currencyCode,
}) => {
	const sellingPrice = useMemo(() => {
		return (
			singleStyle?.sellingPrices?.filter(
				x => x?.currencyCode === currencyCode
			)[0] || {}
		);
	}, [singleStyle, currencyCode]);

	return (
		<div className={styles.single__grid__details}>
			<MimaText variant="subtitleBold">{singleStyle?.name}</MimaText>
			<MimaText mb={1}>{singleStyle?.description}</MimaText>
			<div className={styles.h__divider}></div>

			<MimaText mb={2}>Order Outfit Style</MimaText>

			<Formik
				initialValues={{
					amountPerItem:
						sellingPrice?.onSaleSlashPrice > 0
							? sellingPrice?.onSaleSlashPrice
							: sellingPrice?.withMaterial
								? sellingPrice?.withMaterial
								: sellingPrice?.withoutMaterial
									? sellingPrice?.withoutMaterial
									: 0,
					quantity: 1,
					business: singleStyle?.business || {},
					amount:
						sellingPrice?.onSaleSlashPrice > 0
							? sellingPrice?.onSaleSlashPrice
							: sellingPrice?.withMaterial
								? sellingPrice?.withMaterial
								: sellingPrice?.withoutMaterial
									? sellingPrice?.withoutMaterial
									: 0,
					standardSize: '',
					action: '',
					currencyCode,
					name: singleStyle?.name || '',
					styleId: singleStyle?._id || '',
					withMaterial: sellingPrice?.withMaterial
						? 'with'
						: sellingPrice?.withoutMaterial
							? 'without'
							: '0',
					hasSize: singleStyle?.sizesAvailable?.length > 0 ? true : false,
					imageUrl: singleStyle?.imageUrls[0] || '',
					validate: true,
				}}
				enableReinitialize
				onSubmit={onSubmit}
				validationSchema={validationSchema}>
				{({
					handleChange,
					handleBlur,
					setFieldValue,
					handleSubmit,
					values,
					errors,
					touched,
				}) => (
					<div>
						<div className={styles.single__grid2}>
							{sellingPrice?.withoutMaterial ? (
								<div
									className={`${styles.single__price} ${values.withMaterial === 'without' ? styles.single__price__active : ''}`}
									onClick={() => {
										setFieldValue(
											'amountPerItem',
											sellingPrice?.withoutMaterial
										);
										setFieldValue('withMaterial', 'without');
									}}>
									<MimaText variant="small">
										Price with Customer Material
									</MimaText>
									<div>
										{amountFormatter(currencyCode).format(
											sellingPrice?.withoutMaterial
										)}
									</div>
								</div>
							) : (
								''
							)}
							{sellingPrice?.withMaterial ? (
								<div
									className={`${styles.single__price} ${values.withMaterial === 'with' ? styles.single__price__active : ''}`}
									onClick={() => {
										setFieldValue(
											'amountPerItem',
											sellingPrice?.onSaleSlashPrice > 0
												? sellingPrice?.onSaleSlashPrice
												: sellingPrice?.withMaterial
										);
										setFieldValue('withMaterial', 'with');
									}}>
									{sellingPrice?.onSaleSlashPrice > 0 ? (
										<MimaText variant="small">
											<span
												style={{
													textDecoration: 'line-through',
													marginRight: '1rem',
												}}>
												Price
											</span>
											Price On Sale
										</MimaText>
									) : (
										<MimaText variant="small">Price</MimaText>
									)}
									<div>
										{sellingPrice?.onSaleSlashPrice > 0 ? (
											<>
												<div
													style={{
														textDecoration: 'line-through',
														marginRight: '1rem',
														color: 'var(--color-dark)',
														fontSize: '1.4rem',
														textAlign: 'center',
													}}>
													{amountFormatter(currencyCode).format(
														sellingPrice?.withMaterial
													)}
												</div>
												<div
													style={{
														textAlign: 'center',
													}}>
													{amountFormatter(currencyCode).format(
														sellingPrice?.onSaleSlashPrice
													)}
												</div>
											</>
										) : (
											<>
												{amountFormatter(currencyCode).format(
													sellingPrice?.withMaterial
												)}
											</>
										)}
									</div>
								</div>
							) : (
								''
							)}
						</div>
						{errors.amountPerItem ? (
							<MimaText
								variant="small"
								color="var(--color-error)"
								mt={1}
								mb={2}>
								{errors.amountPerItem}
							</MimaText>
						) : (
							''
						)}
						<div className={styles.single__grid2}>
							<MimaInput
								type="number"
								variant="form"
								labelTitle="Quantity"
								placeholder="Enter quantity"
								id="quantity"
								value={values.quantity}
								onChange={e => {
									handleChange(e);
									setFieldValue(
										'amount',
										values.amountPerItem * e.target.value
									);
								}}
								onBlur={handleBlur}
								error={errors.quantity}
								touched={touched.quantity}
								// width={18}
								mb={2}
							/>
							{singleStyle?.sizesAvailable.length > 0 ? (
								<MimaDropdown
									labelTitle="Select Size"
									name="standardSize"
									value={values.standardSize}
									placeholder="Select Size"
									id="standardSize"
									currentValue={values.standardSize}
									onChange={data => {
										setFieldValue('standardSize', data.value);
									}}
									data={singleStyle?.sizesAvailable?.map(x => {
										return {
											value: `${x.country} - Size ${x.value}`,
											key: x._id,
										};
									})}
									touched={touched.standardSize}
									error={errors.standardSize}
									variant={'form'}
									bodyVariant="bodyBase2"
								/>
							) : (
								''
							)}
						</div>
						{sellingPrice?.withMaterial || sellingPrice?.withoutMaterial ? (
							<>
								<MimaText
									variant="subtitleBold"
									mt={2}
									labelTitle="Total amount">
									{amountFormatter(currencyCode).format(
										values.amountPerItem * values.quantity
									)}
								</MimaText>

								{shippingInfo?.shippingFee > 0 ? (
									<MimaText variant="bodyBold" labelTitle="Shipping Fee" mt={2}>
										{values.amountPerItem * values.quantity >
											shippingInfo?.freeShippingForOrderAbove &&
										shippingInfo?.freeShippingForOrderAbove > 0
											? 'Free'
											: amountFormatter(currencyCode).format(
													shippingInfo?.shippingFee
												)}
									</MimaText>
								) : (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '1rem',
											marginTop: '2rem',
										}}>
										<span style={{ fontSize: '3rem' }}>
											<RiErrorWarningFill />
										</span>

										<MimaText variant="bodyBold" color="var(--color-error)">
											Disclaimer this does not include delivery fees
										</MimaText>
									</div>
								)}
							</>
						) : (
							''
						)}

						{(singleStyle.daysRequiredForDelivery.early !== 0 ||
							singleStyle.daysRequiredForDelivery.late !== 0) && (
							<MimaText variant="bodyBold" mt={2} mb={2}>
								Delivery Timeline: {singleStyle.daysRequiredForDelivery.early} -{' '}
								{singleStyle.daysRequiredForDelivery.late} days
							</MimaText>
						)}

						{singleStyle.business.plan === 'Basic Lite' ? (
							<>
								<MimaButton
									title="Send Enquiry"
									onClick={e => {
										setFieldValue('action', 'REQUEST DESIGN');
										setFieldValue('validate', false);
										handleSubmit(e);
									}}
									type="submit"
									loading={loading2}
									variant="form"
									buttonColor="var(--color-fash-primary-2)"
								/>
							</>
						) : (
							<>
								<div className={styles.single__grid2}>
									{sellingPrice?.withMaterial ||
									sellingPrice?.withoutMaterial ? (
										<MimaButton
											title="Order & Pay"
											onClick={e => {
												setFieldValue('action', 'MAKE PAYMENT');
												setFieldValue('validate', true);
												handleSubmit(e);
											}}
											loading={loading2}
											variant="formColorable"
											buttonColor="var(--color-dark)"
										/>
									) : (
										''
									)}

									{sellingPrice?.withMaterial ||
									sellingPrice?.withoutMaterial ? (
										<MimaButton
											title="Add to Cart"
											onClick={e => {
												setFieldValue('action', 'ADD TO CART');
												setFieldValue('validate', true);
												handleSubmit(e);
											}}
											loading={loading2}
											variant="formColorable"
											buttonColor="var(--color-fash-primary-2)"
										/>
									) : (
										''
									)}

									<MimaButton
										title="Send Enquiry"
										onClick={e => {
											setFieldValue('action', 'REQUEST DESIGN');
											setFieldValue('validate', false);
											handleSubmit(e);
										}}
										type="submit"
										loading={loading2}
										variant="outlined2"
									/>

									{singleStyle?.bookingLinkUrl ? (
										<MimaButton
											title="Book consultation"
											type="submit"
											loading3={loading3}
											variant="formColorable"
											buttonColor="var(--color-dark)"
											onClick={e => {
												setFieldValue('action', 'BOOK CONSULTATION');
												handleSubmit(e);
											}}
										/>
									) : (
										''
									)}
								</div>
							</>
						)}
					</div>
				)}
			</Formik>
		</div>
	);
};

export default SingleCatalogDetails;
