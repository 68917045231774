import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';

const propTypes = {
	// labelTitle: PropTypes.string,
	// placeholder: PropTypes.string,
	value: PropTypes.any,
	data: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	// id: PropTypes.string,
	// name: PropTypes.string,
	// onBlur: PropTypes.func,
	// onChange: PropTypes.func,
	// disabled: PropTypes.bool,
	// variant: PropTypes.oneOf(['medium', 'wide', 'small', 'filterForm', 'error']),
	// width: PropTypes.number,
	// height: PropTypes.number,
	// icon: PropTypes.element,
	// styleClass: PropTypes.any,
	// pt: PropTypes.any,
	// pb: PropTypes.any,
	// error: PropTypes.any,
	// touched: PropTypes.any,
	// mb: PropTypes.number,
	// mt: PropTypes.number,
	// mr: PropTypes.number,
	// ml: PropTypes.number,
	// fontSize: PropTypes.number,
	// bodyVariant: PropTypes.oneOf(['bodyBase', 'bodyBase2',]),
	onChangeText: PropTypes.func,
};

const defaultProps = {
	value: '',
	data: [],
	isLoading: false,
	// labelTitle: 'change labelTitle',
	// placeholder: 'change placeholder',
	// id: '',

	// currentValue: '',
	// canSelectAll: false,
	// name: '',
	// onBlur: () => { },
	// onChange: () => { },
	// disabled: false,
	// variant: 'medium',
	// bodyVariant: 'bodyBase',
	onChangeText: text => {},
};

const MimaAsyncDropdown = ({
	value,
	data,
	onChangeText,
	isLoading,
	...props
}) => {
	const [inputText, setInputText] = useState('');

	const handleInputChange = (inputText, meta) => {
		if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {
			setInputText(inputText);
			onChangeText(inputText);
		}
	};

	async function loadOptions(search, loadedOptions, { page }) {
		const response = await fetch(
			`/awesome-api-url/?search=${search}&page=${page}`
		);
		const responseJSON = await response.json();

		return {
			options: responseJSON.results,
			hasMore: responseJSON.has_more,
			additional: {
				page: page + 1,
			},
		};
	}

	return (
		<AsyncPaginate
			value={value}
			options={data}
			// onChange={setValue}
			// loadOptions={loadOptions}
			// additional={{
			// 	page: 1,
			// }}
			getOptionValue={option => option.value}
			getOptionLabel={option => option.value}
		/>

		// <Select
		// 	options={data}
		// 	components={{
		// 		IndicatorSeparator: () => null,
		// 		DropdownIndicator,
		// 	}}
		// 	styles={customStyles}
		// 	formatOptionLabel={formatOptionLabel}
		// 	inputValue={inputText}
		// 	onInputChange={handleInputChange}
		// 	isLoading={!!inputText && isLoading}
		// 	filterOption={null}

	
        
		//     id="companyId"
		// loadOptions={loadOptions}
		// value={value}
		// getOptionValue={(option: any) => option.imdbID}
		// getOptionLabel={(option: any) => option.Title}
		// onChange={setValue}
		// additional={{
		//   page: 1,
		// }}
		// isSearchable={true}
		// placeholder="Select an option"
		// debounceTimeout={500}
		// />
	);
};

const formatOptionLabel = option => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			}}>
			{option.value}
		</div>
	);
};

const customStyles = {
	control: base => ({
		...base,
	}),
	clearIndicator: base => ({
		...base,
		position: 'absolute',
		right: 0,
	}),
	valueContainer: base => ({
		...base,
		paddingRight: '2.3rem',
	}),
};

const DropdownIndicator = props => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator {...props}>
				<SearchIcon />
			</components.DropdownIndicator>
		)
	);
};

const SearchIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 100 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<circle cx="38" cy="40" r="20.5" stroke="currentColor" strokeWidth="7" />
		<path
			d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
			fill="currentColor"
		/>
	</svg>
);

MimaAsyncDropdown.propTypes = propTypes;

MimaAsyncDropdown.defaultProps = defaultProps;

export default MimaAsyncDropdown;
