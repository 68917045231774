import React, {
	// useMemo,
	useRef,
	//   useState
} from 'react';
import PageLoader from '../PageLoader';
// import {
// 	useReactTable,
// 	getCoreRowModel,
// 	flexRender,
// 	getPaginationRowModel,
// 	getSortedRowModel,
// 	getFilteredRowModel,
// } from '@tanstack/react-table';
import { MimaText } from '..';
import { useIntersectionObserver } from '../../hooks';
import { FaArrowDown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import PublicImageSlideshow from '../../pages/PublicCatalogue/PublicImageSlideshow';

const defaultTableData = [
	{
		id: 1,
		first_name: 'Millicent',
		last_name: 'Whatham',
		email: 'mwhatham0@comsenz.com',
		gender: 'Female',
		university: 'Samarkand State University',
	},
	{
		id: 2,
		first_name: 'Siward',
		last_name: 'Amberger',
		email: 'samberger1@behance.net',
		gender: 'Male',
		university: 'Institute of Industrial Electronics Engineering',
	},
	{
		id: 3,
		first_name: 'Sheree',
		last_name: 'Madeley',
		email: 'smadeley2@google.com',
		gender: 'Female',
		university: 'Kateb Institute of Higher Education',
	},
];

const defaultTableColumns = [
	{
		header: 'ID',
		accessorKey: 'id',
	},
	{
		header: 'First Name',
		accessorKey: 'first_name',
	},
	{
		header: 'Last Name',
		accessorKey: 'last_name',
	},
	{
		header: 'Email',
		accessorKey: 'email',
	},
	{
		header: 'Gender',
		accessorKey: 'gender',
	},
	{
		header: 'University',
		accessorKey: 'university',
	},
];

const BouncingArrow = () => {
	return (
		<motion.div
			animate={{ y: [0, 10, 0] }}
			transition={{
				duration: 0.5,
				ease: 'easeInOut',
				repeat: Infinity,
				repeatType: 'loop',
			}}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				marginTop: '2rem',
			}}>
			<div
				style={{
					backgroundColor: 'var(--color-dark)',
					height: '5rem',
					width: '5rem',
					borderRadius: '50%',
					color: 'var(--color-white)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
				<FaArrowDown size={24} />
			</div>
		</motion.div>
	);
};

const MimaMasonryLayout = ({
	tableData = defaultTableData,
	tableColumns = defaultTableColumns,
	onLimitChange,
	isLoading = false,
	emptyMessage = 'No data available or matches Search',
	tableClick,
	//infinite scroll
	fetchNextPage,
	hasNextPage,
	isFetchingNextPage,
	filtering,
	setFiltering,
	...props
}) => {
	// const data = useMemo(() => tableData, [tableData]);
	// const columns = useMemo(() => tableColumns, [tableColumns]);

	// const [sorting, setSorting] = useState([]);

	// const table = useReactTable({
	// 	data,
	// 	columns,
	// 	getCoreRowModel: getCoreRowModel(),
	// 	getPaginationRowModel: getPaginationRowModel(),
	// 	getSortedRowModel: getSortedRowModel(),
	// 	getFilteredRowModel: getFilteredRowModel(),

	// 	onSortingChange: setSorting,
	// 	onGlobalFilterChange: setFiltering,

	// 	state: {
	// 		sorting,
	// 		globalFilter: filtering,
	// 		pagination: {
	// 			pageSize: tableData?.length,
	// 			pageIndex: 0,
	// 		},
	// 	},
	// });

	const handleItemClick = row => {
		if (tableClick) {
			tableClick(row);
		}
	};

	const loadMoreRef = useRef();

	useIntersectionObserver({
		target: loadMoreRef,
		onIntersect: fetchNextPage,
		enabled: hasNextPage,
		rootMargin: '300px',
	});

	return (
		<div>
			<div className="masonryLayout">
				{isLoading ? (
					<PageLoader loading={isLoading} title="while we fetch your data" />
				) : (
					<div className="masonryLayout__container">
						{/* {table.getRowModel().rows.map(row => (
									<div
										key={row.id}
										onClick={() => {
											handleItemClick(row);
										}}
										className="masonryLayout__cell">
										{row.getVisibleCells().map(cell => (
											<div className="masonryLayout__item" key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</div>
										))}
									</div>
								))} */}

						{tableData?.pages?.map(page => (
							<ResponsiveMasonry
								columnsCountBreakPoints={{ 600: 2, 700: 3, 1000: 4 }}
								key={String(page)}>
								<Masonry gutter="3rem">
									{page.status === 'Success'
										? page?.data?.styles.map(item => (
												<div
													key={`${page}-${item?._id}`}
													onClick={() => {
														handleItemClick(item);
													}}
													className="masonryLayout__item">
													<PublicImageSlideshow imageUrls={item?.imageUrls} />
													{item?.name ? (
														<MimaText variant="small" mt={1}>
															{item?.name}
														</MimaText>
													) : (
														''
													)}
													{item?.designerName ? (
														<MimaText variant="small" mt={1}>
															{item?.designerName}
														</MimaText>
													) : (
														''
													)}
												</div>
											))
										: null}
								</Masonry>
							</ResponsiveMasonry>
						))}
					</div>
				)}

				{tableData?.length <= 0 && !isLoading ? (
					<div className="flex flex-col w-full items-center mt-8">
						<img
							src="https://res.cloudinary.com/mima-business/image/upload/v1702949369/mSell/products_empty_g7hnj3.png"
							alt="No products"
							style={{ width: '25rem', height: 'auto' }}
						/>
						<MimaText variant="subtitleBold" mt={2} align="center">
							{emptyMessage}
						</MimaText>
					</div>
				) : (
					''
				)}
			</div>
			<div ref={loadMoreRef} style={{ height: '20px' }} />

			<div>
				{isFetchingNextPage ? (
					<PageLoader type="bar" />
				) : (
					hasNextPage && <BouncingArrow />
				)}
			</div>
		</div>
	);
};

MimaMasonryLayout.displayName = 'MimaMasonryLayout';

export default MimaMasonryLayout;
