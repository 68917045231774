import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from '../../assets/styles/MimaDropzone.module.scss';
import { MdCancel } from 'react-icons/md';
import PropTypes from 'prop-types';
import MimaText from '../MimaText';

const propTypes = {
	labelTitle: PropTypes.string,
	title: PropTypes.string,
	info: PropTypes.string,
	info2: PropTypes.string,
	maxNumOfImages: PropTypes.number,
	files: PropTypes.array,
	name: PropTypes.string,
	setFiles: PropTypes.any,
	errorMsg: PropTypes.string,
};

const MimaDropZone = ({
	title = 'Upload file',
	info,
	info2,
	maxNumOfImages,
	files = [],
	setFiles,
	labelTitle,
	name,
	errorMsg,
}) => {
	const [rejected, setRejected] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles, rejectedFiles) => {
			const filesWithPreview = acceptedFiles.map(file =>
				Object.assign(file, { preview: URL.createObjectURL(file) })
			);

			setFiles([...files, ...filesWithPreview]);

			if (rejectedFiles?.length) {
				setRejected(previousFiles => [...previousFiles, ...rejectedFiles]);
			}
		},
		[files, setFiles]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: {
			'image/*': [],
		},
		maxSize: 1024 * 1000,
		maxFiles: maxNumOfImages,
		onDrop,
	});

	const removeFile = name => {
		setFiles(files.filter(file => file?.name !== name));
	};

	useEffect(() => {
		const removeRejected = () => {
			if (rejected.length > 0) {
				setTimeout(() => {
					setRejected([]);
				}, 5000);
			}
		};

		removeRejected();
	}, [rejected]);

	return (
		<div className={styles.group}>
			<label htmlFor={name}>{labelTitle}</label>
			<div className={styles.outline}>
				{files?.length < maxNumOfImages ? (
					<div {...getRootProps()}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<p>Drop the files here ...</p>
						) : (
							<div className={styles.content}>
								<div className={styles.upload}>{title}</div>
								<p>{info}</p>
								<p>{info2}</p>
							</div>
						)}
					</div>
				) : (
					''
				)}
				<div className={styles.preview}>
					{Array.isArray(files) &&
						files?.map(file => (
							<div key={file?.name}>
								<img
									src={file?.preview ? file?.preview : ''}
									alt={`Preview ${file?.name}`}
									// onLoad={() => {
									//   file?.preview ? URL.revokeObjectURL(file?.preview) : '';
									// }}
									// width={100}
									// height={100}
								/>
								<p>{file?.name}</p>
								<button
									onClick={() => {
										removeFile(file?.name);
									}}
									className={styles.remove}>
									<MdCancel />
								</button>
							</div>
						))}
				</div>

				<div>
					{rejected.map(({ file, errors }) => (
						<div key={file?.name} className={styles.rejected}>
							<p>{file?.name} cannot be uploaded</p>
							{errors.map(error => (
								<p key={error.code} className={styles.rejected__msg}>
									{error.message}
								</p>
							))}
						</div>
					))}
				</div>
			</div>
			{errorMsg ? (
				<MimaText variant="small" color="var(--color-error)" mt={1}>
					{errorMsg}
				</MimaText>
			) : (
				''
			)}
		</div>
	);
};

MimaDropZone.propTypes = propTypes;

export default MimaDropZone;
