import React, { useMemo } from 'react';
import constant from '../../utils/constant';
import { amountFormatter } from '../../utils/utils';
import {
	MaintenancePage,
	MimaButton,
	MimaGridView,
	MimaText,
} from '../../components';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import ImageSlideshow from './ImageSlideshow';
import CatalogHomeHeader from './CatalogHomeHeader';
import Footer from './Footer';

const CatalogHome = ({
	isLoading,
	data,
	isFetching,
	onLimitChange,
	setSearchQuery,
	limit,
	currentPage,
	setCurrentPage,
	handleTableClick,
	filtering,
	setFiltering,
	setCollectionQuery,
	setCurrencyCode,
	currencyCode,
	showSearch,
	setShowSearch,
	shippingInfo,
	goToShop,
}) => {
	const tableData = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles || [];
		}
		return [];
	}, [data]);

	const isMaintenanceMode =
		data?.data?.websiteSettings?.content?.maintenanceMode;

	const tableColumns = [
		{
			header: 'Image',
			accessorKey: 'imageUrls',
			cell: item => {
				const imageUrls = item?.getValue();
				const sellingPrices = item?.row?.original?.sellingPrices;
				const sellingPrice = sellingPrices.filter(
					x => x?.currencyCode === currencyCode
				)[0];
				return imageUrls ? (
					<ImageSlideshow imageUrls={imageUrls} sellingPrice={sellingPrice} />
				) : (
					<span>N/A</span>
				);
			},
		},
		{
			header: 'Product Name',
			accessorKey: 'name',
			cell: item => {
				const name = item?.getValue();

				return <MimaText variant="small">{name}</MimaText>;
			},
		},
		{
			header: 'Selling Price',
			accessorKey: 'sellingPrices',
			cell: item => {
				const sellingPrices = item?.getValue();
				const filteredPrice = sellingPrices.filter(
					x => x?.currencyCode === currencyCode
				)[0];
				const withOutMaterial = filteredPrice?.withoutMaterial;
				const withMaterial = filteredPrice?.withMaterial;
				const shownPrice = () => {
					if (withMaterial > withOutMaterial) {
						return withMaterial;
					} else if (withMaterial < withOutMaterial) {
						return withOutMaterial;
					} else if (withOutMaterial) {
						return withOutMaterial;
					} else if (withMaterial) {
						return withMaterial;
					} else {
						return '';
					}
				};
				const formattedPrice =
					shownPrice() !== ''
						? amountFormatter(currencyCode).format(shownPrice())
						: '';
				const formattedPromoPrice = amountFormatter(currencyCode).format(
					filteredPrice?.onSaleSlashPrice
				);

				return (
					<>
						{filteredPrice?.onSaleSlashPrice > 0 ? (
							<div className={styles.sales}>
								<p className={styles.sales__cancel}>{formattedPrice}</p>
								<p>{formattedPromoPrice}</p>
							</div>
						) : (
							<MimaText>{formattedPrice ? formattedPrice : ''}</MimaText>
						)}
					</>
				);
			},
		},
	];

	const banner = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.websiteSettings?.content?.homeBanner || {};
		}
		return {};
	}, [data]);

	return (
		<>
			{!isMaintenanceMode ? (
				<>
					<section className={styles.catalog}>
						<CatalogHomeHeader
							filtering={filtering}
							setFiltering={setFiltering}
							setCollectionQuery={setCollectionQuery}
							data={data}
							setSearchQuery={setSearchQuery}
							setCurrencyCode={setCurrencyCode}
							showSearch={showSearch}
							setShowSearch={setShowSearch}
						/>

						<div className={styles.catalog__body}>
							{banner?.imageUrl ? (
								<div
									className={styles.catalog__banner}
									style={{
										backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0)), url(${banner?.imageUrl})`,
									}}>
									<div className={styles.catalog__banner__text}>
										{banner?.title}
									</div>
									<MimaButton
										title="SHOP NOW"
										mt={2}
										variant="whiteSquare"
										onClick={goToShop}
									/>
								</div>
							) : (
								''
							)}

							{shippingInfo?.freeShippingForOrderAbove > 0 ? (
								<MimaText align="center" mt={banner?.imageUrl ? 3 : 15}>
									Free Shipping On Orders Over{' '}
									{amountFormatter(currencyCode).format(
										shippingInfo?.freeShippingForOrderAbove
									)}
								</MimaText>
							) : (
								''
							)}
							<div className={styles.catalog__body__inner}>
								<MimaGridView
									tableData={tableData}
									tableColumns={tableColumns}
									searchPlaceholder="Search Styles"
									searchVariant="wide"
									totalItems={data?.data?.totalCounts}
									onLimitChange={onLimitChange}
									isLoading={isLoading}
									isFetching={isFetching}
									limit={limit}
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									setSearchQuery={setSearchQuery}
									emptyMessage="No Styles found or match search"
									tableClick={handleTableClick}
									filtering={filtering}
									setFiltering={setFiltering}
								/>
							</div>
						</div>
						<Footer data={data} />
					</section>
				</>
			) : (
				<MaintenancePage />
			)}
		</>
	);
};

export default CatalogHome;
