import React from 'react';
import styles from '../../assets/styles/RequestOrder/RequestOrder.module.scss';
import {
	MimaButton,
	MimaDateInput,
	MimaInput,
	MimaText,
	MimaTextArea,
	PageLoader,
} from '../../components';
import { Field, FieldArray, Formik } from 'formik';

const FieldInput = ({ field, ...props }) => {
	return <MimaInput {...field} {...props} />;
};

const ImageUploadField = ({ setFieldValue, values }) => {
	const [previews, setPreviews] = React.useState(values.images || []);

	const handleFileChange = event => {
		const files = Array.from(event.target.files);
		const fileURLs = files.map(file => URL.createObjectURL(file));

		const updatedPreviews = [...previews, ...fileURLs];

		if (updatedPreviews.length > 5) {
			alert('You can only upload up to 5 images.');
			return;
		}

		setFieldValue('imageUrls', updatedPreviews);
		setPreviews(updatedPreviews);
	};

	const handleDeleteImage = index => {
		const updatedPreviews = previews.filter((_, i) => i !== index);
		setFieldValue('imageUrls', updatedPreviews);
		setPreviews(updatedPreviews);
	};

	return (
		<div>
			<MimaText variant="body">Upload Images</MimaText>
			<input
				id="imageUrls"
				name="imageUrls"
				type="file"
				accept="image/*"
				multiple
				onChange={handleFileChange}
			/>

			<div className={styles.imagePreviewContainer}>
				{previews.map((src, index) => (
					<div key={index} className={styles.imgContainer}>
						<img
							src={src}
							alt={`preview ${index}`}
							className={styles.imgContainer_imageUpload}
						/>
						<MimaText
							type="button"
							onClick={() => handleDeleteImage(index)}
							className={styles.cancel}>
							&times;
						</MimaText>
					</div>
				))}
			</div>
		</div>
	);
};

const RequestOrder = ({
	onSubmit,
	validationSchemaStage1,
	validationSchemaStage2,
	validationSchemaStage3,
	loading,
	stage,
	goToNextStage,
	goBack,
}) => {
	return (
		<div className={styles.main}>
			<div className={styles.main__content}>
				<div>
					{(stage === 'STAGE2' || stage === 'STAGE3') && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								width: '80%',
							}}>
							<MimaButton title="Go Back" variant="text" onClick={goBack} />
						</div>
					)}

					<MimaText variant="subtitleBold" mb={2} align="center">
						Request Order Reminder
					</MimaText>

					<Formik
						initialValues={{
							customer: {
								fullname: '',
								mobile: '',
								address: '',
							},
							currencyCode: 'NGN',
							amountPaid: 0,
							note: '',
							imageUrls: [],
							dueDate: '',
							shipping: 0,
							items: [
								{
									item: '',
									unitPrice: 0,
									quantity: 1,
								},
							],
						}}
						validationSchema={
							stage === 'STAGE1'
								? validationSchemaStage1
								: stage === 'STAGE2'
									? validationSchemaStage2
									: validationSchemaStage3
						}
						onSubmit={onSubmit}>
						{({
							handleChange,
							handleBlur,
							handleSubmit,
							setFieldValue,
							values,
							errors,
							touched,
							validateForm,
						}) => {
							return (
								<div className={styles.createLinks}>
									<div className={styles.createLinks_1}>
										{loading ? (
											<PageLoader title="While we process your request" />
										) : (
											<div>
												{stage === 'STAGE1' && (
													<>
														<MimaInput
															type="text"
															variant="form2"
															labelTitle="Full Name"
															placeholder="Enter your Name"
															name={`customer.fullname`}
															mb={3}
															value={values?.customer?.fullname}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors?.customer?.fullname}
															touched={touched?.customer?.fullname}
															height={6}
														/>

														<MimaInput
															type="text"
															variant="form2"
															labelTitle="Phone Number"
															placeholder="Enter your number"
															name={`customer.mobile`}
															mb={3}
															value={values?.customer?.mobile}
															onChange={handleChange}
															onBlur={handleBlur}
															error={errors?.customer?.mobile}
															touched={touched?.customer?.mobile}
															height={6}
														/>
														<MimaTextArea
															type="text"
															textAreaVariant="type3"
															labelTitle="Address (Optional)"
															placeholder="Enter your address"
															name={`customer.address`}
															mb={3}
															value={values?.customer?.address}
															onChange={handleChange}
															onBlur={handleBlur}
														/>

														<MimaButton
															title="Continue"
															onClick={() => {
																goToNextStage(validateForm);
															}}
															type="submit"
															loading={loading}
															variant="filterForm"
															mt={2}
															height={6}
														/>
													</>
												)}
												{stage === 'STAGE2' && (
													<>
														<FieldArray name="items">
															{({ push, remove }) => (
																<React.Fragment>
																	{values.items.map((_, i) => (
																		<div key={i}>
																			<div className={styles.beside}>
																				<Field
																					name={`items.${i}.item`}
																					component={FieldInput}
																					variant="form2"
																					type="text"
																					labelTitle="Style Name"
																					touched={touched?.items?.[i]?.item}
																					error={errors?.items?.[i]?.item}
																					height={6}
																				/>
																				<Field
																					name={`items.${i}.unitPrice`}
																					component={FieldInput}
																					variant="form2"
																					type="number"
																					min="0"
																					labelTitle="Agreed Amount (NGN)"
																					touched={
																						touched?.items?.[i]?.unitPrice
																					}
																					error={errors?.items?.[i]?.unitPrice}
																					height={6}
																				/>
																			</div>
																			{i !== 0 ? (
																				<MimaButton
																					title="Remove"
																					variant="filter"
																					type="button"
																					buttonColor="var(--color-error)"
																					width={10}
																					onClick={() => remove(i)}
																				/>
																			) : (
																				''
																			)}
																			<div
																				className={styles.summary__line}></div>
																		</div>
																	))}
																	<MimaButton
																		title="Add Another Style"
																		variant="text"
																		type="button"
																		onClick={() =>
																			push({
																				quantity: 0,
																				unitPrice: 0,
																				item: '',
																			})
																		}
																		width={38}
																	/>
																</React.Fragment>
															)}
														</FieldArray>
														<div className={styles.beside}>
															<MimaInput
																type="number"
																variant="form2"
																labelTitle="Deposite (NGN)"
																placeholder="Enter Deposite"
																name="amountPaid"
																mb={2}
																value={values?.amountPaid}
																onChange={handleChange}
																onBlur={handleBlur}
																error={errors?.amountPaid}
																touched={touched?.amountPaid}
																height={6}
															/>
															<MimaDateInput
																labelTitle="Expected Delivery Due Date"
																type="date"
																name="dueDate"
																value={values.dueDate}
																onChange={text => {
																	setFieldValue('dueDate', text);
																}}
																onBlur={handleBlur}
																touched={touched.dueDate}
																error={errors.dueDate}
																height={6}
															/>
														</div>

														<MimaButton
															title="Continue"
															onClick={() => {
																goToNextStage(validateForm);
															}}
															type="submit"
															loading={loading}
															variant="filterForm"
															mt={3}
															height={6}
														/>
													</>
												)}

												{stage === 'STAGE3' && (
													<>
														<ImageUploadField
															setFieldValue={setFieldValue}
															values={values}
														/>
														<errors name="imageUrls" component="div" />

														<MimaTextArea
															type="text"
															textAreaVariant="type3"
															labelTitle="Comment"
															name="note"
															value={values.note}
															onChange={handleChange}
															onBlur={handleBlur}
															height={10}
															mt={5}
														/>

														<MimaButton
															title="Request Order Reminder"
															onClick={handleSubmit}
															type="submit"
															loading={loading}
															variant="filterForm"
															mt={3}
															height={6}
														/>
													</>
												)}
											</div>
										)}
									</div>
								</div>
							);
						}}
					</Formik>
				</div>
			</div>
		</div>
	);
};
export default RequestOrder;
