import React, { useState } from 'react';
import styles from '../../assets/styles/MimaDropdown.module.scss';

const MimaDropdownFilter = ({
	labelTitle,
	placeholder,
	data,
	canSelectAll,
	onBlur,
	onChange,
	value,
	currentValue,
	variant,
	width,
	id,
	name,
	touched,
	error,
	...props
}) => {
	const [openDropdown, setOpenDropdown] = useState(false);
	const [selected, setSelected] = useState({ key: currentValue || '', value: placeholder });


	const openDropdownHandler = e => {
		setOpenDropdown(!openDropdown);
	};

	const dropDownSetHandler = dropDownOption => {
		setSelected(dropDownOption);
		onChange(dropDownOption);
		setOpenDropdown(false);
	};

	const dropDownOptions = (canSelectAll) ? [{ value: 'ALL', key: '' }, ...data] : data;

	const dropDownList = dropDownOptions.map(item => (
		<div
			key={item.id}
			onClick={() => dropDownSetHandler(item)}
			className={styles.select__options}
		>
			{item.value}
		</div>
	));

	const dropDownList2 = dropDownOptions.map(item => (
		<div
			key={item.id}
			onClick={() => dropDownSetHandler(item)}
			className={styles.select2__options}
		>
			{item.value}
		</div>
	));

	return (
		<>
			<div>
				<label
					className={`${variant !== 'wide'
							? styles.label
							: `${styles.label} ${styles.label__wide}`
						}`}
				>
					{labelTitle}
				</label>
				<div
					className={`${variant !== 'wide'
							? styles.select2__options_selected
							: `${styles.select2__options_selected} ${styles.select2__options_selected__wide}`
						}`}

					onClick={openDropdownHandler}
					style={openDropdown ? { marginBottom: "1rem" } : { marginBottom: "0" }}
				>
					{selected?.value}
				</div>
				{openDropdown && (
					<div
						className={`${variant !== 'wide'
								? styles.select2__options_container
								: `${styles.select2__options_container} ${styles.select2__options_container__wide}`
							} `}
					>

						{dropDownList2}
					</div>
				)}

				{error ? <div className={styles.error}>{touched && error}</div> : null}
			</div>
		</>
	);
};

export default MimaDropdownFilter;