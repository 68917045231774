import styles from '../../assets/styles/MimaFilter/MimaFilter.module.scss';
import React, { useEffect, useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { MimaButton } from '../Button';
import MimaText from '../MimaText';
import PropTypes from 'prop-types';

const propTypes = {
	children: PropTypes.any.isRequired,
	filterClick: PropTypes.func,
	resetClick: PropTypes.func,
	loading: PropTypes.bool,
	noButton: PropTypes.bool,
};

const defaultProps = {
	filterClick: () => {},
	resetClick: () => {},
	loading: false,
	noButton: false,
};

const MimaFilter = ({
	children,
	filterClick,
	resetClick,
	loading,
	noButton,
	...props
}) => {
	const [filterItem, setFilterItem] = useState(false);

	const showFilterItem = () => {
		setFilterItem(!filterItem);
	};

	useEffect(() => {
		setTimeout(() => {
			if (filterItem) {
				setFilterItem(false);
			}
		}, 30000);
	}, [filterItem]);

	return (
		<div className={styles.filter}>
			<button
				className={styles.filter_button}
				type="button"
				onClick={showFilterItem}
			>
				<BiFilterAlt className={styles.filter_button_icon} />{' '}
				{filterItem ? 'Hide Filters ' : 'Show Filters'}
			</button>
			{filterItem && (
				<>
					<div className={styles.filter_line}></div>
					<MimaText variant="smallBold" mb={1} mt={1}>
						{' '}
						Filter Items by:{' '}
					</MimaText>
					<div className={styles.filter_content_group}>
						<div className={styles.filter_content}>{children}</div>

						{!noButton && (
							<div className={styles.filter_content}>
								<MimaButton
									title="Filter"
									onClick={filterClick}
									type="submit"
									loading={loading}
									variant="filter"
									width={18}
								/>
								<MimaButton
									title="Reset"
									onClick={resetClick}
									type="submit"
									variant="filter"
									buttonColor="var(--color-primary)"
									width={18}
									loading={loading}
								/>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

MimaFilter.propTypes = propTypes;

MimaFilter.defaultProps = defaultProps;

export default MimaFilter;
