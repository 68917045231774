import { css } from 'styled-components';

export const wrapper = css`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
export const preview = css`
	width: 4rem;
	height: 4rem;
	border: 2px solid #ccc;
	cursor: pointer;
`;
export const previewWrapper = css`
	display: flex;
	gap: 1rem;
	align-items: center;
	border: 1px solid #ccc;
	padding: 1rem;
	width: 100%;
`;

export const pickerButton = css`
	position: absolute;
	z-index: 2;
	margin-top: 8px;
`;

export const input = css`
	flex: 1;

	&:focus,
	&:focus-visible,
	&:focus-within {
		outline: none;
	}
`;
