import styles from '../../assets/styles/Button.module.scss';
import PulseLoader from 'react-spinners/PulseLoader';
import * as buttonStyles from './styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf([
		'contained',
		'outlined',
		'outlined2',
		'outlined3',
		'text',
		'text2',
		'form',
		'close',
		'small',
		'input',
		'inputClick',
		'smaller',
		'colorable',
		'colorableSmall',
		'filter',
		'filterForm',
		'formColorable',
		'whiteSquare',
		'regular',
	]),
	width: PropTypes.number,
	height: PropTypes.number,
	icon: PropTypes.any,
	buttonColor: PropTypes.string,
	mt: PropTypes.number,
	mr: PropTypes.number,
	mb: PropTypes.number,
	ml: PropTypes.number,
	labelTitle: PropTypes.string,
	titleColor: PropTypes.string,
};

const defaultProps = {
	title: '',
	loading: false,
	type: 'button',
	onClick: () => {},
	disabled: false,
	variant: 'regular',
	icon: null,
	buttonColor: '',
	labelTitle: '',
};

const MimaButton = ({
	title,
	loading,
	disabled,
	icon,
	labelTitle,
	mt,
	mb,
	mr,
	ml,
	width,
	height,
	...props
}) => {
	return (
		<DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width} height={height}>
			{labelTitle !== '' ? (
				<label className={styles.label}>{labelTitle}</label>
			) : null}
			<ButtonSkeleton
				{...props}
				disabled={loading || disabled}
				icon={icon}
				width={width}
				height={height}>
				{loading ? (
					<PulseLoader color={'white'} loading={loading} size={15} />
				) : (
					<>
						{icon} {title}
					</>
				)}
			</ButtonSkeleton>
		</DivSkeleton>
	);
};

const ButtonSkeleton = styled.button`
	${buttonStyles.base};
	${props => buttonStyles[props.variant]};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
	${props =>
		props.buttonColor ? `background-color: ${props.buttonColor}` : ''};
	${props => (props.titleColor ? `color: ${props.titleColor}` : '')};
`;

const DivSkeleton = styled.div`
	${props => (props.mt ? `margin-top: ${props.mt}rem` : '')};
	${props => (props.mr ? `margin-right: ${props.mr}rem` : '')};
	${props => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
	${props => (props.ml ? `margin-left: ${props.ml}rem` : '')};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
`;

MimaButton.propTypes = propTypes;

MimaButton.defaultProps = defaultProps;

export default MimaButton;
