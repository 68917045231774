import React, { useMemo, useState } from 'react';
import styles from '../../assets/styles/Catalogue/StyleCatalogue.module.scss';
import { useCatalogUtility } from '../../hooks';
import CatalogHomeHeader from './CatalogHomeHeader';
import Footer from './Footer';
import { MimaText, PageLoader } from '../../components';

const SizeGuide = () => {
	const [measurementUnit, setMeasurementUnit] = useState('INCHES');

	const switchMeasurementUnit = () => {
		if (measurementUnit === 'INCHES') {
			setMeasurementUnit('CM');
		} else if (measurementUnit === 'CM') {
			setMeasurementUnit('INCHES');
		}
	};
	const {
		filtering,
		setFiltering,
		setCollectionQuery,
		data,
		setSearchQuery,
		setCurrencyCode,
		showSearch,
		setShowSearch,
		isLoading,
	} = useCatalogUtility();

	const femaleSizeChart = [
		{
			size: 'XS',
			UK: '6',
			US: '2',
			EU: '34',
			bust: '33"',
			waist: '25"',
			hips: '35"',
			cmBust: 84,
			cmWaist: 64,
			cmHips: 89,
		},
		{
			size: 'S',
			UK: '8',
			US: '4',
			EU: '36',
			bust: '34"',
			waist: '26"',
			hips: '36"',
			cmBust: 86,
			cmWaist: 66,
			cmHips: 91,
		},
		{
			size: 'M',
			UK: '10',
			US: '6',
			EU: '38',
			bust: '36"',
			waist: '28"',
			hips: '38"',
			cmBust: 91,
			cmWaist: 71,
			cmHips: 97,
		},
		{
			size: 'L',
			UK: '12',
			US: '8',
			EU: '40',
			bust: '38"',
			waist: '30"',
			hips: '40"',
			cmBust: 97,
			cmWaist: 76,
			cmHips: 102,
		},
		{
			size: 'XL',
			UK: '14',
			US: '10',
			EU: '42',
			bust: '40"',
			waist: '32"',
			hips: '42"',
			cmBust: 102,
			cmWaist: 81,
			cmHips: 107,
		},
		{
			size: '2XL',
			UK: '16',
			US: '12',
			EU: '44',
			bust: '42"',
			waist: '34"',
			hips: '44"',
			cmBust: 107,
			cmWaist: 86,
			cmHips: 112,
		},
		{
			size: '3XL',
			UK: '18',
			US: '14',
			EU: '46',
			bust: '44"',
			waist: '36"',
			hips: '46"',
			cmBust: 112,
			cmWaist: 91,
			cmHips: 117,
		},
		{
			size: '4XL',
			UK: '20',
			US: '16',
			EU: '48',
			bust: '46"',
			waist: '38"',
			hips: '48"',
			cmBust: 117,
			cmWaist: 97,
			cmHips: 122,
		},
	];

	const maleSizeData = [
		{
			Sizes: 'XXS',
			US: '30',
			UK: '30',
			EUROPE: '40',
		},
		{
			Sizes: 'XS',
			US: '32',
			UK: '32',
			EUROPE: '42',
		},
		{
			Sizes: 'S',
			US: '34-36',
			UK: '34-36',
			EUROPE: '44-46',
		},
		{
			Sizes: 'M',
			US: '38-40',
			UK: '38-40',
			EUROPE: '48-50',
		},
		{
			Sizes: 'L',
			US: '42-42',
			UK: '42-44',
			EUROPE: '52-54',
		},
		{
			Sizes: 'XL',
			US: '46',
			UK: '46',
			EUROPE: '56',
		},
		{
			Sizes: 'XXL',
			US: '48',
			UK: '48',
			EUROPE: '58',
		},
		{
			Sizes: 'XXXL',
			US: '50',
			UK: '50',
			EUROPE: '60',
		},
	];

	const childrenSizeData = [
		{
			age: '2',
			heightInInches: '36-38',
			heightInCm: '92-98',
			europeanSize: '92-98',
			ukSize: '2-3',
			usSize: '2T',
		},
		{
			age: '3-4',
			heightInInches: '38-41',
			heightInCm: '98-104',
			europeanSize: '98-104',
			ukSize: '3-4',
			usSize: '3T-4T',
		},
		{
			age: '4-5',
			heightInInches: '41-43',
			heightInCm: '104-110',
			europeanSize: '104-110',
			ukSize: '4-5',
			usSize: '5',
		},
		{
			age: '5-6',
			heightInInches: '43-46',
			heightInCm: '110-116',
			europeanSize: '110-116',
			ukSize: '5-6',
			usSize: '6',
		},
		{
			age: '6-7',
			heightInInches: '46-48',
			heightInCm: '116-122',
			europeanSize: '116-122',
			ukSize: '6-7',
			usSize: '6X-7',
		},
		{
			age: '7-8',
			heightInInches: '48-50',
			heightInCm: '122-128',
			europeanSize: '122-128',
			ukSize: '7-8',
			usSize: '7-8',
		},
		{
			age: '8-9',
			heightInInches: '50-53',
			heightInCm: '128-134',
			europeanSize: '128-134',
			ukSize: '8-9',
			usSize: '9-10',
		},
		{
			age: '9-10',
			heightInInches: '53-55',
			heightInCm: '134-140',
			europeanSize: '134-140',
			ukSize: '9-10',
			usSize: '10',
		},
		{
			age: '10-11',
			heightInInches: '55-57',
			heightInCm: '140-146',
			europeanSize: '140-146',
			ukSize: '10-11',
			usSize: '11',
		},
		{
			age: '11-12',
			heightInInches: '57-60',
			heightInCm: '146-152',
			europeanSize: '146-152',
			ukSize: '11-12',
			usSize: '14',
		},
	];

	const sizeGuideData = useMemo(() => {
		return data?.data?.websiteSettings?.content?.sizeGuides || [];
	}, [data]);

	const isFemaleSizeGuide = sizeGuideData.includes('FEMALE');
	const isMaleSizeGuide = sizeGuideData.includes('MALE');
	const isChildSizeGuide = sizeGuideData.includes('CHILD');

	return (
		<main>
			<CatalogHomeHeader
				filtering={filtering}
				setFiltering={setFiltering}
				setCollectionQuery={setCollectionQuery}
				data={data}
				setSearchQuery={setSearchQuery}
				setCurrencyCode={setCurrencyCode}
				showSearch={showSearch}
				setShowSearch={setShowSearch}
			/>
			{isLoading ? (
				<div style={{ marginTop: '15rem' }}>
					<PageLoader />
				</div>
			) : (
				<>
					<div className={styles.content}>
						<MimaText variant="h3" align="center" mb={1}>
							SIZE GUIDE
						</MimaText>

						<div className={styles.sizeSwitch}>
							<div
								className={`${
									measurementUnit === 'INCHES'
										? styles.sizeSwitch__inches
										: styles.sizeSwitch__inches_b
								}`}
								onClick={switchMeasurementUnit}>
								SIZE IN INCHES
							</div>
							<div
								className={`${
									measurementUnit === 'CM'
										? styles.sizeSwitch__cm
										: styles.sizeSwitch__cm_b
								}`}
								onClick={switchMeasurementUnit}>
								SIZE IN CM
							</div>
						</div>
						{isFemaleSizeGuide && (
							<div className="sizeGuideTable">
								<MimaText variant="subtitleBold" mb={1} mt={2}>
									Size Guide For Females
								</MimaText>
								<table>
									<thead>
										<tr>
											<th>Size</th>
											<th>U.K. SIZE</th>
											<th>U.S. SIZE</th>
											<th>EUROPEAN SIZE</th>
											{measurementUnit === 'INCHES' && (
												<>
													<th>Bust (INCHES)</th>
													<th>Waist (INCHES)</th>
													<th>Hips (INCHES)</th>
												</>
											)}
											{measurementUnit === 'CM' && (
												<>
													<th>Bust (CM)</th>
													<th>Waist (CM)</th>
													<th>Hips (CM)</th>
												</>
											)}
										</tr>
									</thead>
									<tbody>
										{femaleSizeChart.map(item => (
											<tr key={item.age}>
												<>
													<td>{item.size}</td>
													<td>{item.UK}</td>
													<td>{item.US}</td>
													<td>{item.EU}</td>
													{measurementUnit === 'INCHES' && (
														<>
															<td>{item.bust}</td>
															<td>{item.waist}</td>
															<td>{item.hips}</td>
														</>
													)}
													{measurementUnit === 'CM' && (
														<>
															<td>{item.cmBust}</td>
															<td>{item.cmWaist}</td>
															<td>{item.cmHips}</td>
														</>
													)}
												</>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
						{isMaleSizeGuide && (
							<div className="sizeGuideTable">
								<MimaText variant="subtitleBold" mb={1} mt={4}>
									Size Guide For Males
								</MimaText>
								<table>
									<thead>
										<tr>
											<th>Size</th>
											<th>U.K. SIZE</th>
											<th>U.S. SIZE</th>
											<th>EUROPEAN SIZE</th>
										</tr>
									</thead>
									<tbody>
										{maleSizeData.map(item => (
											<tr key={item.age}>
												<>
													<td>{item.Sizes}</td>
													<td>{item.UK}</td>
													<td>{item.US}</td>
													<td>{item.EUROPE}</td>
												</>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
						{isChildSizeGuide && (
							<div className="sizeGuideTable">
								<MimaText variant="subtitleBold" mb={1} mt={4}>
									Size Guide For Children
								</MimaText>
								<table>
									<thead>
										<tr>
											<th>Age</th>
											{measurementUnit === 'INCHES' && (
												<>
													<th>CHILD'S HEIGHT (INCHES)</th>
												</>
											)}
											{measurementUnit === 'CM' && (
												<>
													<th>CHILD'S HEIGHT (CM)</th>
												</>
											)}
											<th>U.K. SIZE</th>
											<th>U.S. SIZE</th>
											<th>EUROPEAN SIZE</th>
										</tr>
									</thead>
									<tbody>
										{childrenSizeData.map(item => (
											<tr key={item.age}>
												<>
													<td>{item.age}</td>
													{measurementUnit === 'INCHES' && (
														<>
															<td>{item.heightInInches}</td>
														</>
													)}
													{measurementUnit === 'CM' && (
														<>
															<td>{item.heightInCm}</td>
														</>
													)}
													<td>{item.ukSize}</td>
													<td>{item.usSize}</td>
													<td>{item.europeanSize}</td>
												</>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</>
			)}
			<Footer data={data} />{' '}
		</main>
	);
};
export default SizeGuide;
