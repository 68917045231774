import React, { createContext, useContext, useState } from 'react';
import { useUserTheme } from '../hooks';
import { MetaTags, PageLoader } from '../components';
import { HelmetProvider } from 'react-helmet-async';
import { GrEdit } from 'react-icons/gr';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
	// const [theme, setTheme] = useState('default'); // default theme

	const { theme, setTheme } = useUserTheme();
	const [sectionModal, setSectionModal] = useState(false);
	const [sectionModalId, setSectionModalId] = useState('');

	const toggleTheme = newTheme => {
		setTheme(newTheme);
	};

	const metaTags = [
		{ name: 'keywords', content: 'shop, ecommerce, products, deals' },
	];

	const openSectionModal = sectionId => {
		setSectionModal(true);
		setSectionModalId(sectionId);
	};

	const SectionEditButton = ({ sectionId }) => {
		return (
			<button
				onClick={() => {
					openSectionModal(sectionId);
				}}
				className="sectionEditBtn">
				<GrEdit />
				<span> Edit</span>{' '}
			</button>
		);
	};

	return (
		<ThemeContext.Provider
			value={{
				theme,
				toggleTheme,
				SectionEditButton,
				sectionModal,
				setSectionModal,
				sectionModalId,
				setSectionModalId,
			}}>
			{theme !== '' ? (
				<HelmetProvider>
					<MetaTags metas={metaTags} />
					{children}
				</HelmetProvider>
			) : (
				<div
					style={{
						display: 'flex',
						width: '100vw',
						height: '100vh',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<PageLoader />
				</div>
			)}
		</ThemeContext.Provider>
	);
};

export const useTheme = () => useContext(ThemeContext);
