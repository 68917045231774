import React from 'react';
import { CatalogHome } from '../../pages';
import { useCatalogUtility } from '../../hooks';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';

const CatalogHomeContainer = () => {
	const {
		isLoading,
		data,
		isFetching,
		onLimitChange,
		setSearchQuery,
		limit,
		currentPage,
		setCurrentPage,
		handleTableClick,
		filtering,
		setFiltering,
		setCollectionQuery,
		setCurrencyCode,
		currencyCode,
		showSearch,
		setShowSearch,
		shippingInfo,
	} = useCatalogUtility();

	const navigate = useNavigate();

	const goToShop = () => {
		navigate(constant.Routes.WebsiteShop);
	};

	return (
		<CatalogHome
			isLoading={isLoading}
			data={data}
			isFetching={isFetching}
			onLimitChange={onLimitChange}
			setSearchQuery={setSearchQuery}
			limit={limit}
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			handleTableClick={handleTableClick}
			filtering={filtering}
			setFiltering={setFiltering}
			setCollectionQuery={setCollectionQuery}
			setCurrencyCode={setCurrencyCode}
			currencyCode={currencyCode}
			showSearch={showSearch}
			setShowSearch={setShowSearch}
			shippingInfo={shippingInfo}
			goToShop={goToShop}
		/>
	);
};

export default observer(CatalogHomeContainer);
