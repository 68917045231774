import React, { useState } from 'react';
import { Searchbar2 } from '../Searchbar';
import styles from '../../assets/styles/MimaDropdown.module.scss';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';

const propTypes = {
	labelTitle: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.any,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf(['medium', 'wide', 'small', 'filterForm', 'error']),
	width: PropTypes.number,
	height: PropTypes.number,
	icon: PropTypes.element,
	data: PropTypes.array.isRequired,
	styleClass: PropTypes.any,
	pt: PropTypes.any,
	pb: PropTypes.any,
	error: PropTypes.any,
	touched: PropTypes.any,
	mb: PropTypes.number,
	mt: PropTypes.number,
	mr: PropTypes.number,
	ml: PropTypes.number,
	fontSize: PropTypes.number,
	bodyVariant: PropTypes.oneOf(['bodyBase', 'bodyBase2',]),
};

const defaultProps = {
	labelTitle: 'change labelTitle',
	placeholder: 'change placeholder',
	data: [{ key: 'item 1', value: 'item 1' }, { key: 'item 2', value: 'item 2' }],
	id: '',
	value: '',
	currentValue: '',
	canSelectAll: false,
	name: '',
	onBlur: () => { },
	onChange: () => { },
	disabled: false,
	variant: 'medium',
	bodyVariant: 'bodyBase'
};

const MimaDropdown2 = ({
	labelTitle,
	placeholder,
	data,
	canSelectAll,
	onBlur,
	onChange,
	value,
	currentValue,
	variant,
	width,
	id,
	name,
	touched,
	error,
	...props
}) => {
	const [openDropdown, setOpenDropdown] = useState(false);
	const [selected, setSelected] = useState({ key: currentValue || '', value: placeholder });

	const openDropdownHandler = e => {
		setOpenDropdown(!openDropdown);
	};

	const dropDownSetHandler = dropDownOption => {
		setSelected(dropDownOption);
		onChange(dropDownOption);
		setOpenDropdown(false);
	};

	const dropDownOptions = (canSelectAll) ? [{ value: 'ALL', key: '' }, ...data] : data;
	const [filteredDropdown, setFilteredDropdown] = useState([]);

	const dropDownList = filteredDropdown.map(item => (
		<div
			key={item.id}
			onClick={() => dropDownSetHandler(item)}
			className={styles.select__options}
		>
			{item.value}
		</div>
	));

	return (
		<>
			<div>
				<label
					className={`${variant !== 'wide'
							? styles.label
							: `${styles.label} ${styles.label__wide}`
						}`}
				>
					{labelTitle}
				</label>
				<div
					className={`${variant !== 'wide'
							? styles.select__options_selected
							: `${styles.select__options_selected} ${styles.select__options_selected__wide}`
						}`}
					// ${touched => (touched.id || touched.name ? `color: var(--color-dark)` : '')}
					onClick={openDropdownHandler}
				>
					{selected?.value}
				</div>
				{openDropdown && (
					<div
						className={`${variant !== 'wide'
								? styles.select__options_container
								: `${styles.select__options_container} ${styles.select__options_container__wide}`
							} `}
					>


						<div className="searchInMiddle">
							<Searchbar2
								placeholder='Search list'
								input={toJS(dropDownOptions)}
								loading={false}
								output={setFilteredDropdown}
								handleFilter={(item, searchQuery) => {
									return (
										item?.value
											?.toLowerCase()
											?.includes(searchQuery)
									);
								}}
								width={34}
								showIcon={false}
							/>
						</div>

						{dropDownList}
					</div>
				)}

				{error ? <div className={styles.error}>{touched && error}</div> : null}
			</div>
		</>
	);
};

MimaDropdown2.propTypes = propTypes;

MimaDropdown2.defaultProps = defaultProps;

export default MimaDropdown2;