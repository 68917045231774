import styles from '../../assets/styles/Catalogue/StyleCatalogue.module.scss';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import React, { useMemo } from 'react';
import { userStore } from '../../stores';
import {
	FaFacebookSquare,
	FaInstagram,
	FaTwitterSquare,
	FaLinkedin,
} from 'react-icons/fa';

const Footer = ({ data }) => {
	const navigate = useNavigate();

	const businessLogo = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles?.[0]?.business?.logoUrl || '';
		}
		return '';
	}, [data]);

	const webSettingsContent = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.websiteSettings?.content || {};
		}
		return '';
	}, [data]);

	const webBusinessDetails = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.websiteSettings?.business || {};
		}
		return '';
	}, [data]);

	const footerMenu = useMemo(() => {
		const footerMenu = [
			{
				title: 'Contact Us',
				actionLink: constant.Routes.WebsiteContact,
			},
		];

		if (webSettingsContent?.faqs?.length > 0) {
			footerMenu.unshift({
				title: 'FAQs',
				actionLink: constant.Routes.WebsiteFaqs,
			});
		}

		if (webSettingsContent?.aboutUs?.text) {
			footerMenu.unshift({
				title: 'About',
				actionLink: constant.Routes.WebsiteAbout,
			});
		}
		if (webSettingsContent?.termsOfService) {
			footerMenu.push({
				title: 'Terms Of Service',
				actionLink: constant.Routes.WebsiteTermsOfService,
			});
		}
		if (webSettingsContent?.refundPolicy) {
			footerMenu.push({
				title: 'Refund Policy',
				actionLink: constant.Routes.WebsiteRefundPolicy,
			});
		}

		return footerMenu;
	}, [webSettingsContent]);

	const socialHandles = useMemo(() => {
		const socials = [];

		if (webBusinessDetails?.facebookHandle) {
			socials.push({
				site: 'Facebook',
				link: `https://web.facebook.com/${webBusinessDetails?.facebookHandle}`,
				icon: FaFacebookSquare,
			});
		}
		if (webBusinessDetails?.instagramHandle) {
			socials.push({
				site: 'Instagram',
				link: `https://www.instagram.com/${webBusinessDetails?.instagramHandle}`,
				icon: FaInstagram,
			});
		}
		if (webBusinessDetails?.twitterHandle) {
			socials.push({
				site: 'Twitter',
				link: `https://twitter.com/${webBusinessDetails?.twitterHandle}`,
				icon: FaTwitterSquare,
			});
		}
		if (webBusinessDetails?.linkedinHandle) {
			socials.push({
				site: 'LinkedIn',
				link: `https://www.linkedin.com/${webBusinessDetails?.linkedinHandle}`,
				icon: FaLinkedin,
			});
		}

		return socials;
	}, [webBusinessDetails]);

	const footerMenuHandler = action => {
		userStore.setUserWebsiteData(data);
		navigate(action);
	};

	return (
		<footer className={styles.footer2}>
			<div className={styles.footer2_content}>
				<ul>
					{footerMenu.map((menu, i) => (
						<li
							className={styles.footer2_list}
							key={i}
							onClick={() => {
								footerMenuHandler(menu.actionLink);
							}}>
							{menu.title}
						</li>
					))}
				</ul>

				<div className={styles.footer2__logSoc}>
					{/* <div className={styles.logo}>
						<img
							src={businessLogo}
							className={styles.footer2__logo}
							alt="Business logo"
						/>
					</div> */}
					{socialHandles.length > 0 ? (
						<div className={styles.footer2__socials}>
							{socialHandles.map((social, i) => (
								<a href={social.link} target="_blank" rel="noreferrer" key={i}>
									<social.icon />
								</a>
							))}
						</div>
					) : (
						''
					)}
				</div>
			</div>

			<div className={styles.footer2_copyright}>
				<p>
					&copy; 2024, Powered by{' '}
					<a
						href="https://fashcreatives.com/"
						className={styles.footer2_copyright__link}>
						Fash Creatives
					</a>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
