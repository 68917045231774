import styles from '../../assets/styles/LandingPage/Header.module.scss';
import Testimonials from './Testimonials';
import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import Subscription from './Subscription';
import Features from './Features';
import Features2 from './Features2';
// import { CatalogHomeContainer } from '../../containers';
import constant from '../../utils/constant';
import { PageLoader } from '../../components';
import { useTheme } from '../../context/ThemeContext';

const MainLandingPage = () => {
	const redirectToFashCreatives = () => {
		window.location.href = constant.Routes.FashHome;
	};

	useEffect(() => {
		redirectToFashCreatives();
	}, []);

	return (
		<>
			<Header />
			<Features />
			<Features2 />
			<Subscription />
			<Testimonials />
			<Footer />
		</>
	);
};

const LandingPage = () => {
	const { theme } = useTheme();
	const [ThemeHomeComponent, setThemeHomeComponent] = useState(null);

	const hostname = window.location.hostname;
	const subdomain = hostname.split('.')[0];
	const hostnameParts = hostname.split('.');
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	useEffect(() => {
		const loadThemeHomeComponent = async () => {
			try {
				const themeConfig = await import(
					`../../themes/${theme}/theme.config.js`
				);
				const HomeComponent = themeConfig.default.components.Home;
				setThemeHomeComponent(() => HomeComponent);
			} catch (error) {
				console.error('Failed to load theme home component', error);
				setThemeHomeComponent(null);
			}
		};

		loadThemeHomeComponent();
	}, [theme]);

	return (
		<main className={styles.main}>
			{(hostnameParts.length > 1 && subdomain !== 'www') || isCustomDomain ? (
				// <CatalogHomeContainer />
				ThemeHomeComponent ? (
					<ThemeHomeComponent />
				) : (
					<PageLoader />
				)
			) : (
				<MainLandingPage />
			)}
		</main>
	);
};

export default LandingPage;
