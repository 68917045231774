import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

const DangerousContent = ({ content }) => {
	const sanitizedContent = useMemo(
		() => DOMPurify.sanitize(content),
		[content]
	);
	return (
		<div
			dangerouslySetInnerHTML={{ __html: sanitizedContent }}
			className="sanitized__content"
		/>
	);
};

export default DangerousContent;
