import React, { useState } from 'react';
import styled from 'styled-components';
import { searchStyles } from './styles';
import { BiSearchAlt } from 'react-icons/bi';
import { useAsyncDebounce } from '../../hooks';

const GridSearch = ({
	filter,
	setFilter,
	placeholder,
	onBlur,
	disabled = false,
	variant = 'regular',
	width,
	id,
	name,
	mt,
	mr,
	mb,
	ml,
	loading = false,
	showIcon = true,
	setSearchQuery,
	onLimitChange,
	...props
}) => {
	const [value, setValue] = useState('');

	const debouncedOnChange = useAsyncDebounce(value => {
		// setFilter(value);
		setSearchQuery(value);

		if (onLimitChange) {
			onLimitChange(3);
		}
	}, 1000);

	const onChange = async event => {
		const { value } = event.target;
		setValue(value);
		await debouncedOnChange(value);
	};

	return (
		<DivSkeleton>
			<SearchSkeleton
				{...props}
				placeholder={placeholder}
				onBlur={onBlur}
				disabled={disabled}
				variant={variant}
				width={width}
				id={id}
				name={name}
				value={value || ''}
				onChange={onChange}
			/>
			{showIcon && (
				<SearchIcon>
					<BiSearchAlt />
				</SearchIcon>
			)}
		</DivSkeleton>
	);
};

const SearchSkeleton = styled.input`
	${searchStyles.base};
	${props => props.variant && searchStyles[props.variant]};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
	${touched => (touched ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div`
	${searchStyles.divBase};
	${props => props.variant && searchStyles[props.variant]};
	${props => (props.mt ? `margin-top: ${props.mt}rem` : '')};
	${props => (props.mr ? `margin-right: ${props.mr}rem` : '')};
	${props => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
	${props => (props.ml ? `margin-left: ${props.ml}rem` : '')};
	${props => (props.width ? `width: ${props.width}rem` : '')};
`;

const SearchIcon = styled.div`
	${searchStyles.iconBase};
`;

export default GridSearch;
