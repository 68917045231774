import React from 'react';
import {
	MimaButton,
	MimaInput,
	MimaPhoneInput,
	MimaText,
	MimaTextArea,
	Modal,
} from '../../components';
import { Formik } from 'formik';
import { catalogStore } from '../../stores';

const RDModal = ({ closeModal, loading, validationSchema, onSubmit }) => {
	const { catalogOrderInfo } = catalogStore || {};

	return (
		<Modal closeModal={closeModal}>
			<Formik
				initialValues={{
					fullname: '',
					mobile: '',
					email: '',
					note: '',
					quantity: 0,
					withMaterial: true,
					instagramHandle: '',
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					values,
					errors,
					touched,
				}) => (
					<>
						<MimaText
							variant="subtitleBold"
							// color="var(--color-fash-primary-2)"
							mb={4}
							width={38}
							align="center">
							Request {catalogOrderInfo.name} Design
						</MimaText>
						<MimaInput
							labelTitle="Full Name"
							type="text"
							placeholder="Firstname Lastname"
							id="fullname"
							name="fullname"
							value={values.fullname}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.fullname}
							touched={touched.fullname}
						/>

						{/* <MimaInput
							labelTitle="Phone Number"
							type="tel"
							placeholder="Enter your mobile number"
							id="mobile"
							name="mobile"
							value={values.mobile}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.mobile}
							touched={touched.mobile}
						/> */}

						<MimaPhoneInput
							labelTitle="Phone Number"
							type="tel"
							placeholder="Enter your mobile number"
							id="mobile"
							name="mobile"
							value={values.mobile}
							onChange={value => {
								setFieldValue('mobile', value);
							}}
							onBlur={handleBlur}
							error={errors.mobile}
							touched={touched.mobile}
							mb={2}
						/>

						<MimaInput
							labelTitle="Email"
							type="text"
							placeholder="Enter your Email"
							id="email"
							name="email"
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.email}
							touched={touched.email}
						/>

						<MimaInput
							labelTitle="Instagram Handle"
							type="text"
							placeholder="Instagram Handle"
							id="instagramHandle"
							name="instagramHandle"
							value={values.instagramHandle}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors.instagramHandle}
							touched={touched.instagramHandle}
						/>

						<MimaTextArea
							labelTitle="Note"
							placeholder="Note"
							name="note"
							id="note"
							value={values.note}
							onChange={handleChange}
							onBlur={handleBlur}
							touched={touched.note}
							error={errors.note}
						/>

						<MimaButton
							title="Send Enquiry"
							type="submit"
							loading={loading}
							onClick={handleSubmit}
						/>
					</>
				)}
			</Formik>
		</Modal>
	);
};

export default RDModal;
