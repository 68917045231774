import React from 'react';
import {
	MimaButton,
	MimaText,
	MimaInput,
	MimaTextArea,
	MimaDropdown,
	MimaPhoneInput,
	PoweredBy,
	// ReceiptCheckBox as RCB,
} from '../../components';
import styles from '../../assets/styles/PaymentLink/PaymentLinkCheckout.module.scss';
import { Formik } from 'formik';
import countries from '../../utils/countries.json';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import FxTransfer from './FxTransfer';

const DeliveryForm = ({
	validationSchema,
	onSubmit,
	loading,
	clientSessionId,
	stripePromise,
	onSuccess,
	onClose,
	goBack,
	foreignAccount,
	invoice,
	catalogOrderInfo,
	primaryLocation,
}) => {
	return (
		<>
			{stripePromise && clientSessionId ? (
				<>
					<Elements
						stripe={stripePromise}
						options={{ clientSecret: clientSessionId }}>
						<StripeCheckoutForm
							onSuccess={onSuccess}
							onClose={onClose}
							goBack={goBack}
						/>
					</Elements>
				</>
			) : foreignAccount?.currencyCode ? (
				<>
					<FxTransfer
						foreignAccount={foreignAccount}
						invoice={invoice}
						onSuccess={onSuccess}
						goBack={goBack}
					/>
				</>
			) : (
				<Formik
					initialValues={{
						firstname: '',
						lastname: '',
						email: '',
						mobile: '',
						country:
							catalogOrderInfo[0]?.currencyCode === 'NGN' ? 'Nigeria' : '',
						street: '',
						company: '',
						apartment: '',
						postalCode: '',
						city: '',
					}}
					validationSchema={validationSchema}
					onSubmit={onSubmit}>
					{({
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldValue,
						values,
						errors,
						touched,
					}) => {
						return (
							<div className={styles.checkout__form}>
								<MimaText variant="subtitleBold">Contact</MimaText>
								<MimaInput
									labelTitle="Your Email Address"
									type="email"
									variant="checkoutForm"
									placeholder="Your email address"
									name="email"
									id="email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.email}
									error={errors.email}
								/>

								<MimaText variant="subtitleBold" mt={2}>
									Delivery
								</MimaText>
								<MimaDropdown
									labelTitle="Country"
									name="country"
									value={values.country}
									placeholder="Select Country"
									id="country"
									currentValue={values.country}
									onChange={data => {
										setFieldValue('country', data.key);
									}}
									data={countries.map(value => {
										return {
											key: value.country,
											value: value.country,
										};
									})}
									touched={touched.country}
									error={errors.country}
									variant="checkoutForm"
									bodyVariant="bodyBase3"
									disabled={
										catalogOrderInfo[0]?.currencyCode === 'NGN' ? true : false
									}
								/>
								<div className={styles.checkout__form__beside}>
									<MimaInput
										labelTitle="First Name"
										variant="checkoutForm"
										placeholder="First Name"
										name="firstname"
										id="firstname"
										value={values.firstname}
										onChange={handleChange}
										onBlur={handleBlur}
										touched={touched.firstname}
										error={errors.firstname}
									/>
									<MimaInput
										labelTitle="Last Name"
										variant="checkoutForm"
										placeholder="Last Name"
										name="lastname"
										id="lastname"
										value={values.lastname}
										onChange={handleChange}
										onBlur={handleBlur}
										touched={touched.lastname}
										error={errors.lastname}
									/>
								</div>

								<MimaInput
									labelTitle="Company (Optional)"
									variant="checkoutForm"
									placeholder="Company (Optional"
									name="company"
									id="company"
									value={values.company}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.company}
									error={errors.company}
								/>

								<MimaTextArea
									labelTitle="Address"
									textAreaVariant="type3"
									placeholder="Enter Address"
									name="street"
									id="street"
									value={values.street}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.street}
									error={errors.street}
								/>
								<MimaTextArea
									labelTitle="Apartment, suite, etc (optional)"
									textAreaVariant="type3"
									placeholder="Apartment"
									name="apartment"
									id="apartment"
									value={values.apartment}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.apartment}
									error={errors.apartment}
								/>

								<div className={styles.checkout__form__beside}>
									<MimaInput
										labelTitle="Postal code"
										variant="checkoutForm"
										placeholder="Postal code"
										name="postalCode"
										id="postalCode"
										value={values.postalCode}
										onChange={handleChange}
										onBlur={handleBlur}
										touched={touched.postalCode}
										error={errors.postalCode}
									/>
									<MimaInput
										labelTitle="City"
										variant="checkoutForm"
										placeholder="City"
										name="city"
										id="city"
										value={values.city}
										onChange={handleChange}
										onBlur={handleBlur}
										touched={touched.city}
										error={errors.city}
									/>
								</div>

								<MimaPhoneInput
									labelTitle="Your Phone Number"
									type="tel"
									placeholder="Enter your mobile number"
									id="mobile"
									name="mobile"
									error={errors.mobile}
									touched={touched.mobile}
									value={values.mobile}
									onChange={value => {
										setFieldValue('mobile', value);
									}}
									onBlur={handleBlur}
									variant="form"
									defaultCountry={primaryLocation || 'NG'}
								/>

								<MimaButton
									title="Make Payment"
									onClick={handleSubmit}
									variant="form"
									mt={2}
									loading={loading}
								/>

								<PoweredBy />
							</div>
						);
					}}
				</Formik>
			)}
		</>
	);
};

export default DeliveryForm;
