import { object, string } from 'yup';
import { PaymentCheckout } from '../../pages';
import { catalogStore } from '../../stores';
import React, { useEffect, useMemo, useState } from 'react';
import { MimaToastUtil } from '../../components';
import constant from '../../utils/constant';
import * as yup from 'yup';
import { phoneRegExp, removeEmptyKeys } from '../../utils/utils';
import { StyleCatalogController } from '../../controllers';
import { useNavigate } from 'react-router-dom';
import { usePaystackPayment } from 'react-paystack';
import { loadStripe } from '@stripe/stripe-js';

const PaymentCheckoutContainer = () => {
	const [loading, setLoading] = useState(false);
	const [invoice, setInvoice] = useState({});
	const [clientSessionId, setClientSessionId] = useState('');
	const [foreignAccount, setForeignAccount] = useState({});

	const { catalogOrderInfo, primaryLocation, setCatalogOrderInfo } =
		catalogStore || {};

	const validationSchema = () => {
		return object({
			firstname: yup
				.string()
				.min(2, 'First name is required')
				.required('First name is required'),
			lastname: yup
				.string()
				.min(2, 'Last name is required')
				.required('Last name is required'),
			email: string()
				.trim()
				.email('Enter a valid Email')
				.required('Email Address is required'),
			mobile: yup
				.string()
				.matches(phoneRegExp, 'Phone number is not valid')
				.required('phone number is required'),
			street: yup.string().required('Address is required'),
			country: yup.string().required('Country is required'),
			postalCode: yup.string().required('Postal code is required'),
			company: yup.string().optional(),
			apartment: yup.string().optional(),
			city: yup.string().required('City is required'),
		});
	};

	const paystackConfig = useMemo(() => {
		const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

		const metadata = {
			business: invoice?.business?._id,
			invoiceNumber: `INV-${invoice?.number}`,
			customer: { fullname: invoice?.customer?.fullname },
			invoice: invoice?._id,
			type: 'INVOICE',
		};

		const config = {
			email: invoice?.customer?.email,
			amount: invoice?.transactionAmount * 100,
			metadata,
			publicKey,
		};
		return config;
	}, [invoice]);

	const onSuccess = () => {
		if (foreignAccount?.code) {
			MimaToastUtil.success({
				message:
					'Payment is in verification, Your order will be processed once confirmed, Check your email for confirmation',
			});
		} else {
			MimaToastUtil.success({
				message:
					'Payment made Successfully, Your order is being processed, Check your email for confirmation',
			});
		}

		localStorage.removeItem('fashCart');
		setCatalogOrderInfo({});
		return navigate(constant.Routes.Home);
	};

	const onClose = () => {
		return MimaToastUtil.error({ message: 'Payment Failed, Try again' });
	};

	const initializePaystackPayment = usePaystackPayment(paystackConfig);

	const sPublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
	const stripePromise = loadStripe(sPublicKey);

	useEffect(() => {
		if (invoice?.currencyCode === 'NGN') {
			initializePaystackPayment(onSuccess, onClose);
		}
	}, [invoice]);

	const onSubmit = async values => {
		setLoading(true);

		const orders = catalogOrderInfo.map(item => {
			return {
				item: item?.name,
				style: item?.styleId,
				standardSize: item?.standardSize,
				quantity: item?.quantity,
				unitPrice: item?.amountPerItem,
				color: item?.color,
				accessories: item?.accessories,
			};
		});

		removeEmptyKeys(orders);

		const payload = {
			customer: {
				fullname: `${values.firstname} ${values.lastname}`,
				email: values.email,
				mobile: values.mobile,
				street: `${values.street} ${values.apartment}`,
				country: values.country,
				companyName: values.company,
				postCode: values.postalCode,
				state: values.city,
			},
			isFash: true,
			publicKey: catalogOrderInfo[0].business.publicKey,
			invoice: {
				orders: [...orders],
				orderId: catalogOrderInfo[0].styleId,
				currencyCode: catalogOrderInfo[0].currencyCode,
			},
		};

		removeEmptyKeys(payload);

		const { status, errorMessage, data } =
			await StyleCatalogController.createCataloguePaymentCheckout(payload);

		if (status === constant.Success) {
			// MimaToastUtil.success({
			// 	message: 'Navigating to Payment Page',
			// });
			setInvoice(data);
			if (data?.stripeSessionId) {
				setClientSessionId(data?.stripeSessionId);
			}
			if (data?.foreignAccount?.id?.metadata?.data?.length > 0) {
				setForeignAccount(data?.foreignAccount);
			}

			setLoading(false);
		} else {
			setLoading(false);
			MimaToastUtil.error({ message: errorMessage });
		}
	};

	const navigate = useNavigate();

	const goToCart = () => {
		navigate(constant.Routes.WebsiteCart);
	};
	const goToHome = () => {
		navigate(constant.Routes.Home);
	};

	const goBack = () => {
		setClientSessionId('');
		setForeignAccount({});
	};

	return (
		<PaymentCheckout
			loading={loading}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			catalogOrderInfo={catalogOrderInfo}
			goToCart={goToCart}
			invoice={invoice}
			goToHome={goToHome}
			clientSessionId={clientSessionId}
			stripePromise={stripePromise}
			onSuccess={onSuccess}
			onClose={onClose}
			goBack={goBack}
			foreignAccount={foreignAccount}
			primaryLocation={primaryLocation}
		/>
	);
};

export default PaymentCheckoutContainer;
