import React, { useEffect, useMemo, useState } from 'react';
import { SinglePublicCatalogue } from '../../pages';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import { usePublicCatalogUtility } from '../../hooks';
import { catalogStore } from '../../stores';
import { observer } from 'mobx-react';

const SinglePublicCatalogueContainer = () => {
	const [rdModal, setRDModal] = useState(false);
	const closeModal = () => {
		setRDModal(false);
	};

	const navigate = useNavigate();

	const goToCatalogHome = () => {
		return navigate(constant.Routes.Home);
	};
	const goBackPublic = () => {
		return navigate(constant.Routes.PublicCatalogue);
	};

	const {
		isLoading,
		data,
		filtering,
		setFiltering,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		setSearchQuery,
		searchQuery,
		subdomain,
	} = usePublicCatalogUtility();

	const { setOnePublicCatalog, onePublicCatalog } = catalogStore;

	const handleTableClick = item => {
		window.scrollTo(0, 0);
		setOnePublicCatalog(item);
		const navLink = `${constant.Routes.SinglePublicCatalogue}?${item?._id}`;
		navigate(navLink);
	};

	const requestDesign = () => {
		catalogStore.setCatalogOrderInfo(onePublicCatalog);
		setRDModal(true);
	};

	const hashString = useMemo(() => {
		return onePublicCatalog?.hashTags.length > 0
			? onePublicCatalog?.hashTags.join(' ')
			: '';
	}, [onePublicCatalog]);

	const relatedItems = onePublicCatalog => {
		if (onePublicCatalog?.hashTags.length > 0) {
			const searchWith = hashString.split(' ');
			setSearchQuery(searchWith[0]);
		} else if (onePublicCatalog?.name) {
			const nameSearch = onePublicCatalog?.name.split(' ');
			setSearchQuery(nameSearch[0]);
		}
	};

	useEffect(() => {
		relatedItems(onePublicCatalog);
	}, [onePublicCatalog?._id]);

	return (
		<SinglePublicCatalogue
			isLoading={isLoading}
			data={data}
			goToCatalogHome={goToCatalogHome}
			filtering={filtering}
			setFiltering={setFiltering}
			handleTableClick={handleTableClick}
			fetchNextPage={fetchNextPage}
			hasNextPage={hasNextPage}
			isFetchingNextPage={isFetchingNextPage}
			setSearchQuery={setSearchQuery}
			searchQuery={searchQuery}
			onePublicCatalog={onePublicCatalog}
			requestDesign={requestDesign}
			rdModal={rdModal}
			closeModal={closeModal}
			hashString={hashString}
			goBackPublic={goBackPublic}
			subdomain={subdomain}
		/>
	);
};

export default observer(SinglePublicCatalogueContainer);
