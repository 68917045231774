import {
	IoMdSettings,
	IoMdLogOut,
	IoMdEye,
	IoMdEyeOff,
	IoIosCloudDownload,
	IoMdRemoveCircleOutline,
	IoMdAddCircleOutline,
} from 'react-icons/io';
import styles from '../../assets/styles/Components/IconButton.module.scss';
import PropTypes from 'prop-types';
import React from 'react';
import { VscBellDot } from 'react-icons/vsc';
import { BiBell } from 'react-icons/bi';

const propTypes = {
	loading: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'submit', 'reset']),
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf([
		'settings',
		'logout',
		'eyeOpen',
		'eyeClose',
		'download',
		'add',
		'remove',
		'notification',
		'notificationActive',
	]),
};

const defaultProps = {
	loading: false,
	type: 'button',
	onClick: () => {},
	disabled: false,
	variant: 'settings',
};

const IconButton = ({ type, onClick, variant, ...props }) => {
	return (
		<div className={styles.icon} onClick={onClick}>
			{variant == 'settings' && <IoMdSettings />}
			{variant == 'logout' && <IoMdLogOut />}
			{variant == 'eyeOpen' && <IoMdEye />}
			{variant == 'eyeClose' && <IoMdEyeOff />}
			{variant == 'remove' && <IoMdRemoveCircleOutline />}
			{variant == 'add' && <IoMdAddCircleOutline />}
			{variant == 'download' && <IoIosCloudDownload />}
			{variant == 'notification' && <BiBell />}
			{variant == 'notificationActive' && <VscBellDot />}
		</div>
	);
};

IconButton.propTypes = propTypes;

IconButton.defaultProps = defaultProps;

export default IconButton;
