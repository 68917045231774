import React from 'react';
import styles from '../../assets/styles/PublicCatalogue/PublicCatalogue.module.scss';
import PublicCatalogueSearch from './PublicCatalogueSearch';
import { MimaButton, MimaMasonryLayout, MimaText } from '../../components';
import { useMediaQuery } from 'react-responsive';
import { IoMdHome } from 'react-icons/io';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Avatar from '../../assets/img/Mima-avatar.png';
import { AnimatePresence } from 'framer-motion';
import { RDModalContainer } from '../../containers';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';

const SinglePublicCatalogue = ({
	searchQuery,
	setSearchQuery,
	isLoading,
	data,
	goToCatalogHome,
	handleTableClick,
	fetchNextPage,
	hasNextPage,
	isFetchingNextPage,
	onePublicCatalog,
	requestDesign,
	rdModal,
	closeModal,
	hashString,
	goBackPublic,
	subdomain,
}) => {
	const isBelow500 = useMediaQuery({
		query: '(max-width: 500px)',
	});

	return (
		<main>
			<header className={styles.pch}>
				<button className={styles.gtmw} onClick={goToCatalogHome}>
					{isBelow500 ? (
						<IoMdHome style={{ fontSize: '3rem' }} />
					) : (
						<span> Go to Main Website </span>
					)}
				</button>
				<PublicCatalogueSearch setSearchQuery={setSearchQuery} />
			</header>
			<div className={styles.pcback} onClick={goBackPublic}>
				<BsFillArrowLeftCircleFill />
				Back to all Public Catalogue Styles
			</div>
			<section className={styles.spc}>
				<div className={styles.spc__content}>
					<LazyLoadImage
						alt="Style"
						src={onePublicCatalog?.imageUrls[0]}
						effect="blur"
					/>
					<div>
						<div>
							{onePublicCatalog?.name ? (
								<MimaText variant="h3" mt={3}>
									{onePublicCatalog?.name}
								</MimaText>
							) : (
								''
							)}
							{hashString ? (
								<MimaText
									variant="subtitle"
									mt={1}
									mb={3}
									color="var(--color-primary-4)">
									{hashString}
								</MimaText>
							) : (
								''
							)}
							{onePublicCatalog?.designerName ? (
								<div
									style={{
										display: 'flex',
										gap: '1rem',
										alignItems: 'center',
										marginBottom: '3rem',
									}}>
									<img src={Avatar} alt="Avatar" style={{ width: '7rem' }} />
									<MimaText variant="subtitle">
										{onePublicCatalog?.designerName}
									</MimaText>
								</div>
							) : (
								''
							)}
						</div>
						<MimaButton
							title="Send Enquiry for this Style"
							onClick={requestDesign}
							variant="contained"
							buttonColor="var(--color-dark)"
							mb={3}
							mt={2}
						/>
					</div>
				</div>
			</section>
			<section className={styles.pcb}>
				<MimaMasonryLayout
					tableData={data}
					// tableColumns={tableColumns}
					searchPlaceholder="Search Styles"
					isLoading={isLoading}
					setSearchQuery={setSearchQuery}
					emptyMessage="No Styles found or match search"
					tableClick={handleTableClick}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			</section>
			{rdModal && (
				<div className="modal">
					<AnimatePresence>
						{rdModal && (
							<RDModalContainer closeModal={closeModal} subdomain={subdomain} />
						)}
					</AnimatePresence>
				</div>
			)}
		</main>
	);
};

export default SinglePublicCatalogue;
