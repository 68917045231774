import React from // useEffect,
// useMemo,
//  useState
'react';
// import { usePublicCatalogUtility } from '../../hooks';
// import { useNavigate } from 'react-router-dom';
import GridSearch from '../../components/MimaGridView/GridSearch';
// import { motion } from 'framer-motion';
import styles from '../../assets/styles/Catalogue/CatalogSearch.module.scss';
// import { MimaText } from '../../components';

const PublicCatalogueSearch = ({ setSearchQuery }) => {
	// const { data, onLimitChange, searchQuery, setSearchQuery, status } =
	// 	usePublicCatalogUtility();

	// const publicStylesFound = useMemo(() => {
	// 	if (status === 'success') {
	// 		return data?.pages?.flatMap(page => page?.data?.styles) || [];
	// 	}
	// 	return [];
	// }, [data, status]);

	// const navigate = useNavigate();

	// const handleTableClick = row => {
	// 	// const navLink = `/catalog/${row.slug}`;
	// 	// navigate(navLink);
	// 	showSearchHandler();
	// };

	// const [showSearch, setShowSearch] = useState(false);

	// const showSearchHandler = () => {
	// 	setShowSearch(!showSearch);
	// };

	// useEffect(() => {
	// 	if (searchQuery !== '') setShowSearch(true);
	// }, [searchQuery]);

	return (
		<>
			<div className={styles.pcSearch}>
				<GridSearch
					placeholder="Search styles"
					variant="publicCatalogue"
					setSearchQuery={setSearchQuery}
					// onLimitChange={onLimitChange}
				/>
			</div>
			{/* {showSearch ? (
				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
						transition: {
							duration: 0.2,
						},
					}}
					exit={{
						opacity: 0,
						transition: {
							duration: 0.2,
						},
					}}
					className={styles.search__public__bg}>
					<div className={styles.search__public}>
						{publicStylesFound?.map((style, i) => (
							<div
								className={styles.search__items}
								key={i}
								onClick={() => {
									handleTableClick(style);
								}}>
								<img src={style.imageUrls[0]} alt="Product" />
								<MimaText>{style.name}</MimaText>
							</div>
						))}
					</div>
					<div
						onClick={showSearchHandler}
						className={styles.search__empty}></div>
				</motion.div>
			) : (
				''
			)} */}
		</>
	);
};

export default PublicCatalogueSearch;
