import { MimaText, PageLoader } from '../../components';
import styles from '../../assets/styles/Catalogue/StyleCatalogue.module.scss';
import React, { useMemo } from 'react';
import Footer from './Footer';
import { CatalogHomeHeader } from '.';
import { useCatalogUtility } from '../../hooks';

const Contact = () => {
	const {
		filtering,
		setFiltering,
		setCollectionQuery,
		data,
		setSearchQuery,
		setCurrencyCode,
		showSearch,
		setShowSearch,
		isLoading,
	} = useCatalogUtility();

	const contactDetails = useMemo(() => {
		return data?.data?.websiteSettings?.business || {};
	}, [data]);

	return (
		<main>
			<CatalogHomeHeader
				filtering={filtering}
				setFiltering={setFiltering}
				setCollectionQuery={setCollectionQuery}
				data={data}
				setSearchQuery={setSearchQuery}
				setCurrencyCode={setCurrencyCode}
				showSearch={showSearch}
				setShowSearch={setShowSearch}
			/>
			{isLoading ? (
				<div style={{ marginTop: '15rem' }}>
					<PageLoader />
				</div>
			) : (
				<>
					<section className={styles.content}>
						<MimaText variant="h2" align="center" mb={1}>
							Contact Us
						</MimaText>
						<div className={styles.contact}>
							<div>
								<MimaText labelTitle="Business Name" mb={1}>
									{contactDetails?.tradingName}
								</MimaText>
								<MimaText labelTitle="Phone Number" mb={1}>
									+{contactDetails?.mobile}
								</MimaText>
								<MimaText labelTitle="Business Email" mb={1}>
									{contactDetails?.email}
								</MimaText>
								{contactDetails?.instagramHandle && (
									<MimaText labelTitle="Instagram Handle" mb={1}>
										{contactDetails?.instagramHandle}
									</MimaText>
								)}
							</div>
						</div>
					</section>
					<Footer data={data} />
				</>
			)}
		</main>
	);
};

export default Contact;
