import { css } from 'styled-components';
import arrowDown from '../../assets/svg/arrowDown.svg';

export const base = css`
	padding-left: 2rem;
	border-radius: 1rem;
	background-color: var(--color-white);
	border: 1px solid var(--color-dark);
	display: flex;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	color: var(--color-dark);
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	order: 0;
	position: relative;

	&::after {
		content: '';
		background: url(${arrowDown});
		background-size: contain;
		background-repeat: no-repeat;

		position: absolute;
		height: 100%;
		width: 1.6rem;
		right: 2rem;
		top: 2.5rem;

		transition: all 0.4s;
	}
`;

export const medium = css`
	width: 38rem;
	height: 6rem;
`;

export const error = css`
	width: 38rem;
	height: 6rem;
	border: 2px solid var(--color-error);
	outline: none;
	margin-bottom: 0.5rem;
`;
export const formError = css`
	width: 100%;
	height: 6rem;
	border: 2px solid var(--color-error);
	outline: none;
	margin-bottom: 0.5rem;
`;

export const wide = css`
	width: 60rem;
	height: 6rem;
`;

export const container = css`
	width: 68rem;
	color: red;
`;

export const filterForm = css`
	width: 100%;
	font-size: 1.4rem;
	font-family: inherit;
	color: var(--color-dark);
	margin-top: 0.5rem;
	background-color: var(--color-white);
	box-sizing: border-box;
	height: 4.5rem;
	align-items: center;
	box-sizing: border-box;

	&::after {
		content: '';
		background: url(${arrowDown});
		background-size: contain;
		background-repeat: no-repeat;

		position: absolute;
		height: 100%;
		width: 1.6rem;
		right: 2rem;
		top: 1.5rem;

		transition: all 0.4s;
	}
`;

export const form = css`
	width: 100%;
	height: 6rem;
`;

export const checkoutForm = css`
	width: 100%;
	height: 6rem;
	border: 1px solid #999;
`;

export const divBase = css`
	margin-bottom: 2rem;
`;

export const searchDivBase = css`
	position: relative;
`;

export const regular = css`
	width: 38rem;
	margin-bottom: 0.5rem;
`;

export const dropdown = css`
	width: 100%;
`;

export const searchBase = css`
	font-size: 1.8rem;
	font-family: inherit;
	color: var(--color-dark);
	padding-left: 2rem;
	height: 6rem;
	border-radius: 1rem;
	background-color: var(--color-white);
	border: 2px solid var(--color-primary);
	display: block;
	box-sizing: border-box;
`;

export const bodyBase = css`
	background-color: var(--color-white);
	color: var(--color-dark);
	border: 1px solid var(--color-dark);
	width: 38rem;
	border-radius: 1rem;
	transition: all 0.4s;
	order: 1;
	max-height: 27rem;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;

	&::-webkit-scrollbar {
		width: 1rem;
		background-color: var(--color-white);
		border-radius: 0 1rem 1rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey);
		border-radius: 1rem;
	}

	&>: first-child {
		align-self: center;
	}
`;

export const bodyBase2 = css`
	background-color: var(--color-white);
	color: var(--color-dark);
	border: 1px solid var(--color-dark);
	width: 100%;
	border-radius: 1rem;
	transition: all 0.4s;
	order: 1;
	max-height: 27rem;
	overflow-y: scroll;
	font-size: var(--default-font-small);
	display: flex;
	flex-direction: column;

	&::-webkit-scrollbar {
		width: 1rem;
		background-color: var(--color-white);
		border-radius: 0 1rem 1rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey);
		border-radius: 1rem;
	}

	&>: first-child {
		align-self: center;
	}
`;

export const bodyBase3 = css`
	background-color: var(--color-white);
	color: var(--color-dark);
	border: 1px solid #999;
	width: 100%;
	border-radius: 1rem;
	transition: all 0.4s;
	order: 1;
	max-height: 27rem;
	overflow-y: scroll;
	font-size: var(--default-font);
	display: flex;
	flex-direction: column;

	&::-webkit-scrollbar {
		width: 1rem;
		background-color: var(--color-white);
		border-radius: 0 1rem 1rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey);
		border-radius: 1rem;
	}

	&>: first-child {
		align-self: center;
	}
`;
