import styles from '../../assets/styles/Components/InvoiceListItem.module.scss';
import React from 'react';


const ReceiptSummaryItem = ({ service, quantity, amount, totalAmount }) => {
	return (
		<div className={styles.item}>
			<div>
				<p> {service}</p>
				<p className={styles.item__qty}>
					{quantity} x {amount}
				</p>
			</div>
			<p className={styles.item__total}>{totalAmount}</p>
		</div>
	);
};

export default ReceiptSummaryItem;
