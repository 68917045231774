import { useMemo, useState } from 'react';
import constant from '../../utils/constant';
import { useNavigate } from 'react-router-dom';

const useHeaderNavLogic = (
	setShowSearch,
	showSearch,
	data,
	isSolid,
	setIsSolid
) => {
	const [openCart, setOpenCart] = useState(false);

	const showSearchHandler = () => {
		setShowSearch(!showSearch);
		if (setIsSolid) {
			setIsSolid(!isSolid);
		}
	};

	const businessName = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles?.[0]?.business.tradingName || '';
		}
		return '';
	}, [data]);

	const businessLogo = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles?.[0]?.business?.logoUrl || '';
		}
		return '';
	}, [data]);

	const webSettings = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.websiteSettings || {};
		}
		return '';
	}, [data]);

	const navigate = useNavigate();

	const goToCart = () => {
		navigate(constant.Routes.WebsiteCart);
	};

	const goToHome = () => {
		navigate(constant.Routes.Home);
	};

	const closeHeaderModals = () => {
		setOpenCart(false);
	};

	const openCartHandler = () => {
		setOpenCart(true);
	};

	return {
		businessLogo,
		businessName,
		showSearchHandler,
		goToCart,
		webSettings,
		openCart,
		openCartHandler,
		closeHeaderModals,
		goToHome,
	};
};

export default useHeaderNavLogic;
