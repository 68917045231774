import React, { useState } from 'react';
import { RDModal } from '../../pages';
import constant from '../../utils/constant';
import { MimaToastUtil } from '../../components';
import * as yup from 'yup';
import { phoneRegExp, removeEmptyKeys } from '../../utils/utils';
import { catalogStore } from '../../stores';
import { StyleCatalogController } from '../../controllers';

const RDModalContainer = ({ closeModal, subdomain }) => {
	const [loading, setLoading] = useState(false);

	const { catalogOrderInfo } = catalogStore || {};

	const validationSchema = () =>
		yup.object({
			fullname: yup
				.string()
				.required('Full Name is required')
				.test('fullName', 'First and Last name is required', function (value) {
					const names = value ? value.split(' ') : [];
					return names.length > 1 && names[0].length > 1 && names[1].length > 1;
				}),

			mobile: yup
				.string()
				.matches(phoneRegExp, 'Phone number is not valid')
				.required('Phone number is required'),
			email: yup
				.string()
				.trim()
				.email('Enter a valid Email')
				.required('Email Address is required'),
		});

	const onSubmit = async value => {
		setLoading(true);
		const payload = {
			fullname: value.fullname,
			note: value.note,
			quantity: catalogOrderInfo?.quantity,
			withMaterial:
				catalogOrderInfo?.withMaterial === 0 ||
				catalogOrderInfo?.withMaterial === 'with'
					? true
					: false,
			style: catalogOrderInfo?.styleId || catalogOrderInfo?._id,
			business: catalogOrderInfo?.business?._id,
			email: value.email,
			mobile: value.mobile,
			instagramHandle: value.instagramHandle,
			subDomain: subdomain,
		};

		removeEmptyKeys(payload);

		const { status, errorMessage } =
			await StyleCatalogController.requestDesign(payload);
		if (status === constant.Success) {
			setLoading(false);
			MimaToastUtil.success({
				message: constant.Success,
			});
			closeModal();
			return;
		}
		setLoading(false);
		return MimaToastUtil.error({
			message: errorMessage,
		});
	};
	return (
		<RDModal
			closeModal={closeModal}
			loading={loading}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
		/>
	);
};

export default RDModalContainer;
