import React, { useState } from 'react';
import Modal from '../Modal';
import MimaText from '../MimaText';
import FieldRenderer from './FieldRenderer';
import { ReceiptCheckBox as RCB } from '../Receipt';
import styles from '../../assets/styles/EditMode/EditMode.module.scss';
import { MimaButton } from '../Button';
import { BiSolidChevronDownCircle } from 'react-icons/bi';
import { Formik } from 'formik';
import { StyleCatalogController } from '../../controllers';
import constant from '../../utils/constant';
import { MimaToastUtil } from '../Toast';
import useEditMode from './useEditMode';
import { catalogStore } from '../../stores';
import { removeEmptyKeysAndObjects } from '../../utils/utils';

const EditModal = ({ closeModal, page, generalSettings }) => {
	const {
		generateInitialValues,
		// generateValidationSchema,
		convertFormData,
		findImagesToUpload,
		prepareImagesForUpload,
		uploadFiles,
		linkUploadedUrls,
		insertUpdatedImages,
		contentToKeyValueArray,
		keyValueArrayToContent,
		removeValidation,
		updateContentWithValidation,
		removeIdKeys,
	} = useEditMode();

	const initialValues = generateInitialValues(page.sections);
	// const validationSchema = generateValidationSchema(page.sections);

	const [expandedSections, setExpandedSections] = useState({});
	const [loading, setLoading] = useState(false);

	const toggleSection = sectionId => {
		setExpandedSections(prev => ({
			...prev,
			[sectionId]: !prev[sectionId],
		}));
	};

	const updatePagesInThemeData = (originalObject, editedPage) => {
		return {
			...originalObject,
			pages: originalObject.pages.map(page =>
				page.id === editedPage.id ? editedPage : page
			),
		};
	};

	const {
		themeData,
		configThemeData,
		themeId,
		setThemeId,
		setThemeData,
		webConfig,
	} = catalogStore;

	const onSubmit = async (values, { setFieldValue }) => {
		setLoading(true);

		const imagesToUpload = findImagesToUpload(values);
		let editedPage;

		if (imagesToUpload?.length > 0) {
			const preparedFiles = prepareImagesForUpload(imagesToUpload);

			const uploadedUrls = await uploadFiles(preparedFiles);
			const updatedImages = linkUploadedUrls(imagesToUpload, uploadedUrls);
			const updatedValues = insertUpdatedImages(updatedImages, values);

			editedPage = convertFormData(updatedValues, page.name, page.id);
		} else {
			editedPage = convertFormData(values, page.name, page.id);
		}

		let updatedThemeData;

		if (themeData?.pages?.length) {
			updatedThemeData = updatePagesInThemeData(themeData, editedPage);
		} else {
			updatedThemeData = updatePagesInThemeData(configThemeData, editedPage);
		}

		const validationRemoved = removeValidation(updatedThemeData);
		const switchedToKeyValueArray = contentToKeyValueArray(validationRemoved);
		const prePayload = removeEmptyKeysAndObjects(switchedToKeyValueArray);

		const payload = {
			config: {
				themeName: prePayload?.generalSettings?.themeName,
				data: prePayload?.generalSettings?.data,
			},
			pages: [...prePayload?.pages],
		};

		const { status, errorMessage, data } = await (themeData?.pages?.length
			? StyleCatalogController.updateThemeData(payload, themeId)
			: StyleCatalogController.createThemeData(payload));

		if (status === constant.Success) {
			const returnedThemeData = { ...data?.themeData };
			setThemeId(returnedThemeData?._id);
			const postPayload = {
				generalSettings: {
					themeName: returnedThemeData?.config?.themeName,
					data: returnedThemeData?.config?.data,
				},
				pages: [...returnedThemeData?.pages],
			};

			const reshapedThemeData = keyValueArrayToContent(postPayload);
			const idRemovedThemeData = removeIdKeys(reshapedThemeData);

			const aboutPageDetails = reshapedThemeData?.pages.find(
				page => page.id === 'about_page'
			);

			const aboutInfo = aboutPageDetails?.sections.find(
				section => section.id === 'about_info'
			);

			const aboutUsPayload = {
				website: {
					...webConfig,
					aboutUs: {
						imageUrl: aboutInfo?.defaultContent?.imageUrls[0]?.imageUrl,
						text: aboutInfo?.defaultContent?.content || '',
					},
				},
			};

			if (
				aboutUsPayload?.website?.aboutUs?.text &&
				aboutUsPayload?.website?.aboutUs?.text !== webConfig?.aboutUs?.text
			) {
				await StyleCatalogController.configureWebsite(aboutUsPayload);
			}

			const themeDataWithValidation = updateContentWithValidation(
				idRemovedThemeData,
				configThemeData
			);
			setThemeData(themeDataWithValidation);

			setLoading(false);
			closeModal();

			MimaToastUtil.success({
				message: `${constant.Success}, Reloading page`,
			});

			return setTimeout(() => {
				window.location.reload();
			}, 500);
		}
		setLoading(false);
		return MimaToastUtil.error({
			message: errorMessage,
		});
	};

	return (
		<Modal closeModal={closeModal}>
			<MimaText variant="subtitleBold" mb={1}>
				Quick Edit{' '}
			</MimaText>
			<MimaText mb={2}>{page.name} page</MimaText>
			<Formik
				initialValues={initialValues}
				// validationSchema={validationSchema}
				onSubmit={onSubmit}>
				{({
					handleChange,
					handleBlur,
					handleSubmit,
					setFieldValue,
					values,
					errors,
					touched,
				}) => {
					return (
						<>
							<div className={styles.sections}>
								{page.sections.map(section => (
									<div key={section.id}>
										<button
											onClick={e => {
												e.preventDefault();
												toggleSection(section.id);
											}}
											className={`${styles.section__btn} ${expandedSections[section.id] ? styles.section__btn__active : ''}`}>
											<span>Edit {section.name} Section</span>
											<BiSolidChevronDownCircle
												className={`${styles.section__arr} ${expandedSections[section.id] ? styles.section__arr__active : ''}`}
											/>
										</button>
										{expandedSections[section.id] && (
											<div className={styles.section__expanded}>
												<MimaText variant="subtitleBold">
													{section.name}
												</MimaText>

												{!section?.compulsory ? (
													<div className={styles.section__flex}>
														<RCB
															title="Show Section"
															onChange={() =>
																setFieldValue(`${section.id}.isVisible`, true)
															}
															checked={values[section.id].isVisible === true}
															id={`${section.id}_visible_show`}
															name={`${section.id}.isVisible`}
															value={true}
														/>
														<RCB
															title="Hide Section"
															onChange={() =>
																setFieldValue(`${section.id}.isVisible`, false)
															}
															checked={values[section.id].isVisible === false}
															id={`${section.id}_visible_hide`}
															name={`${section.id}.isVisible`}
															value={false}
														/>
													</div>
												) : (
													''
												)}

												{section?.defaultContent
													? Object.keys(section.defaultContent)?.map(key => (
															<FieldRenderer
																key={key}
																field={key}
																value={values[section.id][key]}
																validation={section.validation[key]}
																onChange={handleChange}
																onBlur={handleBlur}
																name={`${section.id}.${key}`}
																error={errors[section.id]?.[key]}
																touched={touched[section.id]?.[key]}
																setFieldValue={setFieldValue}
																arrayTitle={section.name}
															/>
														))
													: ''}
											</div>
										)}
									</div>
								))}
								<MimaButton
									title="Save Changes"
									onClick={handleSubmit}
									variant="form"
									mt={2}
									loading={loading}
								/>
							</div>
						</>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default EditModal;
