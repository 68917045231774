import styles from '../../assets/styles/LandingPage/Header.module.scss';
import PlayStoreIcon from '../../assets/img/playstore.png';
import AppleStoreIcon from '../../assets/img/apple.png';
import { MimaButton } from '../../components';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import React from 'react';
import LandingNav from './LandingNav';
import { FashShape } from '../../assets/img/ImgList';

export const DownloadAppBtns = () => {
	const appleIcon = (
		<img src={AppleStoreIcon} className={styles.btn__icon} alt="Apple Icon" />
	);

	const playIcon = (
		<img
			src={PlayStoreIcon}
			className={styles.btn__icon}
			alt="Google play Icon"
		/>
	);

	return (
		<div className={styles.dl}>
			<a href="#" style={{ textDecoration: 'none' }}>
				<MimaButton
					title="Get app on Android"
					variant="outlined"
					icon={playIcon}
					width={30}
				/>
			</a>

			<a href="#" style={{ textDecoration: 'none' }}>
				<MimaButton
					title="Get app on iPhone"
					variant="outlined"
					icon={appleIcon}
					width={30}
				/>
			</a>
		</div>
	);
};

const Header = () => {
	const navigate = useNavigate();
	const switchToSignup = () => {
		navigate(constant.Routes.SignUp);
	};

	return (
		<header>
			<LandingNav />
			<div className={styles.line}></div>
			<div className={styles.hero}>
				<h1>Unleash Your Creativity.</h1>
				<p>
					Create a stunning online catalog, manage orders, and connect with
					customers seamlessly.
				</p>
				<MimaButton
					title="Get Started - Create an account"
					onClick={switchToSignup}
					variant="colorable"
					buttonColor="var(--color-fash-primary)"
					titleColor="var(--color-fash-black)"
				/>
				<img src={FashShape} alt="shapes" className={styles.hero__shape} />
			</div>
			<DownloadAppBtns />
		</header>
	);
};

export default Header;
