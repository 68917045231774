import React, { useState } from 'react';
import styles from '../../assets/styles/LandingPage/Header.module.scss';
import { FashMainLogo } from '../../assets/img/ImgList';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import { Link } from 'react-router-dom';
import { MimaButton } from '../../components';
import { IoMdMenu } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
import { MdCancel } from 'react-icons/md';

const LandingNav = () => {
	const [menu, setMenu] = useState(false);

	const showMenu = () => {
		setMenu(!menu);
	};

	const navigate = useNavigate();
	const switchToLanding = () => {
		navigate(constant.Routes.Home);
	};
	const switchToSignup = () => {
		navigate(constant.Routes.SignUp);
	};

	const switchToLogin = () => {
		navigate(constant.Routes.Login);
	};

	const navItems = [
		{
			title: 'Products',
			id: '#Products',
			link: constant.Routes.Home,
		},
		{
			title: 'Pricing',
			id: '#Pricing',
			link: constant.Routes.Home,
		},
		{
			title: 'Blog',
			id: '#',
			link: constant.Routes.BlogLanding,
		},
		{
			title: 'Partnership',
			id: '#',
			link: constant.Routes.PartnerHome,
		},
		// {
		// 	title: 'Company',
		// 	id: '#Company',
		// 	link: constant.Routes.Home,
		// },
	];

	return (
		<div>
			<AnimatePresence>
				{menu && (
					<motion.div
						initial={{
							opacity: 0,
							y: '-100%',
						}}
						animate={{
							opacity: 1,
							y: 0,
							transition: {
								duration: 0.5,
							},
						}}
						exit={{
							opacity: 0,

							y: '-100%',
							transition: {
								duration: 0.3,
							},
						}}
						className={styles.nav__mobile}>
						<div className={styles.nav__mobile__top}>
							<img
								src={FashMainLogo}
								alt="Fash Creatives Logo"
								onClick={switchToLanding}
							/>
							<div className={styles.nav__close} onClick={showMenu}>
								<MdCancel />
							</div>
						</div>
						<nav>
							<ul className={styles.nav__mobile__ul}>
								{navItems.map((navItem, i) => (
									<li key={i} onClick={showMenu}>
										<Link to={`${navItem.link}${navItem.id}`}>
											{navItem.title}
										</Link>
										{/* <Link to={navItem.link}>{navItem.title}</Link> */}
									</li>
								))}
							</ul>
						</nav>
						<div className={styles.nav__mobile__btns}>
							{/* <MimaButton
								title="Log in"
								variant="outlined"
								onClick={switchToLogin}
								width={38}
								mb={2}
							/> */}
							<MimaButton title="Sign up" onClick={switchToSignup} />
						</div>
					</motion.div>
				)}
			</AnimatePresence>
			<div className={styles.nav}>
				<img
					src={FashMainLogo}
					className="w-72"
					alt="Fash Creative Logo"
					onClick={switchToLanding}
				/>
				<nav>
					<ul className={styles.nav__ul}>
						{navItems.map((navItem, i) => (
							<li key={i}>
								<Link to={`${navItem.link}${navItem.id}`}>{navItem.title}</Link>
								{/* <Link to={navItem.link}>{navItem.title}</Link> */}
							</li>
						))}
					</ul>
				</nav>
				<div className={styles.nav__btns}>
					{/* <MimaButton
						title="Log in"
						variant="outlined"
						onClick={switchToLogin}
					/> */}
					<MimaButton title="Sign up" onClick={switchToSignup} width={30} />
				</div>
				<div onClick={showMenu} className={styles.nav__open}>
					<IoMdMenu />
				</div>
			</div>
		</div>
	);
};

export default LandingNav;
