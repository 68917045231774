import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as inputStyles from './styles';
import styles from '../../assets/styles/MimaInput.module.scss';

const propTypes = {
	labelTitle: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string.isRequired,
	width: PropTypes.any,
	mt: PropTypes.number,
	mr: PropTypes.number,
	mb: PropTypes.number,
	ml: PropTypes.number,
	error: PropTypes.any,
	touched: PropTypes.any,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
};

const ColorPicker = ({
	labelTitle,
	value,
	name,
	width,
	id,
	mt,
	mr,
	mb,
	ml,
	touched,
	error,
	onBlur,
	onChange,
	...props
}) => {
	const [showPicker, setShowPicker] = useState(false);
	const pickerRef = useRef(null);

	const handleColorChange = color => {
		onChange(color.hex); // Update the color as hex string
	};

	// Handle click outside of the picker to close it
	useEffect(() => {
		function handleClickOutside(event) {
			if (pickerRef.current && !pickerRef.current.contains(event.target)) {
				setShowPicker(false);
			}
		}

		function handleEscKey(event) {
			if (event.key === 'Escape') {
				setShowPicker(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleEscKey);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleEscKey);
		};
	}, [pickerRef]);

	return (
		<ColorWrapper mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
			<label className={styles.form__label}>{labelTitle}</label>

			<ColorPreviewWrapper>
				<ColorPreview
					onClick={() => setShowPicker(!showPicker)}
					style={{ backgroundColor: value }}
				/>
				<InputSkeleton
					type="text"
					value={value}
					onChange={e => handleColorChange({ hex: e.target.value })}
					name={name}
					onBlur={onBlur}
				/>
			</ColorPreviewWrapper>
			{showPicker && (
				<ColorPickerButton ref={pickerRef}>
					<SketchPicker color={value} onChange={handleColorChange} />
				</ColorPickerButton>
			)}
			{error ? <div className={styles.error}>{touched && error}</div> : null}
		</ColorWrapper>
	);
};

const ColorWrapper = styled.div`
	${inputStyles.wrapper};
	${props => props.mt && `margin-top: ${props.mt}rem`};
	${props => props.mr && `margin-right: ${props.mr}rem`};
	${props => props.mb && `margin-bottom: ${props.mb}rem`};
	${props => props.ml && `margin-left: ${props.ml}rem`};
	${props => props.width && `width: ${props.width}rem`};
`;

const ColorPreview = styled.div`
	${inputStyles.preview};
`;

const ColorPickerButton = styled.div`
	${inputStyles.pickerButton};
`;

const ColorPreviewWrapper = styled.div`
	${inputStyles.previewWrapper};
`;

const InputSkeleton = styled.input`
	${inputStyles.input};
`;

ColorPicker.propTypes = propTypes;

export default ColorPicker;
