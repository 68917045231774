import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';

const DangerousContent = ({ content, color, className }) => {
	const sanitizedContent = useMemo(
		() => DOMPurify.sanitize(content),
		[content]
	);
	return (
		<div
			dangerouslySetInnerHTML={{ __html: sanitizedContent }}
			className={`sanitized__content ${className}`}
			style={{ color: color }}
		/>
	);
};

export default DangerousContent;
