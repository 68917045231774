import React from 'react';
import styles from '../../assets/styles/PublicCatalogue/PublicCatalogue.module.scss';
import PublicCatalogueSearch from './PublicCatalogueSearch';
import { MimaMasonryLayout, MimaText } from '../../components';
import PublicImageSlideshow from './PublicImageSlideshow';
import { useMediaQuery } from 'react-responsive';
import { IoMdHome } from 'react-icons/io';

const PublicCatalogue = ({
	searchQuery,
	setSearchQuery,
	isLoading,
	data,
	goToCatalogHome,
	handleTableClick,
	fetchNextPage,
	hasNextPage,
	isFetchingNextPage,
	status,
}) => {
	// console.log('data', data);

	// const [tableData, setTableData] = useState([]);

	// useEffect(() => {
	//     if (status === 'success' && data) {
	//       const newStyles = data.pages.flatMap(page => page?.data?.styles);
	//       setTableData(newStyles);
	//     }
	//   }, [data, status]);

	// const tableData = useMemo(() => {
	// 	if (status === 'success') {
	// 		return data?.pages?.flatMap(page => page?.data?.styles) || [];
	// 	}
	// 	return [];
	// }, [data, status]);

	// const tableData = useMemo(() => {
	// 	if (status === 'success' && data?.pages) {
	// 		let accumulatedStyles = [];
	// 		data.pages.forEach(page => {
	// 			if (page?.data?.styles) {
	// 				accumulatedStyles = accumulatedStyles.concat(page?.data?.styles);
	// 			}
	// 		});
	// 		return accumulatedStyles;
	// 	}
	// 	return [];
	// }, [data, status]);

	const tableColumns = [
		{
			header: 'Image',
			accessorKey: 'imageUrls',
			cell: item => {
				const imageUrls = item?.getValue();

				return imageUrls ? (
					<PublicImageSlideshow imageUrls={imageUrls} />
				) : (
					<span>N/A</span>
				);
			},
		},
		{
			header: 'Product Name',
			accessorKey: 'name',
			cell: item => {
				const name = item?.getValue();

				return <MimaText variant="small">{name}</MimaText>;
			},
		},
		{
			header: 'Designer Name',
			accessorKey: 'designerName',
			cell: item => {
				const designerName = item?.getValue();

				return designerName ? (
					<MimaText variant="small">by {designerName}</MimaText>
				) : (
					''
				);
			},
		},
	];

	const isBelow500 = useMediaQuery({
		query: '(max-width: 500px)',
	});
	return (
		<main>
			<header className={styles.pch}>
				<button className={styles.gtmw} onClick={goToCatalogHome}>
					{isBelow500 ? (
						<IoMdHome style={{ fontSize: '3rem' }} />
					) : (
						<span> Go to Main Website </span>
					)}
				</button>
				<PublicCatalogueSearch setSearchQuery={setSearchQuery} />
			</header>
			<section className={styles.pcb}>
				{searchQuery !== '' ? (
					<MimaText variant="subtitle" align="center">
						{searchQuery}
					</MimaText>
				) : (
					''
				)}
				<MimaMasonryLayout
					tableData={data}
					tableColumns={tableColumns}
					searchPlaceholder="Search Styles"
					isLoading={isLoading}
					setSearchQuery={setSearchQuery}
					emptyMessage="No Styles found or match search"
					tableClick={handleTableClick}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
					isFetchingNextPage={isFetchingNextPage}
				/>
			</section>
		</main>
	);
};

export default PublicCatalogue;
