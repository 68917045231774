import { css, FlattenSimpleInterpolation } from 'styled-components';

export const searchStyles = {
	base: css`
		font-size: 1.4rem;
		font-family: inherit;
		color: var(--color-dark);
		padding-left: 2rem;
		height: 4.2rem;
		border-radius: 0.8rem;
		background-color: var(--color-white);
		border: 2px solid var(--color-dark);
		display: block;
		box-sizing: border-box;
		outline: none;

		&:active {
			border: 2px solid var(--color-primary-4);
		}
	`,
	regular: css`
		width: 38rem;
		margin-bottom: 0.5rem;
	`,
	wide: css`
		width: 60rem;

		@media only screen and (max-width: 31.25em) {
			width: 38rem;
		}
	`,
	container: css`
		width: 100%;
	`,
	iconBase: css`
		width: 5rem;
		height: 100%;
		color: var(--color-dark);
		font-size: 2.4rem;
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	`,
	divBase: css`
		position: relative;
	`,
	publicCatalogue: css`
		width: 100%;
		background-color: var(--color-light-blue);
		border: none;
		font-weight: normal;
		outline: none;
		height: 100%;
		padding: 2rem;
		border-radius: 2rem;

		&:active,
		&:focus {
			border: 2px solid var(--color-dark);
		}
	`,
};
