import { walletStore } from '.';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class UserStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'UserStore',
			properties: [
				'isLoggedIn',
				'user',
				'isOnBoardingDone',
				'token',
				'userId',
				'isActivated',
				'email',
				'permissions',
				'selectedAdmin',
				'availableCards',
				'hasMobileApp',
				'unDoneReviews',
				'reviewId',
				'userWebsiteData',
			],
		});
	}

	user = {};
	isLoggedIn = false;
	isOnBoardingDone = false;
	token = '';
	userId = '';
	selectedAdmin = {};
	email = '';
	permissions = [];
	isActivated = false;
	availableCards = [];
	hasMobileApp = false;
	unDoneReviews = [];
	reviewId = '';
	userWebsiteData = {};

	reset = () => {
		this.user = {};
		this.isLoggedIn = false;
		this.isOnBoardingDone = false;
		this.token = '';
		this.userId = '';
		this.selectedAdmin = {};
		this.email = '';
		this.permissions = [];
		this.isActivated = false;
		this.availableCards = [];
		this.hasMobileApp = false;
		this.unDoneReviews = [];
		this.reviewId = '';
		this.userWebsiteData = {};
	};

	setUser = async user => {
		runInAction(async () => {
			this.user = user;
		});
	};

	setSelectedAdmin = async state => {
		runInAction(async () => {
			this.selectedAdmin = state;
		});
	};

	setPermissions = async permissions => {
		runInAction(async () => {
			this.permissions = permissions;
		});
	};

	setLoggedIn = async state => {
		if (state === false) {
			this.reset();
			walletStore.reset();
		}
		runInAction(() => {
			this.isLoggedIn = state;
		});
	};

	setOnBoardingDone = async state => {
		runInAction(() => {
			this.isOnBoardingDone = state;
		});
	};

	setActivated = async state => {
		runInAction(() => {
			this.isActivated = state;
		});
	};
	setToken = async state => {
		runInAction(() => {
			this.token = state;
		});
	};

	setUserId = async state => {
		runInAction(() => {
			this.userId = state;
		});
	};

	setEmail = async state => {
		runInAction(() => {
			this.email = state;
		});
	};

	setAvailableCards = async state => {
		runInAction(() => {
			this.availableCards = state;
		});
	};

	setHasMobileApp = async state => {
		runInAction(() => {
			this.hasMobileApp = state;
		});
	};
	setUnDoneReviews = async state => {
		runInAction(() => {
			this.unDoneReviews = state;
		});
	};
	setReviewId = async state => {
		runInAction(() => {
			this.reviewId = state;
		});
	};
	setUserWebsiteData = async state => {
		runInAction(() => {
			this.userWebsiteData = state;
		});
	};
}
