import React, { useState } from 'react';
import styles from '../../assets/styles/PaymentLink/PaymentLinkCheckout.module.scss';
import {
	MimaButton,
	MimaText,
	MimaToastUtil,
	PoweredBy,
} from '../../components';
import { amountFormatter } from '../../utils/utils';
import { FaInfoCircle } from 'react-icons/fa';
import { MdOutlineContentCopy } from 'react-icons/md';
import constant from '../../utils/constant';
import { StyleCatalogController } from '../../controllers';
import validateImg from '../../assets/img/Mimaweb.png';

const FxTransfer = ({ foreignAccount, invoice, onSuccess, goBack }) => {
	const [validatePay, setValidatePay] = useState(false);
	const [loading, setLoading] = useState(false);

	const validatePaymentClick = async () => {
		setLoading(true);
		const { status, data } = await StyleCatalogController.invoicePaymentConfirm(
			invoice?._id
		);
		if (status === constant.Success) {
			if (data.msg === 'Payment Confirmed') {
				onSuccess();
				setLoading(false);
			}
		}
		const timer = setTimeout(() => {
			setValidatePay(true);
		}, 1000);

		onSuccess();

		return () => clearTimeout(timer);
	};

	return (
		<div className={styles.checkout__form}>
			<MimaText variant="subtitleBold">Payment</MimaText>
			<MimaText mb={3} color="#999">
				All transactions are secure and encrypted.
			</MimaText>

			{validatePay ? (
				<div className={styles.payInfo}>
					<img
						src={validateImg}
						alt="Validating"
						className={styles.successImg}
					/>
					<MimaText
						variant="h3"
						align="center"
						mb={1}
						mt={2}
						color="var(--color-primary)">
						Payment In Validation
					</MimaText>
					<MimaText variant="subtitleBold" align="center" width={40}>
						You will get an update on this transfer transaction in 12 - 72 hours
					</MimaText>
				</div>
			) : (
				<div className={styles.payInfo}>
					<MimaText align="center" variant="subtitleBold" mb={1}>
						Pay{' '}
						<span className={styles.span}>
							{amountFormatter(foreignAccount?.currencyCode).format(
								foreignAccount?.expectedAmount
							)}
						</span>{' '}
						by bank transfer
					</MimaText>

					<div className={styles.payInfo__info}>
						<FaInfoCircle />{' '}
						<p>
							When paying through your bank, make sure to use the reference{' '}
							<span
								style={{
									fontWeight: 700,
								}}
								onClick={() => {
									navigator.clipboard.writeText(foreignAccount?.code);
									MimaToastUtil.success({
										message: `Reference Copied!`,
									});
								}}>
								{foreignAccount?.code}
							</span>
							. Click on{' '}
							<span
								style={{
									fontWeight: 700,
								}}>
								Validate Payment
							</span>{' '}
							once your transfer is complete.
						</p>
					</div>

					<div className={styles.payInfo__main}>
						<div className={styles.payInfo__main_text2__grid}>
							<MimaText labelTitle="Reference">{foreignAccount?.code}</MimaText>
							<MdOutlineContentCopy
								onClick={() => {
									navigator.clipboard.writeText(foreignAccount?.code);
									MimaToastUtil.success({
										message: `Transfer Code Copied!`,
									});
								}}
							/>
						</div>
						{foreignAccount?.id?.metadata?.data?.map((details, i) => (
							<div key={i} className={styles.payInfo__main_text2__grid}>
								<MimaText
									labelTitle={details?.label}
									// variant="h3"
									// align="center"
									// style={{ display: 'flex' }}
								>
									{details?.value}{' '}
								</MimaText>
								<MdOutlineContentCopy
									onClick={() => {
										navigator.clipboard.writeText(details?.value);
										MimaToastUtil.success({
											message: `${details?.label} Copied!`,
										});
									}}
								/>
							</div>
						))}
					</div>
				</div>
			)}

			<div
				style={
					!validatePay
						? {
								display: 'grid',
								gridTemplateColumns: '15rem 1fr',
								gap: '1rem',
								width: '100%',
							}
						: null
				}>
				{!validatePay ? (
					<MimaButton
						title="Go Back"
						variant="form"
						mt={2}
						loading={loading}
						buttonColor="var(--color-dark)"
						onClick={goBack}
					/>
				) : (
					''
				)}
				<MimaButton
					title="Validate Payment"
					mt={2}
					onClick={validatePaymentClick}
					loading={loading}
					variant="form"
				/>
			</div>

			<PoweredBy />
		</div>
	);
};

export default FxTransfer;
