import styles from '../../assets/styles/LandingPage/Subscription.module.scss';
import React from 'react';
import { MimaButton, MimaText } from '../../components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import { amountFormatter } from '../../utils/utils';
// import { businessStore } from '../../stores';

const Subscription = () => {
	const [subscription, setSubscription] = useState('MONTHLY');

	const navigate = useNavigate();

	// const { subscriptionPlans } = businessStore;

	const fashSubs = [
		{
			vendorCount: 0,
			_id: '64625eef89dbe67073f03fb6',
			name: 'Free',
			__v: 0,
			amount: 0,
			coverage: [
				'E-commerce Website (No Payment Gateway)',
				'Order Management (Max 5)',
				'Order Due Date Reminder',
				'Balance Payment Reminder',
				'Customer Management (Max. 10 customers)',
				'Measurement Storage (Max. 10)',
				'Fashion Style Management (Max. 10 styles)',
				'Infinite Online Fashion Catalogue',
				'Logistics',
			],
			createdAt: '2023-05-15T16:33:51.539Z',
			currencyCode: 'NGN',
			oneYearDiscountPer: 20,
			sixMonthsDiscountPer: 10,
			status: 'ACTIVE',
			threeMonthsDiscountPer: 5,
			updatedAt: '2023-05-21T15:41:26.797Z',
			rate: 1,
			adminCount: 0,
			businessCount: 0,
			customerCount: 100,
			employeeCount: 0,
			productCount: 30,
			gracePeriod: 7,
			// noCoverage: [
			// 	'Lose Access To Create Invoice In Foreign Currencies',
			// 	'Lose Access To Vendor Split Payment',
			// 	'Lose Access To Petty Cash Managements',
			// 	'Lose Access To Employees Managements',
			// 	'Lose Multiple Admin Login',
			// 	'Lose Access To Payroll',
			// 	'Lose Access To Vendor Managements',
			// 	'Lose Access To Multi-Buisiness Management',
			// 	'Lose Access to In Person Accountant',
			// ],
			stockCount: 30,
			bookingLink: false,
			paymentLink: false,
			subWallet: false,
		},
		{
			vendorCount: 0,
			_id: '64625eef89dbe67073f03fb7',
			name: 'Lite',
			__v: 0,
			amount: 999.99,
			coverage: [
				'E-commerce Website (No Payment Gateway)',
				'Order Management (Max. 30)',
				'Order Due Date Reminder',
				'Balance Payment Reminder',
				'Customer Management (Max. 30 customers)',
				'Measurement Storage',
				'Fashion Style Management (Max. 30 styles)',
				'Infinite Online Fashion Catalogue',
				'Logistics',
			],
			createdAt: '2023-05-15T16:33:51.539Z',
			currencyCode: 'NGN',
			oneYearDiscountPer: 20,
			sixMonthsDiscountPer: 10,
			status: 'ACTIVE',
			threeMonthsDiscountPer: 5,
			updatedAt: '2023-05-21T15:41:26.797Z',
			rate: 1,
			adminCount: 0,
			businessCount: 0,
			customerCount: 100,
			employeeCount: 0,
			productCount: 30,
			gracePeriod: 7,
			// noCoverage: [
			// 	'Lose Access To Create Invoice In Foreign Currencies',
			// 	'Lose Access To Vendor Split Payment',
			// 	'Lose Access To Petty Cash Managements',
			// 	'Lose Access To Employees Managements',
			// 	'Lose Multiple Admin Login',
			// 	'Lose Access To Payroll',
			// 	'Lose Access To Vendor Managements',
			// 	'Lose Access To Multi-Buisiness Management',
			// 	'Lose Access to In Person Accountant',
			// ],
			stockCount: 30,
			bookingLink: false,
			paymentLink: false,
			subWallet: false,
		},
		{
			vendorCount: 0,
			_id: '64625eef89dbe67073f03fb8',
			name: 'Basic',
			__v: 0,
			amount: 2500,
			coverage: [
				'E-commerce Website with Naira Payment Gateway',
				'Order Management',
				'Order Due Date Reminder',
				'Balance Payment Reminder',
				'Customer Management (Max. 100 customers)',
				'Measurement Storage',
				'Fashion Style Management (Up to 100 styles)',
				'Infinite Online Fashion Catalogue',
				'Logistics',
				'Invoice/Receipt/Quotation',
				'Payment Link (Naira Payment Only)',
				'Booking Consultation Link (Naira Payment Only)',
				'Inventory Management (Max. 30 products)',
				'Debt Management',
				'Wallet (Naira Only)',
				'Income and Expense Report and Analysis',
				'Virtual Business Bank Account',
			],
			createdAt: '2023-05-15T16:33:51.539Z',
			currencyCode: 'NGN',
			oneYearDiscountPer: 20,
			sixMonthsDiscountPer: 10,
			status: 'ACTIVE',
			threeMonthsDiscountPer: 5,
			updatedAt: '2023-05-21T15:41:26.797Z',
			rate: 1,
			adminCount: 0,
			businessCount: 0,
			customerCount: 100,
			employeeCount: 0,
			productCount: 30,
			gracePeriod: 7,
			// noCoverage: [
			// 	'Lose Access To Create Invoice In Foreign Currencies',
			// 	'Lose Access To Vendor Split Payment',
			// 	'Lose Access To Petty Cash Managements',
			// 	'Lose Access To Employees Managements',
			// 	'Lose Multiple Admin Login',
			// 	'Lose Access To Payroll',
			// 	'Lose Access To Vendor Managements',
			// 	'Lose Access To Multi-Buisiness Management',
			// 	'Lose Access to In Person Accountant',
			// ],
			stockCount: 30,
			bookingLink: false,
			paymentLink: false,
			subWallet: false,
		},
		{
			vendorCount: 0,
			_id: '64625eef89dbe67073f03fb9',
			name: 'Premium',
			__v: 0,
			amount: 5500,
			coverage: [
				'E-commerce Website with Multi-currency Payment Gateway',
				'Order Management',
				'Order Due Date Reminder',
				'Balance Payment Reminder',
				'Customer Management (Unlimited)',
				'Measurement Storage',
				'Fashion Style Management (Unlimited)',
				'Infinite Online Fashion Catalogue',
				'Logistics',
				'Invoice/Receipt/Quotation',
				'Payment Link (Naira Payment Only)',
				'Booking Consultation Link (Multi Currency)',
				'Inventory Management (Up to 100 products)',
				'Income and Expense Report and Analysis',
				'Virtual Business Bank Account',
				'Employee Management (Max. 20 Employees)',
				'Vendor Management (Unlimited)',
				'Admin Management (Max. 2 Admins)',
				'Payroll',
				'Wallet (USD, EUR, GBP, NGN)',
				'Income, Expense, and Debt Report',
				'Business Insights',
				'Bulk SMS',
				'Manage Multiple Businesses (Max. 3 Businesses)',
			],
			createdAt: '2023-05-15T16:33:51.539Z',
			currencyCode: 'NGN',
			oneYearDiscountPer: 20,
			sixMonthsDiscountPer: 10,
			status: 'ACTIVE',
			threeMonthsDiscountPer: 5,
			updatedAt: '2023-05-21T15:41:26.797Z',
			rate: 1,
			adminCount: 0,
			businessCount: 0,
			customerCount: 100,
			employeeCount: 0,
			productCount: 30,
			gracePeriod: 7,
			// noCoverage: [
			// 	'Lose Access To Create Invoice In Foreign Currencies',
			// 	'Lose Access To Vendor Split Payment',
			// 	'Lose Access To Petty Cash Managements',
			// 	'Lose Access To Employees Managements',
			// 	'Lose Multiple Admin Login',
			// 	'Lose Access To Payroll',
			// 	'Lose Access To Vendor Managements',
			// 	'Lose Access To Multi-Buisiness Management',
			// 	'Lose Access to In Person Accountant',
			// ],
			stockCount: 30,
			bookingLink: false,
			paymentLink: false,
			subWallet: false,
		},
	];

	return (
		<section className={styles.subscription} id="Pricing">
			<MimaText variant="h2" mb={1}>
				Choose the best plan for your Fashion Business
			</MimaText>
			<MimaText variant="subtitle" mb={4}>
				Enjoy Unlimited access to all features for the first month
			</MimaText>
			<div className={styles.subscription__nav}>
				<div
					onClick={() => {
						setSubscription('MONTHLY');
					}}
					className={`${
						subscription === 'MONTHLY'
							? styles.subscription__nav__active
							: styles.subscription__nav__inactive
					}`}>
					Monthly billing
				</div>
				<div
					onClick={() => {
						setSubscription('QUARTERLY');
					}}
					className={`${
						subscription === 'QUARTERLY'
							? styles.subscription__nav__active
							: styles.subscription__nav__inactive
					}`}>
					Quarterly billing
				</div>
				<div
					onClick={() => {
						setSubscription('BIANNUAL');
					}}
					className={`${
						subscription === 'BIANNUAL'
							? styles.subscription__nav__active
							: styles.subscription__nav__inactive
					}`}>
					Biannual billing
				</div>
				<div
					onClick={() => {
						setSubscription('ANNUAL');
					}}
					className={`${
						subscription === 'ANNUAL'
							? styles.subscription__nav__active
							: styles.subscription__nav__inactive
					}`}>
					Annual billing
				</div>
			</div>

			<div className={styles.subscription__cardClass}>
				{fashSubs?.map(plan => (
					<div
						className={plan.name === 'Basic' ? styles.popular : ''}
						key={plan._id}>
						{plan.name === 'Basic' && (
							<MimaText
								variant="smallBold"
								mb={1}
								align="center"
								color="var(--color-white)">
								POPULAR
							</MimaText>
						)}

						<div className={styles.subscription__cardClass_card}>
							<MimaText variant="small" mb={1}>
								{plan.name} Plan
							</MimaText>
							{subscription === 'MONTHLY' && (
								<div className={styles.beside}>
									<MimaText variant="h3" mb={1}>
										{amountFormatter('NGN').format(plan.amount)}
									</MimaText>
									<MimaText>/ Month</MimaText>
								</div>
							)}
							{subscription === 'QUARTERLY' && (
								<>
									<div className={styles.beside}>
										<MimaText variant="h3">
											{amountFormatter('NGN').format(
												(plan.amount *
													3 *
													(100 - plan.threeMonthsDiscountPer)) /
													100
											)}
										</MimaText>
										<MimaText> / Quarter</MimaText>
									</div>
									<div className={styles.savings}>
										Save {plan.threeMonthsDiscountPer}%
									</div>
								</>
							)}
							{subscription === 'BIANNUAL' && (
								<>
									<div className={styles.beside}>
										<MimaText variant="h3">
											{amountFormatter('NGN').format(
												(plan.amount * 6 * (100 - plan.sixMonthsDiscountPer)) /
													100
											)}
										</MimaText>
										<MimaText> / Biannual</MimaText>
									</div>
									<div className={styles.savings}>
										Save {plan.sixMonthsDiscountPer}%
									</div>
								</>
							)}
							{subscription === 'ANNUAL' && (
								<>
									<div className={styles.beside}>
										<MimaText variant="h3">
											{amountFormatter('NGN').format(
												(plan.amount * 12 * (100 - plan.oneYearDiscountPer)) /
													100
											)}
										</MimaText>
										<MimaText> / Year</MimaText>
									</div>
									<div className={styles.savings}>
										Save {plan.oneYearDiscountPer}%
									</div>
								</>
							)}

							{plan.name === 'Lite' && (
								<MimaText variant="small">
									For small sized businesses. Billed every{' '}
									{subscription === 'MONTHLY'
										? 'month'
										: subscription === 'QUARTERLY'
											? 'quarter'
											: subscription === 'BIANNUAL'
												? 'six months'
												: 'year'}
									. Cancel anytime.
								</MimaText>
							)}

							{plan.name === 'Basic' && (
								<MimaText variant="small">
									For small and medium sized businesses. Billed every{' '}
									{subscription === 'MONTHLY'
										? 'month'
										: subscription === 'QUARTERLY'
											? 'quarter'
											: subscription === 'BIANNUAL'
												? 'six months'
												: 'year'}
									. Cancel anytime.
								</MimaText>
							)}

							{plan.name === 'Premium' && (
								<MimaText variant="small">
									For medium and large scale businesses. Billed every{' '}
									{subscription === 'MONTHLY'
										? 'month'
										: subscription === 'QUARTERLY'
											? 'quarter'
											: subscription === 'BIANNUAL'
												? 'six months'
												: 'year'}
									. Cancel anytime.
								</MimaText>
							)}

							<div className={styles.line}></div>
							<ul className="custom-list">
								{plan.coverage.map((covers, i) => (
									<li key={i}>{covers}</li>
								))}
							</ul>
							<div className={styles.getStarted}>
								<MimaButton
									title="Get Started"
									width={24}
									mt={3}
									onClick={() => {
										navigate(constant.Routes.SignUp);
									}}
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
};
export default Subscription;
