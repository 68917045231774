import constant from './constant';
import client from './client';
import config from './config';

const subdomain = window.location.hostname.split('.')[0];

const UseRequest = async ({
	baseUrl = subdomain.startsWith('dev-') || subdomain.startsWith('staging-')
		? config.BASE_DEV_API_URL
		: config.BASE_API_URL,
	url,
	method = 'get',
	payload = {},
	headers = {},
}) => {
	const requestUrl = mapBusinessId(baseUrl + url);
	return await client[method](requestUrl, payload, { headers });
};

const bId = '';

function mapBusinessId(url) {
	const urlObj = new URL(url);

	if (!bId) {
		return url;
	}

	if (urlObj.search) {
		urlObj.searchParams.append('bId', bId);
	} else {
		urlObj.search = new URLSearchParams({ bId }).toString();
	}
	return urlObj.toString();
}

const apiRequest = async ({
	url,
	method,
	payload,
	hasImageDownload,
	params,
	query,
	hasImageUpload,
	constantStore,
	responseStore,
}) => {
	try {
		const requestObject = {
			url,
			method,
		};
		if (payload) requestObject['payload'] = payload;
		if (params) requestObject['params'] = params;
		if (query) requestObject['query'] = query;

		if (hasImageUpload) {
			requestObject['headers'] = {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			};
		}
		if (hasImageDownload) {
			requestObject['headers'] = {
				responseType: 'blob',
			};
		}

		const response = await UseRequest(requestObject);
		if (constantStore && constantStore.length > 0) {
			for (let index = 0; index < constantStore.length; index++) {
				const item = constantStore[index];
				item.store(item.value);
			}
		}
		if (responseStore && responseStore.length > 0) {
			for (let index = 0; index < responseStore.length; index++) {
				const item = responseStore[index];
				item.store(response.data[item.value]);
			}
		}
		return { status: constant.Success, data: response.data };
	} catch (error) {
		const { data } = error.response || {};
		return {
			status: constant.Failed,
			errorMessage: data?.message ? data?.message : data?.error,
			data,
		};
	}
};

export { UseRequest, apiRequest };
