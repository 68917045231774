import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../../assets/styles/PaymentLink/PaymentLinkCheckout.module.scss';
import { FashMainLogo } from '../../assets/img/ImgList';

const PoweredBy = () => {
	return (
		<Link to="https://fashcreatives.com/" className={styles.mima}>
			<p>Powered by</p>{' '}
			<img src={FashMainLogo} className={styles.mima__logo} alt="mima Logo" />
		</Link>
	);
};

export default PoweredBy;
