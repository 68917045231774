// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MimaTable_numberedPage__VJgVr {
  width: 3rem;
  height: 3rem;
  font-size: var(--default-font-small);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--color-white);
}
.MimaTable_numberedPage__VJgVr:hover {
  border: 1px solid var(--color-fash-primary-2);
  color: var(--color-fash-primary-2);
}
.MimaTable_numberedPage__VJgVr:disabled {
  color: var(--color-grey);
  border: 1px solid var(--color-grey);
}

.MimaTable_numberPageActive__fjsM1 {
  border: 1px solid var(--color-fash-primary-2);
  color: var(--color-fash-primary-2);
  box-shadow: 0px 0px 7px rgba(245, 204, 22, 0.5);
}

.MimaTable_pagination__OOOM8 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}
.MimaTable_pagination__OOOM8 > :not(:last-child) {
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/MimaTable.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,YAAA;EACA,oCAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,oCAAA;AADJ;AAGI;EACI,6CAAA;EACA,kCAAA;AADR;AAKI;EACI,wBAAA;EACA,mCAAA;AAHR;;AAQA;EACI,6CAAA;EACA,kCAAA;EACA,+CAAA;AALJ;;AAQA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AALJ;AAOI;EACI,kBAAA;AALR","sourcesContent":["@import './variables/breakpoints';\n\n.numberedPage {\n    width: 3rem;\n    height: 3rem;\n    font-size: var(--default-font-small);\n    border-radius: 0.5rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    background-color: var(--color-white);\n\n    &:hover {\n        border: 1px solid var(--color-fash-primary-2);\n        color: var(--color-fash-primary-2);\n    }\n\n\n    &:disabled {\n        color: var(--color-grey);\n        border: 1px solid var(--color-grey);\n\n    }\n}\n\n.numberPageActive {\n    border: 1px solid var(--color-fash-primary-2);\n    color: var(--color-fash-primary-2);\n    box-shadow: 0px 0px 7px rgba(245, 204, 22, 0.5);\n}\n\n.pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 2rem;\n    width: 100%;\n\n    &> :not(:last-child) {\n        margin-right: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"numberedPage": `MimaTable_numberedPage__VJgVr`,
	"numberPageActive": `MimaTable_numberPageActive__fjsM1`,
	"pagination": `MimaTable_pagination__OOOM8`
};
export default ___CSS_LOADER_EXPORT___;
