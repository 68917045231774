/**
 * MobX store classes instantiation.
 * This is where all MobX stores are instantiated
 * and exported for use within the Provider.
 */
import { configurePersistable } from 'mobx-persist-store';
import React from 'react';
import { configure } from 'mobx';
import UserStore from './UserStore';
import WalletStore from './WalletStore';
import CatalogStore from './CatalogStore';
import PaymentLinkStore from './PaymentLinkStore';
/**
 * Configuration to ensure all state changes occur via MobX actions
 */

configure({ enforceActions: 'observed' });
configurePersistable(
	{
		storage: window.localStorage,
		expireIn: 86400000,
		removeOnExpiration: true,
		stringify: true,
		debugMode: false,
	},
	{ delay: 200, fireImmediately: false }
);

export const userStore = new UserStore();
export const walletStore = new WalletStore();
export const catalogStore = new CatalogStore();
export const paymentLinkStore = new PaymentLinkStore();

export const storesContext = React.createContext({
	userStore,
	walletStore,
	catalogStore,
	paymentLinkStore,
});

export const useStores = () => React.useContext(storesContext);
