import { useMemo, useState } from 'react';
import { StyleCatalogController } from '../controllers';
import { useNavigate } from 'react-router-dom';

const usePublicCatalogUtility = () => {
	const hostname = window.location.hostname;
	const fashSubdomain = hostname.split('.')[0];
	const domainParts = hostname.split('.');
	const customDomain = domainParts.length > 2 ? domainParts[1] : domainParts[0];
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	const subdomain = useMemo(() => {
		if (isCustomDomain) {
			return customDomain;
		}

		return fashSubdomain;
	}, [isCustomDomain, customDomain, fashSubdomain]);

	//for search
	const [filtering, setFiltering] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [limit, setLimit] = useState(30);
	const onLimitChange = limit => {
		setLimit(limit);
	};

	const getPageValue = page => page?.data?.styles || [];
	const getPageTotalCount = lastPage => lastPage?.data?.totalCounts || 0;

	const {
		//infinite scroll
		isLoading,
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
	} = StyleCatalogController.useGetPublicCatalogInfinite(
		searchQuery,
		getPageValue,
		getPageTotalCount,
		limit
	);

	return {
		isLoading,
		data,
		searchQuery,
		setSearchQuery,
		filtering,
		setFiltering,
		subdomain,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
		onLimitChange,
	};
};

export default usePublicCatalogUtility;
