import React from 'react';
import styles from '../../assets/styles/LandingPage/Testimonials.module.scss';
import useEmblaCarousel from 'embla-carousel-react';
import {
	NextButton,
	PrevButton,
	usePrevNextButtons,
} from './EmblaArrowControl';
import { slides } from '../../utils/testimonialData';
import { FashShape2 } from '../../assets/img/ImgList';

const Testimonials = () => {
	const options = { loop: true };
	const slideCount = 3;
	// const slides = Array.from(Array(slideCount).keys());

	const [emblaRef, emblaApi] = useEmblaCarousel(options);
	const {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick,
	} = usePrevNextButtons(emblaApi);
	return (
		<section className={styles.testimonials} id="testimonial">
			<div className={styles.embla}>
				<div className={styles.embla__btns}>
					<PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
					<NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
				</div>
				<div className={styles.embla__viewport} ref={emblaRef}>
					<div className={styles.embla__container}>
						{slides.map((slide, index) => (
							<div className={styles.embla__slide} key={index}>
								<div className={styles.embla__slide__img}>
									<img src={slide.imgUrl} alt="Testimonial" />
								</div>
								<div className={styles.embla__slide__texts}>
									<p>{slide.testimony}</p>
									<h5>{slide.name}</h5>
									<p>{slide.business}</p>
								</div>
								<div className={styles.embla__slide__pattern}>
									<img
										src={FashShape2}
										alt="shapes"
										className={styles.hero__shape}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Testimonials;
