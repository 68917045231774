import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../assets/styles/Components/ReceiptCheckBox.module.scss';

const propTypes = {
	title: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.any,
	checked: PropTypes.any,
};

const defaultProps = {
	title: '',
	value: '',
	checked: false,
	onChange: e => {},
	disabled: false,
};

const ReceiptCheckBox = ({ title, id, onChange, name, checked, ...props }) => {
	return (
		<label htmlFor={id} className={styles.rcb} {...props}>
			<input
				{...props}
				type="checkbox"
				name={name}
				id={id}
				onChange={onChange}
				checked={checked ? 'checked' : ''}
				className={styles.rcb__input}
			/>
			<span className={styles.rcb__check}></span>
			{title}
		</label>
	);
};

ReceiptCheckBox.propTypes = propTypes;

ReceiptCheckBox.defaultProps = defaultProps;

export default ReceiptCheckBox;
