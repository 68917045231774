import React from 'react';
import { PublicCatalogue } from '../../pages';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import { usePublicCatalogUtility } from '../../hooks';
import { catalogStore } from '../../stores';

const PublicCatalogueContainer = () => {
	const navigate = useNavigate();

	const goToCatalogHome = () => {
		return navigate(constant.Routes.Home);
	};

	const {
		isLoading,
		data,
		filtering,
		setFiltering,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		status,
		setSearchQuery,
		searchQuery,
	} = usePublicCatalogUtility();

	const { setOnePublicCatalog } = catalogStore;

	const handleTableClick = item => {
		setOnePublicCatalog(item);
		const navLink = `${constant.Routes.SinglePublicCatalogue}?${item?._id}`;
		navigate(navLink);
	};

	return (
		<PublicCatalogue
			isLoading={isLoading}
			data={data}
			goToCatalogHome={goToCatalogHome}
			filtering={filtering}
			setFiltering={setFiltering}
			handleTableClick={handleTableClick}
			fetchNextPage={fetchNextPage}
			hasNextPage={hasNextPage}
			isFetchingNextPage={isFetchingNextPage}
			status={status}
			setSearchQuery={setSearchQuery}
			searchQuery={searchQuery}
		/>
	);
};

export default PublicCatalogueContainer;
