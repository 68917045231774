import React, { useMemo, useState } from 'react';
import { catalogStore } from '../../stores';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

const CollectionInShopMenu = ({ setCollectionQuery, webSettings, data }) => {
	const [navMore, setNavMore] = useState(false);
	const [collectionIndex, selectedCollectionIndex] = useState(-1);

	const { collections } = catalogStore;

	const navigate = useNavigate();

	const usedCollection = useMemo(() => {
		const menu = [
			{
				name: 'Home',
				key: 'Home',
				actionLink: constant.Routes.Home,
			},
			{
				name: 'Shop',
				key: 'Shop',
				actionLink: '#',
			},
		];
		if (webSettings?.content?.sizeGuides?.length > 0) {
			menu.push({
				name: 'size guide',
				key: 'size guide',
				actionLink: constant.Routes.SizeGuide,
			});
		}
		if (webSettings?.content?.aboutUs?.text) {
			menu.push({
				name: 'about',
				key: 'about',
				actionLink: constant.Routes.WebsiteAbout,
			});
		}
		if (webSettings?.content?.faqs?.length > 0) {
			menu.push({
				name: 'faqs',
				key: 'faqs',
				actionLink: constant.Routes.WebsiteFaqs,
			});
		}
		return menu;
	}, [webSettings]);

	const moreCollections = [
		{
			name: 'Shop All',
			key: 'All',
		},
		...collections,
	];

	const headerMenuHandler = (action, collection) => {
		if (collection) {
			return navigate(`${action}?collection=${collection}`);
		}
		return navigate(action);
	};

	return (
		<>
			<nav className={styles.catalog__header__nav}>
				<ul>
					{usedCollection.map((c, i) => (
						<li
							key={i}
							onClick={() => {
								headerMenuHandler(c.actionLink);
								if (c.key === 'Shop') {
									selectedCollectionIndex(-1);
									setNavMore(!navMore);
								} else {
									setNavMore(false);
								}
							}}
							className={
								window.location.pathname === c.actionLink
									? styles.catalog__header__nav__selected
									: ''
							}
							style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
							{c?.name?.toUpperCase()}{' '}
							{c?.key === 'Shop' ? (
								<MdOutlineKeyboardArrowDown
									style={
										navMore
											? {
													fontSize: '2rem',
													transform: 'rotate(180deg)',
													transition: 'transform 0.3s ease',
												}
											: { fontSize: '2rem', transition: 'transform 0.3s ease' }
									}
								/>
							) : (
								''
							)}
						</li>
					))}
					{navMore ? (
						<div className={styles.more__rel}>
							{navMore ? (
								<motion.nav
									initial={{
										y: -10,
									}}
									animate={{
										y: 0,
										transition: {
											duration: 0.2,
										},
									}}
									exit={{
										y: -10,
										transition: {
											duration: 0.2,
										},
									}}
									className={styles.more2}>
									<ul>
										{moreCollections.map((c, i) => (
											<li
												key={i}
												onClick={() => {
													setCollectionQuery(c.key);
													headerMenuHandler(constant.Routes.WebsiteShop, c.key);
													selectedCollectionIndex(i);
													setNavMore(false);
												}}
												className={
													collectionIndex === i
														? styles.catalog__header__nav__selected
														: ''
												}>
												{c?.name?.toUpperCase()}
											</li>
										))}
									</ul>
								</motion.nav>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}
				</ul>
			</nav>
			{navMore ? (
				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
						transition: {
							duration: 0.2,
						},
					}}
					exit={{
						opacity: 0,
						transition: {
							duration: 0.2,
						},
					}}
					className={styles.catalog__modal}>
					<div
						onClick={() => {
							setNavMore(false);
						}}></div>
				</motion.div>
			) : (
				''
			)}
		</>
	);
};

export default CollectionInShopMenu;
