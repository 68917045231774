import React, { useEffect, useMemo, useState } from 'react';
import styles from '../../assets/styles/EditMode/EditMode.module.scss';
import { openRoutes } from '../../router';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import EditModal from './EditModal';
import { GrEdit } from 'react-icons/gr';
import ConfigModal from './ConfigModal';
import { catalogStore, userStore } from '../../stores';
import { IoMdClose } from 'react-icons/io';
import { useTheme } from '../../context/ThemeContext';
import SectionEditModal from './SectionEditModal';

const EditMode = () => {
	const [selectedPage, setSelectedPage] = useState({});
	const [showPages, setShowPages] = useState(false);
	const [editModal, setEditModal] = useState(false);
	const [configModal, setConfigModal] = useState(false);

	const { sectionModal, setSectionModal, sectionModalId, setSectionModalId } =
		useTheme();

	const { themeData, configThemeData, setEditMode } = catalogStore;
	const { setToken } = userStore;

	const usedThemeData = useMemo(() => {
		if (themeData?.pages?.length > 0) {
			return themeData;
		}

		return configThemeData;
	}, [configThemeData, themeData]);

	const availablePages = useMemo(() => {
		if (usedThemeData?.pages?.length > 0) {
			return usedThemeData?.pages.map(page => {
				const matchingObj = openRoutes.find(
					route => route.component === page.name
				);
				if (matchingObj) {
					return {
						...page,
						path: matchingObj.path,
					};
				}

				if (page.name === 'Home' && !page.path) {
					return {
						...page,
						path: '/',
					};
				}
				return page;
			});
		}

		return [];
	}, [usedThemeData?.pages]);

	const currentPath = window.location.pathname;

	useEffect(() => {
		if (availablePages.length > 0) {
			const currentPage = availablePages.filter(x => x.path === currentPath)[0];

			setSelectedPage(currentPage);
		} else {
			setSelectedPage({});
		}
	}, [availablePages, currentPath]);

	const navigate = useNavigate();

	const pagesHandler = page => {
		navigate(page.path);
		setSelectedPage(page);
		setShowPages(!showPages);
	};

	const closeModal = () => {
		setEditModal(false);
		setConfigModal(false);
		setSectionModal(false);
		setSectionModalId('');
	};
	const quickEditHandler = () => {
		setEditModal(true);
	};
	const configHandler = () => {
		setConfigModal(true);
	};
	const leaveEditHandler = () => {
		setToken('');
		setEditMode(false);
	};

	return (
		<section className={styles.editMode}>
			<div>
				<div className={styles.pages}>
					<div
						className={styles.pages__selected}
						onClick={() => {
							setShowPages(!showPages);
						}}>
						<p>Page: {selectedPage?.name}</p>
						{showPages ? <FaCaretUp /> : <FaCaretDown />}
					</div>
					{showPages ? (
						<div className={styles.pages__drop}>
							{availablePages.map((page, i) => (
								<div
									key={i}
									onClick={() => {
										pagesHandler(page);
									}}
									className={styles.pages__drop__item}>
									<p>{page?.name}</p>
								</div>
							))}
						</div>
					) : (
						''
					)}
				</div>
				{selectedPage?.name ? (
					<button
						onClick={quickEditHandler}
						className={styles.editMode__button}>
						<GrEdit /> <span>Quick Edit</span>{' '}
					</button>
				) : (
					''
				)}
			</div>

			<div>
				<button onClick={configHandler} className={styles.editMode__button}>
					<GrEdit /> <span>Change General Settings</span>{' '}
				</button>
				<button
					onClick={leaveEditHandler}
					className={styles.editMode__button}
					style={{
						backgroundColor: 'var(--color-error)',
						color: 'var(--color-white)',
					}}>
					<IoMdClose /> <span>Leave Edit Mode</span>{' '}
				</button>
			</div>

			{(editModal || configModal || sectionModal) && (
				<div className="modal">
					<AnimatePresence>
						{editModal && (
							<EditModal
								closeModal={closeModal}
								page={selectedPage}
								generalSettings={usedThemeData?.generalSettings}
							/>
						)}
						{sectionModal && (
							<SectionEditModal
								closeModal={closeModal}
								page={selectedPage}
								generalSettings={usedThemeData?.generalSettings}
								sectionModalId={sectionModalId}
							/>
						)}
						{configModal && (
							<ConfigModal
								closeModal={closeModal}
								page={selectedPage}
								generalSettings={usedThemeData?.generalSettings}
							/>
						)}
					</AnimatePresence>
				</div>
			)}
		</section>
	);
};

export default EditMode;
