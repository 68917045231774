import React, { useEffect, useMemo, useState } from 'react';
import { SingleCatalog } from '../../pages';
import { StyleCatalogController } from '../../controllers';
import { useNavigate, useParams } from 'react-router-dom';
import constant from '../../utils/constant';
import { catalogStore } from '../../stores';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { MimaToastUtil } from '../../components';

const SingleCatalogContainer = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [rdModal, setRDModal] = useState(false);
	const [initData, setInitData] = useState({});

	const closeModal = () => {
		setRDModal(false);
	};

	// const subdomain = window.location.hostname.split('.')[0];
	const hostname = window.location.hostname;
	const fashSubdomain = hostname.split('.')[0];
	const domainParts = hostname.split('.');
	const customDomain = domainParts.length > 2 ? domainParts[1] : domainParts[0];
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	const subdomain = useMemo(() => {
		if (isCustomDomain) {
			return customDomain;
		}

		return fashSubdomain;
	}, [isCustomDomain, customDomain, fashSubdomain]);
	const { slug } = useParams();

	const query = useMemo(() => {
		return `slug=${slug}&subDomain=${subdomain}`;
	}, [slug, subdomain]);

	useEffect(() => {
		const getSingleCatalog = async () => {
			setLoading(true);
			const { data, errorMessage } =
				await StyleCatalogController.getSingleStyleCatalog(query);

			if (errorMessage === constant.errorMsg.styleNotFound) {
				return navigate(constant.Routes.Error404);
			}
			setInitData(data);
			setLoading(false);
		};

		getSingleCatalog();
	}, [query]);

	const goToCatalogHome = () => {
		navigate(constant.Routes.Home);
	};

	const validationSchema = yup.object().shape({
		action: yup.string().optional(),
		validate: yup.boolean().optional(),
		amount: yup.number().when('validate', {
			is: true,
			then: yup
				.number()
				.required('Amount is required')
				.positive('Amount must be positive')
				.min(1, 'Amount is required'),
			otherwise: yup.number().optional(),
		}),
		amountPerItem: yup.number().when('validate', {
			is: true,
			then: yup
				.number()
				.required('Select Price')
				.positive('Price must be positive')
				.min(1, 'Select Price'),
			otherwise: yup.number().optional(),
		}),
		quantity: yup
			.number()
			.positive('Quantity must be positive')
			.min(1, 'Quantity is required')
			.required('Quantity is required'),
		standardSize: yup.string().when('hasSize', {
			is: true,
			then: yup.string().required('Size is required'),
			otherwise: yup.string().optional(),
		}),

		// Additional fields and validations
	});

	const onSubmit = values => {
		const storedFashCart = JSON.parse(localStorage.getItem('fashCart')) || [];

		if (values.action === 'ADD TO CART') {
			setLoading2(true);
			const differentCurrency = storedFashCart.some(
				item => item.currencyCode !== values.currencyCode
			);
			if (differentCurrency) {
				setLoading2(false);
				return MimaToastUtil.error({
					message:
						'Different currencyCode detected. Cannot add the item to the cart.',
				});
			}
			const fashCart = [...storedFashCart, values];
			localStorage.setItem('fashCart', JSON.stringify(fashCart));
			MimaToastUtil.success({ message: 'Added to Cart' });
			setLoading2(false);
			return;
		}

		if (values.action === 'MAKE PAYMENT') {
			setLoading2(true);
			const differentCurrency = storedFashCart.some(
				item => item.currencyCode !== values.currencyCode
			);
			if (differentCurrency) {
				setLoading2(false);
				return MimaToastUtil.error({
					message:
						'Different currencyCode detected. Cannot add the item to the cart.',
				});
			}
			const fashCart = [...storedFashCart, values];
			localStorage.setItem('fashCart', JSON.stringify(fashCart));
			MimaToastUtil.success({ message: 'Added to Cart' });
			navigate(constant.Routes.WebsiteCart);
			// const catalogArray = [values];
			// catalogStore.setCatalogOrderInfo(catalogArray);
			// navigate(constant.Routes.PaymentCheckout);
			setLoading2(false);
		}

		if (values.action === 'BOOK CONSULTATION') {
			setLoading3(true);
			catalogStore.setCatalogOrderInfo(values);
			window.open(catalogStore.singleCatalog?.bookingLinkUrl, '_self');
			setLoading3(false);
		}

		if (values.action === 'REQUEST DESIGN') {
			setLoading3(true);
			catalogStore.setCatalogOrderInfo(values);
			setRDModal(true);
			setLoading3(false);
		}
	};
	return (
		<SingleCatalog
			goToCatalogHome={goToCatalogHome}
			onSubmit={onSubmit}
			loading={loading}
			loading2={loading2}
			loading3={loading3}
			closeModal={closeModal}
			rdModal={rdModal}
			initData={initData}
			validationSchema={validationSchema}
		/>
	);
};

export default observer(SingleCatalogContainer);
