export const useAsyncDebounce = (fn, delay) => {
	let timeoutId;

	return async value => {
		clearTimeout(timeoutId);

		return new Promise(resolve => {
			timeoutId = setTimeout(() => {
				fn(value);
				resolve();
			}, delay);
		});
	};
};
