import DropIcon from '../../assets/svg/arrowDown.svg';
import { css } from 'styled-components';

export const base = css`
	padding: 1.9rem 2.2rem;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-weight: 700;
	border-radius: 1rem;
	cursor: pointer;
	display: flex;
	gap: 1rem;
	&:disabled {
		background-color: var(--color-grey-2);
		&:hover {
			background-color: var(--color-grey-2);
		}
	}
`;

export const contained = css`
	background-color: var(--color-fash-black);
	color: var(--color-white);
	width: fit-content;
	&:hover {
		background-color: var(--color-fash-black-2);
	}
`;

export const regular = css`
	background-color: var(--color-fash-black);
	color: var(--color-white);
	width: 38rem;
	&:hover {
		background-color: var(--color-fash-black-2);
	}
`;

export const outlined = css`
	border: 1px solid var(--color-fash-black);
	color: var(--color-fash-black);
	box-sizing: border-box;
	&:hover {
		border: 2px solid var(--color-fash-primary-2);
		color: var(--color-fash-primary-2);
	}
`;

export const outlined2 = css`
	width: 100%;
	border: 1px solid var(--color-dark);
	color: var(--color-dark);
	&:hover {
		border: 2px solid var(--color-dark);
		filter: brightness(0.8);
	}
`;

export const outlined3 = css`
	border: 1px solid var(--color-dark);
	color: var(--color-dark);
	padding: 0.5rem;
	height: 4rem;
	font-size: var(--default-font-small);
	width: 100%;
	&:hover {
		border: 2px solid var(--color-primary);
		color: var(--color-primary);
	}
`;

export const form = css`
	background-color: var(--color-primary-2);
	color: var(--color-white);
	width: 100%;
	margin-top: 2rem;
	&:hover {
		background-color: var(--color-primary-4);
	}
`;

export const formColorable = css`
	background-color: var(--color-primary-2);
	color: var(--color-white);
	width: 100%;
	&:hover {
		filter: brightness(0.8);
	}
`;

export const whiteSquare = css`
	background-color: var(--color-white);
	color: var(--color-dark);
	border-radius: 0;
	width: fit-content;
	&:hover {
		filter: brightness(0.8);
	}
`;

export const text = css`
	color: var(--color-dark);
	text-decoration: underline;
	&:hover {
		color: var(--color-fash-primary-2);
	}
`;

export const text2 = css`
	color: var(--color-primary-2);
	text-decoration: underline;
	text-align: center;
	&:hover {
		color: var(--color-primary-4);
	}
`;

export const text3 = css`
	color: var(--color-primary-2);
	text-decoration: underline;
	position: relative;
	left: 29rem;
	&::after {
		content: '';
		background: url('../../svg/arrowDown.svg');
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		height: 100%;
		width: 1.6rem;
		right: 0;
		top: 2.5rem;
		z-index: 10;
	}
	&:hover {
		color: var(--color-primary-4);
	}
`;

export const close = css`
	background-color: var(--color-error);
	color: var(--color-white);
	width: 5rem;
	height: 5rem;
	border-radius: 5rem;
	font-size: 2rem;
	&:hover {
		background-color: var(--color-deep-red);
	}
`;

export const small = css`
	background-color: var(--color-primary-2);
	color: var(--color-white);
	width: 18rem;
	&:hover {
		filter: brightness(0.7);
	}
`;

export const input = css`
	border-radius: 1rem;
	background-color: var(--color-white);
	border: 1px solid var(--color-dark);
	color: var(--color-dark);
	width: 38rem;
	font-weight: 400;
	position: relative;
	justify-content: start;
	&:hover {
		background-color: var(--color-light);
	}
	&::after {
		content: '';
		background: url(${DropIcon});
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		height: 100%;
		width: 1.6rem;
		right: 2rem;
		top: 2.5rem;
		transition: all 0.4s;
	}
`;

export const inputClick = css`
	border-radius: 1rem;
	background-color: var(--color-white);
	border: 1px solid var(--color-dark);
	color: var(--color-dark);
	color: var(--color-dark);
	width: 18rem;
	font-weight: 700;
	text-align: center;
	&:hover {
		background-color: var(--color-light);
	}
	&:focus {
		background-color: var(--color-primary);
		color: var(--color-white);
	}
`;

export const smaller = css`
	background-color: var(--color-primary-2);
	color: var(--color-white);
	padding: 0.5rem 1rem;
	font-size: var(--default-font-xsmall);
	font-weight: 400;
	&:hover {
		background-color: var(--color-primary-4);
	}
`;

export const income = css`
	color: var(--color-dark-3);
	text-decoration: underline;
`;

export const incomeSmall = css`
	color: var(--color-dark-3);
	text-decoration: underline;
	font-size: 1.6rem;
`;

export const receipts = css`
	border: 1px solid var(--color-dark-3);
	background-color: var(--color-dark-3);
	color: var(--color-white);
`;

export const colorable = css`
	background-color: var(--color-primary-2);
	color: var(--color-white);
	width: 38rem;
	&:hover {
		filter: brightness(0.8);
	}
`;

export const colorableSmall = css`
	background-color: var(--color-primary-2);
	color: var(--color-white);
	width: 100%;
	padding: 0;
	height: 4rem;
	font-size: var(--default-font-small);
	&:hover {
		filter: brightness(0.8);
	}
`;

export const filter = css`
	background-color: var(--color-dark);
	font-size: var(--default-font-small);
	color: var(--color-white);
	padding: 0.9rem 2.2rem;
	width: 38rem;
	&:hover,
	&:focus {
		background-color: var(--color-dark-6);
	}
`;

export const filterForm = css`
	background-color: var(--color-dark);
	font-size: var(--default-font-small);
	color: var(--color-white);
	padding: 0.9rem 2.2rem;
	width: 100%;
	&:hover,
	&:focus {
		background-color: var(--color-dark-6);
	}
`;
