import { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleCatalogController } from '../controllers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { catalogStore, userStore } from '../stores';
import { areObjectsEqual, encodeQueryData } from '../utils/utils';
import constant from '../utils/constant';
import { useTheme } from '../context/ThemeContext';
import useUserTheme from './useUserTheme';

const useCatalogUtility = () => {
	const hostname = window.location.hostname;
	const fashSubdomain = hostname.split('.')[0];
	const domainParts = hostname.split('.');
	const customDomain = domainParts.length > 2 ? domainParts[1] : domainParts[0];
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	const subdomain = useMemo(() => {
		if (isCustomDomain) {
			return customDomain;
		}

		return fashSubdomain;
	}, [isCustomDomain, customDomain, fashSubdomain]);

	const {
		locationInfo,
		nextCollectionsCall,
		setEditMode,
		userTheme,
		setUserTheme,
		themeData,
		configThemeData,
		setMetaDetails,
		metaDetails,
	} = catalogStore;

	//for search field
	const [showSearch, setShowSearch] = useState(false);

	//for customer table and pagination
	const [limit, setLimit] = useState(24);
	// const [filterQuery, setFilterQuery] = useState('');
	const [currentPage, setCurrentPage] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [filtering, setFiltering] = useState('');
	const [collectionQuery, setCollectionQuery] = useState('');
	const [currencyCode, setCurrencyCode] = useState(
		locationInfo?.currencyCode !== 'NGN' ? 'USD' : 'NGN'
	);
	const [sortBy, setSortBy] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [bestSelling, setBestSelling] = useState('');
	const [inStock, setInStock] = useState('');

	useEffect(() => {
		const getCollections = async () => {
			await StyleCatalogController.getCollections(subdomain, currencyCode);
		};

		const currentTime = new Date().getTime();

		if (!nextCollectionsCall || currentTime >= nextCollectionsCall) {
			getCollections();
		}
	}, [subdomain, currencyCode, nextCollectionsCall]);

	const query = useMemo(() => {
		return {
			limit: limit,
			storeFrontName: subdomain,
			offset: currentPage + 1,
			currencyCode: currencyCode,
			...(collectionQuery && collectionQuery !== 'All'
				? { styleCollection: collectionQuery }
				: {}),
			...(searchQuery ? { search: searchQuery } : {}),
			...(sortBy ? { sortBy: sortBy } : {}),
			...(sortOrder ? { sortOrder: sortOrder } : {}),
			...(bestSelling ? { bestSelling: bestSelling } : {}),
			...(inStock ? { inStock: inStock } : {}),
		};
	}, [
		currentPage,
		limit,
		subdomain,
		collectionQuery,
		currencyCode,
		searchQuery,
		sortBy,
		sortOrder,
		bestSelling,
		inStock,
	]);

	const encodeQuery = encodeQueryData({
		...query,
	});

	const { isLoading, data, isFetching } =
		StyleCatalogController.useGetStyleCatalog(encodeQuery);

	const onLimitChange = limit => {
		setLimit(limit);
		setCurrentPage(0);
	};

	const navigate = useNavigate();

	const handleTableClick = row => {
		const navLink = `/catalog/${row.slug}`;
		navigate(navLink);
	};

	const shippingInfo = useMemo(() => {
		if (data?.status === constant.Success) {
			if (currencyCode === 'NGN') {
				return data?.data?.websiteSettings?.content?.shipping?.local || {};
			} else {
				return (
					data?.data?.websiteSettings?.content?.shipping?.international || {}
				);
			}
		}
		return {};
	}, [data, currencyCode]);

	useEffect(() => {
		const setShipping = () => {
			catalogStore.setShippingInfo(shippingInfo);

			if (data?.errorMessage === constant.errorMsg.storeNotFound) {
				navigate(
					`${constant.Routes.Error404}?error=${constant.errorMsg.storeNotFound}`
				);
			}
		};

		setShipping();
	}, [data, shippingInfo]);

	const goToShop = () => {
		navigate(constant.Routes.WebsiteShop);
	};

	//For cart

	const [fashCart, setFashCart] = useState([]);

	useEffect(() => {
		const storedFashCart = JSON.parse(localStorage.getItem('fashCart')) || [];
		setFashCart(storedFashCart);
	}, []);

	//Current Theme and Meta Tawk Check
	const { toggleTheme } = useTheme();
	const { setUserThemeData } = useUserTheme();

	const checkCurrentTheme = () => {
		const theme = data?.data?.websiteSettings?.content?.theme;

		let themeKey;
		switch (theme) {
			case 'UPGRADE':
				themeKey = 'hookedByLade';
				break;
			case 'LUXEFLEX':
				themeKey = 'luxeFlex';
				break;
			case 'DEFAULT':
				themeKey = 'default';
				break;
			default:
				themeKey = 'default';
		}

		const userThemeInfo = {
			theme: themeKey,
			createdAt: new Date().getTime().toString(),
			expirationTime: 60 * 60 * 1000,
			backendThemeName: data?.data?.websiteSettings?.content?.theme,
		};

		setUserTheme(userThemeInfo);
		setMetaDetails(metaDetails);

		toggleTheme(themeKey);

		setUserThemeData(data?.data, themeKey);
	};

	const newMetaDetails = useMemo(() => {
		return {
			businessName: data?.data?.websiteSettings?.business?.tradingName,
			favicon: data?.data?.websiteSettings?.business?.logoUrl,
			localCurrency: data?.data?.websiteSettings?.business?.localCurrency,
			aboutDescription: data?.data?.websiteSettings?.content?.aboutUs?.text,
			tawk: data?.data?.websiteSettings?.tawk,
		};
	}, [data?.data?.websiteSettings]);

	useEffect(() => {
		if (
			userTheme?.backendTheme !== data?.data?.websiteSettings?.content?.theme
		) {
			checkCurrentTheme();
		}

		if (!areObjectsEqual(newMetaDetails, metaDetails)) {
			setMetaDetails(metaDetails);
		}
	}, [
		userTheme?.backendTheme,
		data?.data?.websiteSettings?.content?.theme,
		newMetaDetails,
	]);

	//EDITING THEME
	const { setToken } = userStore;

	const [searchParams] = useSearchParams();
	const editMode = searchParams.get('edit');
	const token = searchParams.get('token');

	useEffect(() => {
		if (editMode === 'true' && token) {
			if (editMode === 'true') {
				setEditMode(true);
			} else {
				setEditMode(false);
			}
			setToken(token);
		}
	}, [editMode, token]);

	const usedThemeData = useMemo(() => {
		if (themeData?.pages?.length > 0) {
			return themeData;
		}

		return configThemeData;
	}, [configThemeData, themeData]);

	const getPageById = useCallback(
		id => {
			return usedThemeData?.pages.find(page => page.id === id);
		},
		[usedThemeData]
	);

	const getPageSectionById = useCallback((page, id) => {
		return page?.sections.find(section => section.id === id);
	}, []);

	return {
		isLoading,
		data,
		isFetching,
		onLimitChange,
		searchQuery,
		setSearchQuery,
		limit,
		currentPage,
		setCurrentPage,
		handleTableClick,
		filtering,
		setFiltering,
		collectionQuery,
		setCollectionQuery,
		setCurrencyCode,
		currencyCode,
		showSearch,
		setShowSearch,
		shippingInfo,
		goToShop,
		setSortBy,
		setSortOrder,
		bestSelling,
		setBestSelling,
		inStock,
		setInStock,
		fashCart,
		setFashCart,
		usedThemeData,
		getPageById,
		getPageSectionById,
	};
};

export default useCatalogUtility;
