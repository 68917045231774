import React, { useEffect, useState } from 'react';
import { CatalogCart } from '../../pages';
import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import { catalogStore } from '../../stores';

const CatalogCartContainer = () => {
	const navigate = useNavigate();

	const [fashCart, setFashCart] = useState([]);
	const [quantities, setQuantities] = useState([]);

	useEffect(() => {
		const storedFashCart = JSON.parse(localStorage.getItem('fashCart')) || [];
		setQuantities(storedFashCart?.map(cart => cart?.quantity));
		setFashCart(storedFashCart);
	}, []);

	// const [loading, setLoading] = useState({});

	// const asyncDebounce = useAsyncDebounce(updateCartQuantity, 1000);

	const updateCartQuantityHandler = async (cart, newQuantities, index) => {
		const updatedCart = fashCart.map((item, index) => {
			return {
				...item,
				quantity: newQuantities[index],
				amount: newQuantities[index] * item.amountPerItem,
			};
		});
		setFashCart(updatedCart);
		localStorage.setItem('fashCart', JSON.stringify(updatedCart));

		// const payload = {
		// 	cartId: cart.id,
		// 	quantity: newQuantities[index],
		// };
		// setLoading(prevState => ({ ...prevState, [cart.id]: true }));
		// if (payload.quantity) await asyncDebounce(payload);

		// setLoading(prevState => ({ ...prevState, [cart.id]: false }));
	};

	const handleQtyChange = (index, event, cart) => {
		const newQuantities = [...quantities];
		newQuantities[index] = parseInt(event.target.value || '0');
		setQuantities(newQuantities);
		updateCartQuantityHandler(cart, newQuantities, index);
	};

	const handleIncrement = (index, cart) => {
		const newQuantities = [...quantities];
		newQuantities[index]++;
		setQuantities(newQuantities);

		updateCartQuantityHandler(cart, newQuantities, index);
	};

	const handleDecrement = (index, cart) => {
		const newQuantities = [...quantities];
		if (newQuantities[index] > 1) {
			newQuantities[index]--;
			setQuantities(newQuantities);
		}

		updateCartQuantityHandler(cart, newQuantities, index);
	};

	const removeItemHandler = index => {
		// setLoading(prevState => ({ ...prevState, [id]: true }));
		// await removeFromCart(id);
		// setLoading(prevState => ({ ...prevState, [id]: false }));
		const newQuantities = [...quantities];
		const updatedCartItems = [...fashCart];

		updatedCartItems.splice(index, 1);
		newQuantities.splice(index, 1);

		setFashCart(updatedCartItems);
		setQuantities(newQuantities);

		localStorage.setItem('fashCart', JSON.stringify(updatedCartItems));
	};

	const goToCatalogHome = () => {
		navigate(constant.Routes.Home);
	};

	const calcSubtotalPrice = items => {
		return items?.reduce((totalPrice, cart) => {
			const { amountPerItem, quantity } = cart;
			return totalPrice + amountPerItem * quantity;
		}, 0);
	};

	const checkoutHandler = () => {
		catalogStore.setCatalogOrderInfo(fashCart);
		navigate(constant.Routes.PaymentCheckout);
	};
	return (
		<CatalogCart
			fashCart={fashCart}
			goToCatalogHome={goToCatalogHome}
			handleQtyChange={handleQtyChange}
			handleDecrement={handleDecrement}
			removeItemHandler={removeItemHandler}
			handleIncrement={handleIncrement}
			quantities={quantities}
			calcSubtotalPrice={calcSubtotalPrice}
			checkoutHandler={checkoutHandler}
		/>
	);
};

export default CatalogCartContainer;
