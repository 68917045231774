// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReceiptListItem_rec__oq67d {
  width: 38rem;
  display: grid;
  grid-template-columns: 1.8fr 1fr 1fr;
  gap: 2rem;
  box-sizing: border-box;
  border-radius: 1rem;
  background-color: var(--color-primary-3);
  padding: 1rem 2rem;
  position: relative;
  margin-bottom: 2rem;
}
.ReceiptListItem_rec__expand__IBsKD {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  cursor: pointer;
}
.ReceiptListItem_rec__expand__IBsKD:hover {
  color: var(--color-primary);
}
.ReceiptListItem_rec__main__2DF-C {
  font-weight: 700;
  font-size: var(--default-font-small);
  color: var(--color-primary-4);
}

.ReceiptListItem_item__ykyFa {
  display: grid;
  grid-template-columns: 1.3fr 1fr;
  gap: 2rem;
  margin-bottom: 1rem;
}
.ReceiptListItem_item__qty__Kb43j {
  font-size: var(--default-font-small);
}
.ReceiptListItem_item__total__FhYsc {
  font-weight: 700;
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Components/ReceiptListItem.module.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,aAAA;EACA,oCAAA;EACA,SAAA;EAEA,sBAAA;EACA,mBAAA;EACA,wCAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;AAAD;AAEC;EACC,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAAF;AAEE;EACC,2BAAA;AAAH;AAIC;EACC,gBAAA;EACA,oCAAA;EACA,6BAAA;AAFF;;AAMA;EACC,aAAA;EACA,gCAAA;EACA,SAAA;EACA,mBAAA;AAHD;AAKC;EACC,oCAAA;AAHF;AAMC;EACC,gBAAA;EACA,2BAAA;AAJF","sourcesContent":[".rec {\n\twidth: 38rem;\n\tdisplay: grid;\n\tgrid-template-columns: 1.8fr 1fr 1fr;\n\tgap: 2rem;\n\n\tbox-sizing: border-box;\n\tborder-radius: 1rem;\n\tbackground-color: var(--color-primary-3);\n\tpadding: 1rem 2rem;\n\tposition: relative;\n\tmargin-bottom: 2rem;\n\n\t&__expand {\n\t\tposition: absolute;\n\t\tright: 2rem;\n\t\tbottom: 1rem;\n\t\tcursor: pointer;\n\n\t\t&:hover {\n\t\t\tcolor: var(--color-primary);\n\t\t}\n\t}\n\n\t&__main {\n\t\tfont-weight: 700;\n\t\tfont-size: var(--default-font-small);\n\t\tcolor: var(--color-primary-4);\n\t}\n}\n\n.item {\n\tdisplay: grid;\n\tgrid-template-columns: 1.3fr 1fr;\n\tgap: 2rem;\n\tmargin-bottom: 1rem;\n\n\t&__qty {\n\t\tfont-size: var(--default-font-small);\n\t}\n\n\t&__total {\n\t\tfont-weight: 700;\n\t\tcolor: var(--color-primary);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rec": `ReceiptListItem_rec__oq67d`,
	"rec__expand": `ReceiptListItem_rec__expand__IBsKD`,
	"rec__main": `ReceiptListItem_rec__main__2DF-C`,
	"item": `ReceiptListItem_item__ykyFa`,
	"item__qty": `ReceiptListItem_item__qty__Kb43j`,
	"item__total": `ReceiptListItem_item__total__FhYsc`
};
export default ___CSS_LOADER_EXPORT___;
