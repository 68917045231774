import React, { useEffect, useMemo } from 'react';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import { useCatalogUtility } from '../../hooks';
import constant from '../../utils/constant';
import { amountFormatter } from '../../utils/utils';
import { MimaGridView, MimaText } from '../../components';
import ImageSlideshow from './ImageSlideshow';
import CatalogHomeHeader from './CatalogHomeHeader';
import Footer from './Footer';
import { useSearchParams } from 'react-router-dom';

const CatalogShop = () => {
	const [searchParams] = useSearchParams();
	const collection = searchParams.get('collection');

	const {
		isLoading,
		data,
		isFetching,
		onLimitChange,
		setSearchQuery,
		limit,
		currentPage,
		setCurrentPage,
		handleTableClick,
		filtering,
		setFiltering,
		collectionQuery,
		setCollectionQuery,
		setCurrencyCode,
		currencyCode,
		showSearch,
		setShowSearch,
	} = useCatalogUtility();

	useEffect(() => {
		const collectionActive = () => {
			if (collection) {
				return setCollectionQuery(collection);
			}
		};

		collectionActive();
	}, [collection]);

	const tableData = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles || [];
		}
		return [];
	}, [data]);

	const tableColumns = [
		{
			header: 'Image',
			accessorKey: 'imageUrls',
			cell: item => {
				const imageUrls = item?.getValue();
				const sellingPrices = item?.row?.original?.sellingPrices;
				const sellingPrice = sellingPrices.filter(
					x => x?.currencyCode === currencyCode
				)[0];
				return imageUrls ? (
					<ImageSlideshow imageUrls={imageUrls} sellingPrice={sellingPrice} />
				) : (
					<span>N/A</span>
				);
			},
		},
		{
			header: 'Product Name',
			accessorKey: 'name',
			cell: item => {
				const name = item?.getValue();

				return <MimaText variant="small">{name}</MimaText>;
			},
		},
		{
			header: 'Selling Price',
			accessorKey: 'sellingPrices',
			cell: item => {
				const sellingPrices = item?.getValue();
				const filteredPrice = sellingPrices.filter(
					x => x?.currencyCode === currencyCode
				)[0];
				const withOutMaterial = filteredPrice?.withoutMaterial;
				const withMaterial = filteredPrice?.withMaterial;
				const shownPrice = () => {
					if (withMaterial > withOutMaterial) {
						return withMaterial;
					} else if (withMaterial < withOutMaterial) {
						return withOutMaterial;
					} else if (withOutMaterial) {
						return withOutMaterial;
					} else if (withMaterial) {
						return withMaterial;
					} else {
						return '';
					}
				};
				const formattedPrice =
					shownPrice() !== ''
						? amountFormatter(currencyCode).format(shownPrice())
						: '';
				const formattedPromoPrice = amountFormatter(currencyCode).format(
					filteredPrice?.onSaleSlashPrice
				);

				return (
					<>
						{filteredPrice?.onSaleSlashPrice > 0 ? (
							<div className={styles.sales}>
								<p className={styles.sales__cancel}>{formattedPrice}</p>
								<p>{formattedPromoPrice}</p>
							</div>
						) : (
							<MimaText>{formattedPrice ? formattedPrice : ''}</MimaText>
						)}
					</>
				);
			},
		},
	];

	const title = useMemo(() => {
		if (collectionQuery !== '' && collectionQuery !== 'All') {
			return collectionQuery;
		}

		return 'Shop';
	}, [collectionQuery]);

	return (
		<>
			<section className={styles.catalog}>
				<CatalogHomeHeader
					filtering={filtering}
					setFiltering={setFiltering}
					setCollectionQuery={setCollectionQuery}
					data={data}
					setSearchQuery={setSearchQuery}
					setCurrencyCode={setCurrencyCode}
					showSearch={showSearch}
					setShowSearch={setShowSearch}
				/>

				<div className={styles.catalog__body} style={{ marginTop: '12rem' }}>
					<div className={styles.catalog__body__inner}>
						<h3 className={styles.shopTitle}>{title}</h3>

						<MimaGridView
							tableData={tableData}
							tableColumns={tableColumns}
							searchPlaceholder="Search Styles"
							searchVariant="wide"
							totalItems={data?.data?.totalCounts}
							onLimitChange={onLimitChange}
							isLoading={isLoading}
							isFetching={isFetching}
							limit={limit}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							setSearchQuery={setSearchQuery}
							emptyMessage="No Styles found or match search"
							tableClick={handleTableClick}
							filtering={filtering}
							setFiltering={setFiltering}
						/>
					</div>
				</div>
				<Footer data={data} />
			</section>
		</>
	);
};

export default CatalogShop;
