import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultProps = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const MimaToastUtil = {
  error: ({ message = "", props }) => {
    toast.error(message, props || defaultProps);
  },
  success: ({ message = "", props }) => {
    toast.success(message, props || defaultProps);
  },
  info: ({ message = "", props }) => {
    toast.info(message, props || defaultProps);
  },
};
