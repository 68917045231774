// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_icon__cla1w {
  font-size: 3rem;
}
.IconButton_icon__cla1w > * {
  margin-right: 2rem;
  cursor: pointer;
}
.IconButton_icon__cla1w > *:hover {
  color: var(--color-primary);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Components/IconButton.module.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;AACD;AACC;EACC,kBAAA;EACA,eAAA;AACF;AACE;EACC,2BAAA;AACH","sourcesContent":[".icon {\n\tfont-size: 3rem;\n\n\t& > * {\n\t\tmargin-right: 2rem;\n\t\tcursor: pointer;\n\n\t\t&:hover {\n\t\t\tcolor: var(--color-primary);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `IconButton_icon__cla1w`
};
export default ___CSS_LOADER_EXPORT___;
