import React, { useMemo, useState } from 'react';
import { catalogStore } from '../../stores';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import { motion } from 'framer-motion';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import constant from '../../utils/constant';
import { useNavigate } from 'react-router-dom';

const CollectionInHeaderMenu = ({ setCollectionQuery, webSettings }) => {
	const [navMore, setNavMore] = useState(false);
	const [collectionIndex, selectedCollectionIndex] = useState(0);
	const [collectionIndex2, selectedCollectionIndex2] = useState(-1);

	const { collections } = catalogStore;

	const navigate = useNavigate();

	const usedCollection = useMemo(() => {
		const menu = [
			{
				name: 'All',
				key: 'All',
				actionLink: constant.Routes.Home,
			},
		];

		if (webSettings?.content?.sizeGuides?.length > 0) {
			menu.push({
				name: 'size guide',
				key: 'size guide',
				actionLink: constant.Routes.SizeGuide,
			});
		}

		const updateCollections = collections.map(obj => ({
			...obj,
			actionLink: constant.Routes.WebsiteShop,
		}));

		menu.push(...updateCollections);

		return menu;
	}, [collections, webSettings]);

	let deviceSize = window.innerWidth;

	const stop = useMemo(() => {
		if (deviceSize > 600 && deviceSize <= 800) {
			return 3;
		} else if (deviceSize > 500 && deviceSize <= 600) {
			return 2;
		} else if (deviceSize <= 500) {
			return 1;
		} else {
			return 4;
		}
	}, [deviceSize]);

	const collectionMainNav = usedCollection.slice(0, stop);
	const collectionOtherNav = usedCollection.slice(stop);

	const headerMenuHandler = (action, collection) => {
		if (collection) {
			return navigate(`${action}?collection=${collection}`);
		}
		return navigate(action);
	};

	return (
		<>
			<nav className={styles.catalog__header__nav}>
				<ul>
					{collectionMainNav?.map((c, i) => (
						<li
							key={i}
							onClick={() => {
								setCollectionQuery(c?.key);
								selectedCollectionIndex(i);
								setNavMore(false);
								if (c?.key === 'All') {
									return headerMenuHandler(c?.actionLink);
								}
								return headerMenuHandler(c?.actionLink, c?.key);
							}}
							className={
								collectionIndex === i
									? styles.catalog__header__nav__selected
									: ''
							}>
							{c?.name?.toUpperCase()}
						</li>
					))}
					{usedCollection.length > stop ? (
						<div className={styles.more__rel}>
							<li
								onClick={() => {
									selectedCollectionIndex(100);
									selectedCollectionIndex2(-1);
									setNavMore(!navMore);
								}}
								onMouseEnter={() => {
									selectedCollectionIndex2(-1);
									setNavMore(true);
								}}
								className={
									collectionIndex === 100
										? styles.catalog__header__nav__selected
										: ''
								}
								style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
								Discover More{' '}
								<MdOutlineKeyboardArrowDown style={{ fontSize: '2rem' }} />
							</li>

							{navMore ? (
								<motion.nav
									initial={{
										y: -10,
									}}
									animate={{
										y: 0,
										transition: {
											duration: 0.2,
										},
									}}
									exit={{
										y: -10,
										transition: {
											duration: 0.2,
										},
									}}
									className={styles.more}
									onMouseLeave={() => {
										setNavMore(false);
									}}>
									<ul>
										{collectionOtherNav?.map((c, i) => (
											<li
												key={i}
												onClick={() => {
													setCollectionQuery(c?.key);
													selectedCollectionIndex2(i);
													setNavMore(false);
													headerMenuHandler(c?.actionLink, c?.key);
												}}
												className={
													collectionIndex2 === i
														? styles.catalog__header__nav__selected
														: ''
												}>
												{c?.name?.toUpperCase()}
											</li>
										))}
									</ul>
								</motion.nav>
							) : (
								''
							)}
						</div>
					) : (
						''
					)}
				</ul>
			</nav>
			{navMore ? (
				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
						transition: {
							duration: 0.2,
						},
					}}
					exit={{
						opacity: 0,
						transition: {
							duration: 0.2,
						},
					}}
					className={styles.catalog__modal}
					onMouseEnter={() => {
						setNavMore(true);
					}}>
					<motion.div
						initial={{
							y: -100,
						}}
						animate={{
							y: 0,
							transition: {
								duration: 0.2,
							},
						}}
						exit={{
							y: -100,
							transition: {
								duration: 0.2,
							},
						}}
						className={styles.catalog__modal__nav}
						onMouseLeave={() => {
							setNavMore(false);
						}}></motion.div>
					<div
						onClick={() => {
							setNavMore(false);
						}}></div>
				</motion.div>
			) : (
				''
			)}
		</>
	);
};

export default CollectionInHeaderMenu;
