import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { catalogStore } from '../../stores';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import { flagData } from '../../utils/utils';
import { observer } from 'mobx-react';

const CatalogCountryCurrency = ({ setCurrencyCode, moreActions }) => {
	const isBelow600 = useMediaQuery({
		query: '(max-width: 600px)',
	});

	const { locationInfo, setLocationInfo, primaryLocation } = catalogStore;

	const [showList, setShowList] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState(locationInfo);

	const moreActionsHandler = curr => {
		if (moreActions) {
			moreActions(curr);
		}
	};

	return (
		<div className={styles.country}>
			<div
				className={styles.country__sel}
				onClick={() => {
					if (primaryLocation === 'NG') {
						setShowList(!showList);
					}
				}}>
				<img src={selectedCountry.flag} alt="flag" />
				{!isBelow600 ? <p>{selectedCountry.country}</p> : ''}
			</div>
			{showList ? (
				<div className={styles.country__drop}>
					{flagData.map((flag, i) => (
						<div
							key={i}
							onClick={() => {
								setSelectedCountry(flag);
								setShowList(!showList);
								setLocationInfo(flag);
								setCurrencyCode(flag.currencyCode);
								moreActionsHandler(flag.currencyCode);
							}}
							className={styles.country__drop__item}>
							<img src={flag.flag} alt="flag" />
							<p>{flag.country}</p>
						</div>
					))}
				</div>
			) : (
				''
			)}
		</div>
	);
};

export default observer(CatalogCountryCurrency);
