import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { openRoutes } from './router';
import './assets/styles/Global/GlobalStyles.scss';
import { ToastContainer } from 'react-toastify';
import { CacheCleanup, EditMode, PageLoader, ScrollToTop } from './components';
import { observer } from 'mobx-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useTheme } from './context/ThemeContext';
import { catalogStore } from './stores';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			networkMode: 'offlineFirst',
			refetchOnWindowFocus: false,
		},
		mutations: {
			networkMode: 'online',
		},
	},
});

function App() {
	const { theme } = useTheme();
	const [ThemeComponents, setThemeComponents] = useState({});
	const { editMode, setConfigThemeData, configThemeData, themeData } =
		catalogStore;

	const usedThemeData = useMemo(() => {
		if (themeData?.pages?.length > 0) {
			return themeData;
		}

		return configThemeData;
	}, [configThemeData, themeData]);

	const color = useMemo(() => {
		return usedThemeData?.generalSettings?.data.find(
			color => color.id === 'color'
		);
	}, [usedThemeData?.generalSettings?.data]);

	useEffect(() => {
		const loadTheme = async () => {
			const themeConfig = await import(`./themes/${theme}/theme.config.js`);
			const components = themeConfig.default.components;
			const colors = themeConfig?.default?.colors;

			if (color?.defaultContent?.primary) {
				document.documentElement.style.setProperty(
					'--theme-primary-color',
					color?.defaultContent?.primary
				);
			} else if (colors?.primary) {
				document.documentElement.style.setProperty(
					'--theme-primary-color',
					colors?.primary
				);
			}

			if (color?.defaultContent?.secondary) {
				document.documentElement.style.setProperty(
					'--theme-secondary-color',
					color?.defaultContent?.secondary
				);
			} else if (colors?.secondary) {
				document.documentElement.style.setProperty(
					'--theme-secondary-color',
					colors?.secondary
				);
			}
			setThemeComponents(components);
		};
		loadTheme();
	}, [
		color?.defaultContent?.primary,
		color?.defaultContent?.secondary,
		setConfigThemeData,
		theme,
	]);

	return (
		<QueryClientProvider client={queryClient}>
			<CacheCleanup />
			<BrowserRouter>
				<Suspense fallback={<PageLoader />}>
					<ScrollToTop />
					{editMode ? <EditMode /> : ''}
					<Routes>
						{openRoutes.map(({ path, component: ComponentName, element }) => {
							if (element) {
								return <Route key={path} path={path} element={element} />;
							} else {
								const Component = ThemeComponents[ComponentName];

								return (
									<Route
										key={path}
										path={path}
										element={Component ? <Component /> : <PageLoader />}
									/>
								);
							}
						})}
						{/* {openRoutes.map(route => (
							<Route
								key={route.path}
								path={route.path}
								element={route.element}
							/>
						))} */}
						{/* {isLoggedIn ? (
						protectedRoutes.map(route => (
							<Route
								key={route.path}
								path={route.path}
								element={route.element}
							/>
						))
					) : (
						<Route
							path="*"
							element={<Navigate to={constant.Routes.PartnerLogin} replace />}
						/>
					)} */}
					</Routes>
					<ToastContainer closeButton={false} position="bottom-right" />
				</Suspense>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default observer(App);
