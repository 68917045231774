// import SliderImg1 from '../assets/img/slide-img-1.png';
//import SliderImg2 from '../assets/img/slide-img-4.png';
import SliderImg3 from '../assets/img/slide-img-5.png';
//import SliderImg4 from '../assets/img/slide-img-6.png';
import SliderImg5 from '../assets/img/slide-img-7.png';
import SliderImg6 from '../assets/img/slide-img-8.png';

export const slides = [
	// {
	//   imgUrl: SliderImg1,
	//   testimony: "I started using Mima to manage my business 3 months ago, the payroll system has made it easier for me to pay my workers and monitor what costs actually take more of my business capital.",
	//   name: "Chika Uluocha",
	//   business: "Rovers Consultancy Limited",
	//   title: "My Payroll made easier"
	// },
	// {
	//   imgUrl: SliderImg2,
	//   testimony: "MIMA is the solution for a smooth and seamless business operation! MIMA has helped us build discipline around our business finances. And also, helped in improving the finance management of our brand. It easily helps to keep track of the cash flow in the business. Thank you MIMA ❤️",
	//   name: "Oke Grace",
	//   business: "CEO, Gomabeads"
	// },
	// {
	//   imgUrl: SliderImg4,
	//   testimony: "As a food vendor  using Mima to manage my buisness for 3months now, has been so effortlessly easy and stressfree.  it has made sending of invoice and reciept more easy and straight forward . I have also been able to track every penny i spend and monitor my buisness transactions and also my profit and loss.",
	//   name: "Iyabo Owolabi",
	//   business: "CEO Duna's Food"
	// },
	{
		imgUrl: SliderImg5,
		testimony:
			'As a premium fash creatives user, I particularly love the fact that I am able to delegate certain parts Critical operations such as Financial Control and Customer Service to my staff with the privilege of Limiting access to these rights.',
		name: 'Zainab Oduniyi',
		business: 'Toshowoods',
		title: 'Simple for operations',
	},
	{
		imgUrl: SliderImg3,
		testimony:
			'Using fash creatives has been one of the best things I did for my business this year. The business account, invoice and receipt feature is my favorite because it makes my brand more professional and serious. I can even do a money transfer through the app…..mind blowing!!',
		name: 'Ohen Michael',
		business: 'CEO St. Michael beauty',
		title: 'Simplified Business Accounting',
	},

	{
		imgUrl: SliderImg6,
		testimony:
			"I recently downloaded the Fash creatives app and it's exceeding my expectations. It's swift, easy to use, and I love the feature that allows me to create styles and share my unique web link for clients to request styles. It's a game-changer for managing clients and sales. Solid 8/10!",
		name: 'Olaitan',
		business: 'CEO of Phase Designs',
		title: 'Easy to Use',
	},
];
