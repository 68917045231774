import { css } from 'styled-components';

export const base = css`
	padding: 1.9rem 2.2rem;
	heigth: 6rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;
	height: 6rem;
	background-color: var(--color-primary-7);
	border-radius: 1rem;
	cursor: pointer;
	& > :last-child {
		font-size: 3rem;
	}
	&:hover {
		border: 1px solid var(--color-primary);
	}
`;

export const main = css`
	width: 38rem;
	background-color: var(--color-primary-7);
`;

export const other = css`
	width: 42rem;
	background-color: var(--color-primary-3);
`;
