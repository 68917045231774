import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class PaymentLinkStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'PaymentLinkStore',
			properties: [
				'allPaymentLinks',
				'selectedPaymentLink',
				'paymentCheckoutDetails',
				'charges',
			],
		});
	}

	allPaymentLinks = {};
	selectedPaymentLink = [];
	paymentCheckoutDetails = {};
	charges={};

	reset = () => {
		this.allPaymentLinks = {};
		this.selectedPaymentLink = [];
		this.paymentCheckoutDetails = {};
		this.charges={};
	};


	setAllPaymentLinks = async state => {
		runInAction(async () => {
			this.allPaymentLinks = state;
		});
	};

	setSelectedPaymentLink = async state => {
		runInAction(async () => {
			this.selectedPaymentLink = state;
		});
	};

	setPaymentCheckoutDetails = async state => {
		runInAction(async () => {
			this.paymentCheckoutDetails = state;
		});
	};

	setCharges = async state => {
		runInAction(async () => {
			this.charges = state;
		});
	};
}
