import React from 'react';
import MimaInput, { MimaTextArea } from '../MimaInput';
import MimaDropZone from '../MimaDropZone';
import { MimaDropdown } from '../Dropdown';
import MimaQuillInput from '../MimaQuillInput';
import { convertToTitleCase } from '../../utils/utils';
import { FieldArray } from 'formik';
import styles from '../../assets/styles/EditMode/EditMode.module.scss';
import MimaText from '../MimaText';
import { MdClose } from 'react-icons/md';
import { ColorPicker } from '../ColorPicker';
import { catalogStore } from '../../stores';

const labelMapping = {
	title: 'Title',
	subtitle: 'Subtitle',
	text: 'Text',
	imageUrl: 'Image',
	imageUrls: 'Images',
	buttonTitle: 'Button Text',
	content: 'Content Description',
	// Add more mappings as needed
};

const FieldRenderer = ({
	field,
	value,
	validation,
	onChange,
	onBlur,
	error,
	touched,
	name,
	setFieldValue,
	arrayTitle,
}) => {
	if (!validation) return null;

	const labelTitle = labelMapping[field] || convertToTitleCase(field) || field;
	const maxNumOfImages = validation?.maxItems || 1;
	const { collections } = catalogStore;
	const selectData = item => {
		if (item === 'collections') {
			return collections.map(value => ({
				key: value.key,
				value: value.name,
			}));
		}

		return item.map(value => ({
			key: value,
			value: value,
		}));
	};

	switch (validation.fieldType) {
		case 'input':
			return (
				<MimaInput
					labelTitle={labelTitle}
					type="text"
					placeholder={`Enter ${labelTitle}`}
					id={name}
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					error={error}
					touched={touched}
					variant="form"
				/>
			);
		case 'image':
			return (
				<MimaDropZone
					title={`Upload ${labelTitle}`}
					info="maximum image Size 1MB"
					maxNumOfImages={maxNumOfImages}
					files={value}
					setFiles={newFiles => {
						setFieldValue(name, newFiles);
					}}
					labelTitle={labelTitle}
					name={name}
				/>
			);
		case 'textarea':
			return (
				<MimaTextArea
					labelTitle={labelTitle}
					textAreaVariant="type3"
					placeholder={`Enter ${labelTitle}`}
					name={name}
					id={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					touched={touched}
					error={error}
				/>
			);
		case 'quill':
			return (
				<MimaQuillInput
					name={name}
					labelTitle={labelTitle}
					value={value}
					onChange={content => setFieldValue(name, content)}
					error={error}
					touched={touched}
					placeholder={`Enter ${labelTitle}`}
				/>
			);
		case 'select':
			return (
				<MimaDropdown
					labelTitle={labelTitle}
					name={name}
					value={value}
					placeholder={`Select ${labelTitle}`}
					id={name}
					currentValue={value}
					onChange={data => {
						setFieldValue(name, data.key);
					}}
					data={selectData(validation?.options)}
					touched={touched}
					error={error}
					variant="form"
					bodyVariant="bodyBase3"
				/>
			);
		case 'colorPicker':
			return (
				<ColorPicker
					labelTitle={labelTitle}
					name={name}
					value={value}
					currentValue={value}
					onChange={color => setFieldValue(name, color)}
					touched={touched}
					error={error}
				/>
			);
		case 'array':
			return (
				<FieldArray
					name={name}
					render={arrayHelpers => (
						<div className={styles.section__array__container}>
							{value && value.length > 0
								? value.map((item, index) => (
										<div key={index} className={styles.section__array}>
											{/* Render FieldRenderer for each item */}
											<div className={styles.section__array__sb}>
												<MimaText variant="bodyBold">
													{arrayTitle} {index + 1}
												</MimaText>
												{value.length > validation.minItems && (
													<button
														type="button"
														onClick={() => arrayHelpers.remove(index)}
														className={styles.section__array__remove}>
														<span>Remove</span>
														<MdClose />
													</button>
												)}
											</div>
											{validation.items &&
												Object.keys(validation.items).map(key => (
													<FieldRenderer
														key={key}
														field={key}
														value={item[key]}
														validation={validation.items[key]}
														onChange={e =>
															arrayHelpers.replace(index, {
																...item,
																[key]: e.target.value,
															})
														}
														onBlur={() => {}}
														error={error}
														touched={touched}
														name={`${name}[${index}].${key}`}
														setFieldValue={setFieldValue}
													/>
												))}
										</div>
									))
								: ''}
							{/* Add button */}
							{value.length <
								(validation.maxItems === 'collections'
									? collections.length
									: validation.maxItems) && (
								<button
									type="button"
									onClick={() =>
										arrayHelpers.push(
											validation.items
												? Object.keys(validation.items).reduce(
														(acc, key) => ({ ...acc, [key]: '' }),
														{}
													)
												: {}
										)
									}
									disabled={
										value.length >=
										(validation.maxItems === 'collections'
											? collections.length
											: validation.maxItems)
									}
									className={styles.section__array__add}>
									Add Item
								</button>
							)}
						</div>
					)}
				/>
			);
		default:
			return null;
	}
};

export default FieldRenderer;
