import { useCallback, useEffect, useMemo, useState } from 'react';
import { catalogStore } from '../stores';
import { encodeQueryData } from '../utils/utils';
import { StyleCatalogController } from '../controllers';
import constant from '../utils/constant';
import useEditMode from '../components/EditMode/useEditMode';

const useUserTheme = () => {
	const hostname = window.location.hostname;
	const fashSubdomain = hostname.split('.')[0];
	const domainParts = hostname.split('.');
	const customDomain = domainParts.length > 2 ? domainParts[1] : domainParts[0];
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	const subdomain = useMemo(() => {
		if (isCustomDomain) {
			return customDomain;
		}

		return fashSubdomain;
	}, [isCustomDomain, customDomain, fashSubdomain]);

	const {
		locationInfo,
		userTheme,
		setUserTheme,
		setMetaDetails,
		setWebConfig,
		setThemeData,
		setThemeId,
		setConfigThemeData,
	} = catalogStore;

	const query = useMemo(() => {
		return {
			limit: 2,
			storeFrontName: subdomain,
			offset: 1,
			currencyCode: locationInfo?.currencyCode !== 'NGN' ? 'USD' : 'NGN',
		};
	}, [subdomain, locationInfo]);

	const encodeQuery = encodeQueryData({
		...query,
	});

	const [loading, setLoading] = useState(true);

	const { keyValueArrayToContent, updateContentWithValidation, removeIdKeys } =
		useEditMode();

	const setUserThemeData = async (data, theme) => {
		const themeConfig = await import(`../themes/${theme}/theme.config.js`);
		const editablePages = themeConfig?.default?.editablePages;
		const generalSettings = themeConfig?.default?.generalSettings;

		const configThemeData = {
			pages: editablePages,
			generalSettings: generalSettings,
		};

		if (generalSettings?.themeName) {
			setConfigThemeData(configThemeData);
		}

		if (data?.websiteSettings?.themeData?._id) {
			const returnedThemeData = { ...data?.websiteSettings?.themeData };
			setThemeId(returnedThemeData?._id);
			const postPayload = {
				generalSettings: {
					themeName: returnedThemeData?.config?.themeName,
					data: returnedThemeData?.config?.data,
				},
				pages: [...returnedThemeData?.pages],
			};

			const reshapedThemeData = keyValueArrayToContent(postPayload);
			const idRemovedThemeData = removeIdKeys(reshapedThemeData);

			const themeDataWithValidation = updateContentWithValidation(
				idRemovedThemeData,
				configThemeData
			);
			setThemeData(themeDataWithValidation);
		}
	};

	const [theme, setTheme] = useState('');

	const getUserTheme = async () => {
		setLoading(true);
		const { status, data, errorMessage } =
			await StyleCatalogController.getUserTheme(encodeQuery);
		if (status === constant.Success) {
			const theme = data?.websiteSettings?.content?.theme;

			let themeKey;
			switch (theme) {
				case 'UPGRADE':
					themeKey = 'hookedByLade';
					break;
				case 'LUXEFLEX':
					themeKey = 'luxeFlex';
					break;
				case 'DEFAULT':
					themeKey = 'default';
					break;
				default:
					themeKey = 'default';
			}

			const userThemeInfo = {
				theme: themeKey,
				createdAt: new Date().getTime().toString(),
				expirationTime: 60 * 60 * 1000,
				backendThemeName: data?.websiteSettings?.content?.theme,
			};

			const metaDetails = {
				businessName: data?.websiteSettings?.business?.tradingName,
				favicon: data?.websiteSettings?.business?.logoUrl,
				localCurrency: data?.websiteSettings?.business?.localCurrency,
				aboutDescription: data?.websiteSettings?.content?.aboutUs?.text,
				tawk: data?.websiteSettings.tawk,
			};

			setTheme(themeKey);

			await setUserThemeData(data, themeKey);

			setMetaDetails(metaDetails);
			setUserTheme(userThemeInfo);
			setWebConfig(data?.websiteSettings?.content);
			setLoading(false);
			return;
		}
		setLoading(false);
		return console.error(errorMessage);
	};

	const themeCheck = useCallback(() => {
		const now = new Date().getTime();

		if (
			userTheme?.theme &&
			userTheme?.createdAt &&
			now - userTheme?.createdAt < userTheme?.expirationTime
		) {
			return setTheme(userTheme?.theme);
		}

		if (theme) {
			return;
		}

		return getUserTheme();
	}, [theme, userTheme?.createdAt, userTheme?.theme]);

	useEffect(() => {
		themeCheck();
	}, [themeCheck]);

	return {
		theme,
		setTheme,
		loading,
		setUserThemeData,
	};
};

export default useUserTheme;
