import { BsArrowRightCircle } from 'react-icons/bs';
import styles from '../../assets/styles/LandingPage/Features.module.scss';
import React from 'react';
import imageList from '../../assets/img/ImgList';
import { DownloadAppBtns } from './Header';

const Features = () => {
	const { createStyleImg } = imageList;
	return (
		<section className={styles.section2}>
			<div>
				<h1>
					Express Your <br />
					<span>Signature Style</span>
				</h1>
				<p className={styles.section2__p1}>
					Showcase and sell your stunning designs to your clients easily
				</p>
			</div>
			<div className={styles.section2__card}>
				<div className={styles.section2__card__1}>
					<h2>Create Online Style Catalogs</h2>
					<p>
						Add your styles and create a unique catalog link to showcase your
						awesome fashion designs for customers to pick from.{' '}
					</p>
					<div className={styles.getStarted}>
						<p>Get Started</p>
						<span>
							<BsArrowRightCircle />
						</span>
					</div>
				</div>
				<div className={styles.section2__card__2}>
					<img src={createStyleImg} alt="create style" />
				</div>
			</div>
			<DownloadAppBtns />
		</section>
	);
};

export default Features;
