import { BsArrowRightCircle } from 'react-icons/bs';
import styles from '../../assets/styles/LandingPage/Features.module.scss';
import React from 'react';
import imageList from '../../assets/img/ImgList';
import { DownloadAppBtns } from './Header';
import useEmblaCarousel from 'embla-carousel-react';
import {
	NextButton,
	PrevButton,
	usePrevNextButtons,
} from './EmblaArrowControl';

const Features2 = () => {
	const { createStyleImg } = imageList;

	const slideDetails = [
		{
			title: 'SELL LOCALLY AND GLOBALLY',
			subtext:
				'Add Your Style: Build a Fashion E-commerce Site for Free, Serving Both International and Local Markets. Accept Orders from Around the Globe and Across Nigeria.',
		},
		{
			title: 'MANAGE MEASUREMENTS',
			subtext:
				'Never worry about misplacing your measurement book again. Safely store your customer measurements in the cloud for convenient access anytime, anywhere. ',
		},
		{
			title: 'ENHANCE ORDER MANAGEMENT',
			subtext:
				'Eliminate customer frustration caused by delayed orders. Receive timely order reminders and stay informed about outstanding customer balances.',
		},
		{
			title: 'EQUIPEMENT FINANCING',
			subtext:
				'Ready to take your fashion business to the next level? Gain access to asset financing specifically designed for acquiring essential machinery and tools to support growth and innovation.',
		},
	];
	const options = { loop: true };
	const slideCount = 4;
	const slides = Array.from(Array(slideCount).keys());

	const [emblaRef, emblaApi] = useEmblaCarousel(options);
	const {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick,
	} = usePrevNextButtons(emblaApi);

	return (
		<section className={styles.section3} id="Products">
			<div className={styles.section3__head}>
				<h3>More than just creating style catalogs</h3>
				<div className={styles.embla__btns}>
					<PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
					<NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
				</div>
			</div>
			<div className={styles.embla}>
				<div className={styles.embla__viewport} ref={emblaRef}>
					<div className={styles.section3__slide__container}>
						{slideDetails.map((details, index) => (
							<div
								className={`${styles.section3__slide} ${styles[`section3__slide__${index}`]} `}
								key={index}>
								<h4>{details.title}</h4>
								<h5>{details.subtext}</h5>
							</div>
						))}
					</div>
				</div>
			</div>
			<DownloadAppBtns />
			<div className={styles.section3__bottom}>
				<div className={styles.section3__bottom__1}></div>
				<div className={styles.section3__bottom__2}>
					<h1>Sell More Fashion.</h1>
					<p className={styles.section3__bottom__p}>
						Sell to the diaspora and receive payments in{' '}
						<span>USD, GBP, EUR</span>
					</p>
					<div className={styles.section3__bottom__getStarted}>
						<p>Get Started</p>
						<span>
							<BsArrowRightCircle />
						</span>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Features2;
