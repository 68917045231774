import { useClearCache } from 'react-clear-cache';
import styled from 'styled-components';
import * as React from 'react';

const ClearCacheWrapper = styled.div`
	position: fixed;
	background: var(--color-white);
	padding: 10px;
	left: 0;
	margin: auto;
	bottom: 0;
	border-radius: 4px;
	border-top: 10px solid var(--color-fash-primary-2);
	z-index: 10000000000000000;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	font-size: 1.4rem;
	&.open {
		animation: openPopup 2s ease-out;
	}
	@keyframes openPopup {
		0% {
			transform: translateY(50px);
			opacity: 0;
		}
		60% {
			transform: translateY(50px);
			opacity: 0;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}
`;

const Span = styled.div`
	background-color: rgba(0, 0, 0, 0.75);
	color: var(--color-white);
	padding: 1.2rem;
	border-radius: 1rem;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 0, 0, 1);
		font-weight: 700;
	}
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
`;

const CacheCleanup = () => {
	const hours = 12;
	const milliseconds = hours * 60 * 60 * 1000;
	const cacheInfo = useClearCache({ duration: milliseconds });
	const { isLatestVersion } = cacheInfo;
	const onClick = e => {
		e.preventDefault();
		cacheInfo.emptyCacheStorage();
		window.location.reload();
	};

	return (
		<>
			{!isLatestVersion && (
				<ClearCacheWrapper className={!isLatestVersion ? 'open' : ''}>
					<Flex>
						To Update to the latest Version{' '}
						<Span style={{ marginLeft: '1rem' }} onClick={onClick}>
							Click Here
						</Span>
					</Flex>
				</ClearCacheWrapper>
			)}
		</>
	);
};

export default CacheCleanup;
