import styles from '../../assets/styles/MimaText.module.scss';
import styled from 'styled-components';
import * as textStyles from './styles';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	children: PropTypes.any.isRequired,
	color: PropTypes.string,
	variant: PropTypes.oneOf([
		'h1',
		'h2',
		'h3',
		'subtitle',
		'subtitleBold',
		'body',
		'body2',
		'bodyBold',
		'small',
		'smallBold',
		'xsmall',
		'footer',
	]),
	className: PropTypes.any,
	mt: PropTypes.number,
	mr: PropTypes.number,
	mb: PropTypes.number,
	ml: PropTypes.number,
	width: PropTypes.number,
	fontStyle: PropTypes.string,
	style: PropTypes.object,
	align: PropTypes.string,
	labelTitle: PropTypes.string,
};

const defaultProps = {
	variant: 'body',
	color: 'var(--color-dark)',
	labelTitle: '',
};

const MimaText = ({ children, labelTitle, ...props }) => {
	return (
		<DivSkeleton {...props}>
			{labelTitle !== '' ? (
				<label className={styles.label}>{labelTitle}</label>
			) : null}
			<TextSkeleton {...props}>{children} </TextSkeleton>
		</DivSkeleton>
	);
};

const TextSkeleton = styled.div`
	${textStyles.base};
	${props => textStyles[props.variant]};

	${props => (props.fontStyle ? `font-style: ${props.fontStyle}` : '')};
	${props => (props.color ? `color: ${props.color}` : '')};
	${props => (props.align ? `text-align: ${props.align}` : '')};
`;

const DivSkeleton = styled.div`
	${props => (props.mt ? `margin-top: ${props.mt}rem` : '')};
	${props => (props.mr ? `margin-right: ${props.mr}rem` : '')};
	${props => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
	${props => (props.ml ? `margin-left: ${props.ml}rem` : '')};
	${props => (props.width ? `width: ${props.width}rem` : '')};
`;

MimaText.propTypes = propTypes;

MimaText.defaultProps = defaultProps;

export default MimaText;
