import React, { useMemo, useState } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { IoCaretForward, IoCaretBack } from 'react-icons/io5';
import { AiFillBackward, AiFillForward } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import styles from '../../assets/styles/MimaTable.module.scss';
import PageLoader from '../PageLoader';
import {
	useReactTable,
	getCoreRowModel,
	flexRender,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
} from '@tanstack/react-table';
import { MimaText } from '..';

// type PageButton = number | '...';

const defaultTableData = [
	{
		id: 1,
		first_name: 'Millicent',
		last_name: 'Whatham',
		email: 'mwhatham0@comsenz.com',
		gender: 'Female',
		university: 'Samarkand State University',
	},
	{
		id: 2,
		first_name: 'Siward',
		last_name: 'Amberger',
		email: 'samberger1@behance.net',
		gender: 'Male',
		university: 'Institute of Industrial Electronics Engineering',
	},
	{
		id: 3,
		first_name: 'Sheree',
		last_name: 'Madeley',
		email: 'smadeley2@google.com',
		gender: 'Female',
		university: 'Kateb Institute of Higher Education',
	},
];

const defaultTableColumns = [
	{
		header: 'ID',
		accessorKey: 'id',
	},
	{
		header: 'First Name',
		accessorKey: 'first_name',
	},
	{
		header: 'Last Name',
		accessorKey: 'last_name',
	},
	{
		header: 'Email',
		accessorKey: 'email',
	},
	{
		header: 'Gender',
		accessorKey: 'gender',
	},
	{
		header: 'University',
		accessorKey: 'university',
	},
];

const MimaGridView = ({
	tableData = defaultTableData,
	tableColumns = defaultTableColumns,
	searchPlaceholder = 'Search...',
	searchVariant = 'wide',
	totalItems,
	onLimitChange = limit => {},
	isLoading = false,
	isFetching = false,
	limit = 50,
	currentPage = 0,
	setCurrentPage,
	setSearchQuery,
	emptyMessage = 'No data available or matches Search',
	tableClick,
	filtering,
	setFiltering,
	...props
}) => {
	const data = useMemo(() => tableData, [tableData]);
	const columns = useMemo(() => tableColumns, [tableColumns]);
	const usedLimit = useMemo(() => limit, [limit]);

	// const [currentPage, setCurrentPage] = useState(0);
	const [sorting, setSorting] = useState([]);
	// const [filtering, setFiltering] = useState('');

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),

		onSortingChange: setSorting,
		onGlobalFilterChange: setFiltering,

		state: {
			sorting,
			globalFilter: filtering,
			pagination: {
				pageSize: usedLimit,
				pageIndex: 0,
			},
		},
	});

	const maxDisplayedPages = 3; // Maximum number of page buttons to display

	const pageSize = table.options.state.pagination?.pageSize || 10;
	const pageCount = table.getPageCount();

	const totalPages = totalItems ? Math.ceil(totalItems / pageSize) : pageCount;

	const pageButtons = useMemo(() => {
		let pageButtons = [];
		if (totalPages <= maxDisplayedPages) {
			pageButtons = Array.from({ length: totalPages }, (_, index) => index);
			return pageButtons;
		} else {
			const startPage = Math.max(
				0,
				currentPage - Math.floor(maxDisplayedPages / 2)
			);
			const endPage = Math.min(
				totalPages - 1,
				startPage + maxDisplayedPages - 1
			);

			if (startPage > 0) {
				pageButtons.push('...');
			}

			pageButtons = pageButtons.concat(
				Array.from(
					{ length: endPage - startPage + 1 },
					(_, index) => startPage + index
				)
			);

			if (endPage < totalPages - 1) {
				pageButtons.push('...');
			}
			return pageButtons;
		}
	}, [totalPages, maxDisplayedPages, currentPage]);

	const sortIcon = header => {
		const isSorted = header.column.getIsSorted();
		return (
			<span>
				{isSorted === 'asc' ? (
					<FaArrowUp />
				) : isSorted === 'desc' ? (
					<FaArrowDown />
				) : null}{' '}
			</span>
		);
	};

	const handleRowClick = row => {
		if (tableClick) {
			tableClick(row.original);
		}
	};

	return (
		<div>
			<div className="searchInMiddle">
				{/* <GridSearch
					filter={filtering}
					setFilter={setFiltering}
					placeholder={searchPlaceholder}
					variant={searchVariant}
					setSearchQuery={setSearchQuery}
				/> */}
			</div>

			<div className="gridTable">
				{isLoading ? (
					<PageLoader loading={isLoading} title="while we fetch your data" />
				) : (
					<table>
						<tbody>
							{table.getRowModel().rows.map(row => (
								<tr
									key={row.id}
									onClick={() => {
										handleRowClick(row);
									}}>
									{row.getVisibleCells().map(cell => (
										<td key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				)}

				{tableData?.length <= 0 ? (
					<div className="flex flex-col w-full items-center mt-8">
						<img
							src="https://res.cloudinary.com/mima-business/image/upload/v1702949369/mSell/products_empty_g7hnj3.png"
							alt="No products"
							style={{ width: '25rem', height: 'auto' }}
						/>
						<MimaText variant="subtitleBold" mt={2} align="center">
							{emptyMessage}
						</MimaText>
					</div>
				) : (
					''
				)}
			</div>

			{isFetching && <PageLoader loading={isFetching} type="bar" />}

			<div className={styles.pagination__group}>
				{/* <div className={styles.pagination}>
					<select
						value={table.options.state.pagination?.pageSize}
						onChange={e => {
							table.setPageSize(Number(e.target.value));
							onLimitChange(Number(e.target.value));
						}}>
						{[50, 100, 200].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</select>
					<strong>
						{currentPage + 1} of{' '}
						{totalItems ? totalPages : table.getPageCount()}
					</strong>
				</div> */}
				<div className={styles.pagination}>
					<button
						onClick={() => {
							setCurrentPage(0);
						}}
						disabled={currentPage === 0}
						className={styles.numberedPage}>
						<AiFillBackward style={{ fontSize: '2rem' }} />
					</button>
					<button
						onClick={() => {
							setCurrentPage(currentPage - 1);
						}}
						disabled={currentPage === 0}
						className={styles.numberedPage}>
						<IoCaretBack style={{ fontSize: '2rem' }} />
					</button>
					{pageButtons.map((page, index) =>
						page === '...' ? (
							<BsThreeDots key={index} style={{ fontSize: '2rem' }} />
						) : (
							<button
								key={index}
								onClick={() => {
									setCurrentPage(page);
								}}
								className={`${styles.numberedPage} ${currentPage === page ? styles.numberPageActive : ''}`}>
								{page + 1}
							</button>
						)
					)}

					<button
						onClick={() => {
							setCurrentPage(currentPage + 1);
						}}
						disabled={currentPage === totalPages - 1}
						className={styles.numberedPage}>
						<IoCaretForward style={{ fontSize: '2rem' }} />
					</button>
					<button
						onClick={() => {
							setCurrentPage(totalPages - 1);
						}}
						disabled={currentPage === totalPages - 1}
						className={styles.numberedPage}>
						<AiFillForward style={{ fontSize: '2rem' }} />
					</button>
				</div>
			</div>
		</div>
	);
};

MimaGridView.displayName = 'MimaGridView';

export default MimaGridView;
