import { useNavigate } from 'react-router-dom';
import constant from '../../utils/constant';
import { useMemo } from 'react';
import { userStore } from '../../stores';
import {
	FaFacebookSquare,
	FaInstagram,
	FaTwitterSquare,
	FaLinkedin,
} from 'react-icons/fa';

const useFooterLogic = data => {
	const navigate = useNavigate();

	const businessLogo = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles?.[0]?.business?.logoUrl || '';
		}
		return '';
	}, [data]);

	const webSettingsContent = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.websiteSettings?.content || {};
		}
		return '';
	}, [data]);

	const webBusinessDetails = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.websiteSettings?.business || {};
		}
		return '';
	}, [data]);

	const footerMenu = useMemo(() => {
		const footerMenu = [
			{
				title: 'Contact Us',
				actionLink: constant.Routes.WebsiteContact,
			},
		];

		if (webSettingsContent?.faqs?.length > 0) {
			footerMenu.unshift({
				title: 'FAQs',
				actionLink: constant.Routes.WebsiteFaqs,
			});
		}

		if (webSettingsContent?.aboutUs?.text) {
			footerMenu.unshift({
				title: 'About',
				actionLink: constant.Routes.WebsiteAbout,
			});
		}
		if (webSettingsContent?.termsOfService) {
			footerMenu.push({
				title: 'Terms Of Service',
				actionLink: constant.Routes.WebsiteTermsOfService,
			});
		}
		if (webSettingsContent?.refundPolicy) {
			footerMenu.push({
				title: 'Refund Policy',
				actionLink: constant.Routes.WebsiteRefundPolicy,
			});
		}

		return footerMenu;
	}, [webSettingsContent]);

	const ourPolicyMenu = useMemo(() => {
		const policyMenu = [];

		if (webSettingsContent?.termsOfService) {
			policyMenu.push(
				{
					title: 'Privacy Policy',
					actionLink: constant.Routes.WebsiteTermsOfService,
				},
				{
					title: 'Shipping Policy',
					actionLink: constant.Routes.WebsiteTermsOfService,
				},
				{
					title: 'Terms of service',
					actionLink: constant.Routes.WebsiteTermsOfService,
				}
			);
		}
		if (webSettingsContent?.refundPolicy) {
			policyMenu.push({
				title: 'Refund Policy',
				actionLink: constant.Routes.WebsiteRefundPolicy,
			});
		}

		return policyMenu;
	}, [webSettingsContent]);

	const customerCareMenu = useMemo(() => {
		const menu = [];

		if (webSettingsContent?.aboutUs?.text) {
			menu.push({
				title: 'About Us',
				actionLink: constant.Routes.WebsiteAbout,
			});
		}

		menu.push({
			title: 'Contact Us',
			actionLink: constant.Routes.WebsiteContact,
		});

		if (webSettingsContent?.faqs?.length > 0) {
			menu.push({
				title: 'FAQs',
				actionLink: constant.Routes.WebsiteFaqs,
			});
		}

		menu.push({
			title: 'Track Order',
			actionLink: constant.Routes.WebsiteContact,
		});

		return menu;
	}, [webSettingsContent]);

	const socialHandles = useMemo(() => {
		const socials = [];

		if (webBusinessDetails?.facebookHandle) {
			socials.push({
				site: 'Facebook',
				link: `https://web.facebook.com/${webBusinessDetails?.facebookHandle}`,
				icon: FaFacebookSquare,
			});
		}
		if (webBusinessDetails?.instagramHandle) {
			socials.push({
				site: 'Instagram',
				link: `https://www.instagram.com/${webBusinessDetails?.instagramHandle}`,
				icon: FaInstagram,
			});
		}
		if (webBusinessDetails?.twitterHandle) {
			socials.push({
				site: 'Twitter',
				link: `https://twitter.com/${webBusinessDetails?.twitterHandle}`,
				icon: FaTwitterSquare,
			});
		}
		if (webBusinessDetails?.linkedinHandle) {
			socials.push({
				site: 'LinkedIn',
				link: `https://www.linkedin.com/${webBusinessDetails?.linkedinHandle}`,
				icon: FaLinkedin,
			});
		}

		return socials;
	}, [webBusinessDetails]);

	const footerMenuHandler = action => {
		userStore.setUserWebsiteData(data);
		navigate(action);
	};

	return {
		footerMenu,
		footerMenuHandler,
		socialHandles,
		businessLogo,
		ourPolicyMenu,
		customerCareMenu,
	};
};

export default useFooterLogic;
