import useEmblaCarousel from 'embla-carousel-react';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import {
	NextButton,
	PrevButton,
	usePrevNextButtons,
} from '../StyleCatalog/CatalogArrowControl';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PublicImageSlideshow = ({ imageUrls, sellingPrice }) => {
	const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

	const {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick,
	} = usePrevNextButtons(emblaApi);

	const [showBtn, setShowBtn] = useState(false);

	return (
		<>
			<div className={styles.catalog__embla}>
				<div
					className={styles.catalog__embla__viewport}
					ref={emblaRef}
					onMouseLeave={() => {
						setShowBtn(false);
					}}
					onTouchEnd={() => {
						setShowBtn(false);
					}}>
					<div className={styles.catalog__embla__container}>
						{imageUrls.slice(0, 2).map((img, i) => (
							<div
								key={i}
								className={styles.catalog__embla__slide}
								onMouseEnter={() => {
									if (imageUrls.length > 1) {
										setShowBtn(true);
									}
								}}
								onTouchStart={() => {
									if (imageUrls.length > 1) {
										setShowBtn(true);
									}
								}}>
								{/* <img src={img} alt="Style" /> */}
								<LazyLoadImage alt="Style" src={img} effect="blur" />
							</div>
						))}
					</div>

					{showBtn ? (
						<div className={styles.catalog__embla__btns}>
							<PrevButton
								onClick={e => {
									e.stopPropagation();
									onPrevButtonClick();
								}}
								disabled={prevBtnDisabled}
							/>
							<NextButton
								onClick={e => {
									e.stopPropagation();
									onNextButtonClick();
								}}
								disabled={nextBtnDisabled}
							/>
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</>
	);
};

export default PublicImageSlideshow;
