import { useMemo } from 'react';
import { StyleCatalogController } from '../controllers';
import { useNavigate } from 'react-router-dom';
import { encodeQueryData, updateCartWithCurrencyCheck } from '../utils/utils';
import { MimaToastUtil } from '../components';

const useRelatedProducts = (
	currencyCode,
	setFashCart,
	collectionQuery,
	switchedPrice
) => {
	const hostname = window.location.hostname;
	const fashSubdomain = hostname.split('.')[0];
	const domainParts = hostname.split('.');
	const customDomain = domainParts.length > 2 ? domainParts[1] : domainParts[0];
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	const subdomain = useMemo(() => {
		if (isCustomDomain) {
			return customDomain;
		}

		return fashSubdomain;
	}, [isCustomDomain, customDomain, fashSubdomain]);

	const query = useMemo(() => {
		return {
			limit: 13,
			storeFrontName: subdomain,
			offset: 1,
			...(collectionQuery ? { styleCollection: collectionQuery } : {}),
			currencyCode: currencyCode,
		};
	}, [collectionQuery, currencyCode, subdomain]);

	const encodeQuery = encodeQueryData({
		...query,
	});

	const { isLoading, data, isFetching } =
		StyleCatalogController.useGetStyleCatalog(encodeQuery);

	const navigate = useNavigate();

	const handleTableClick = row => {
		const navLink = `/catalog/${row.slug}`;
		navigate(navLink);
	};

	const addButtonHandler = (e, row) => {
		e.stopPropagation();

		if (
			row?.variations?.length > 1 ||
			row?.variations[0]?.sizes?.length > 0 ||
			row?.variations[0]?.accesories?.length > 0 ||
			row?.variations[0]?.colorsAvailable?.length > 0 ||
			row?.type === 'SERVICE'
		) {
			return handleTableClick(row);
		}

		if (row?.variations[0]?.isSoldOut) {
			return MimaToastUtil.error({
				message: 'This Product is sold out and cannot be added to the cart.',
			});
		}

		const storedFashCart = JSON.parse(localStorage.getItem('fashCart')) || [];

		const sellingPrice =
			row?.variations[0]?.sellingPrices?.filter(
				x => x?.currencyCode === currencyCode
			)[0] || {};

		const convertedPrice = switchedPrice(
			sellingPrice?.currencyCode,
			sellingPrice?.withMaterial
				? sellingPrice?.withMaterial
				: sellingPrice?.withoutMaterial
		);

		const convertedPromoPrice = switchedPrice(
			sellingPrice?.currencyCode,
			sellingPrice?.onSaleSlashPrice
		);

		const differentCurrency = storedFashCart.some(
			item => item.currencyCode !== convertedPrice?.currencyCode
		);

		// if (differentCurrency) {
		// 	return MimaToastUtil.error({
		// 		message:
		// 			'Different currencyCode detected. Cannot add the item to the cart.',
		// 	});
		// }

		const values = {
			amountPerItem:
				convertedPromoPrice?.price > 0
					? convertedPromoPrice?.price
					: convertedPrice?.price
						? convertedPrice?.price
						: 0,
			quantity: 1,
			business: row?.business || {},
			amount:
				convertedPromoPrice?.price > 0
					? convertedPromoPrice?.price
					: convertedPrice?.price
						? convertedPrice?.price
						: 0,
			currencyCode: convertedPrice?.currencyCode,
			name: row?.name || '',
			styleId: row?._id || '',
			withMaterial: sellingPrice?.withMaterial
				? 'with'
				: sellingPrice?.withoutMaterial
					? 'without'
					: '0',
			hasSize: row?.sizesAvailable?.length > 0 ? true : false,
			imageUrl: row?.imageUrls[0] || '',
			sellingPrices: row?.sellingPrices,
		};

		let fashCart;

		if (differentCurrency) {
			fashCart = updateCartWithCurrencyCheck(
				storedFashCart,
				values,
				true,
				switchedPrice
			);
		} else {
			fashCart = updateCartWithCurrencyCheck(
				storedFashCart,
				values,
				false,
				switchedPrice
			);
		}
		// const fashCart = [...storedFashCart, values];
		localStorage.setItem('fashCart', JSON.stringify(fashCart));
		setFashCart(fashCart);
		MimaToastUtil.success({ message: 'Added to Cart' });
		return;
	};

	return {
		isLoading,
		data,
		isFetching,
		handleTableClick,
		addButtonHandler,
	};
};

export default useRelatedProducts;
