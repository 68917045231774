import React, { useMemo } from 'react';
import { MimaButton, MimaText } from '../../components';
import styles from '../../assets/styles/Catalogue/CatalogCart.module.scss';
import { amountFormatter } from '../../utils/utils';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import Footer from './Footer';
import CatalogHomeHeader from './CatalogHomeHeader';
import { useCatalogUtility } from '../../hooks';

const CatalogCart = ({
	goToCatalogHome,
	fashCart,
	quantities,
	handleQtyChange,
	handleIncrement,
	handleDecrement,
	removeItemHandler,
	calcSubtotalPrice,
	checkoutHandler,
}) => {
	const {
		filtering,
		setFiltering,
		setCollectionQuery,
		data,
		setSearchQuery,
		setCurrencyCode,
		showSearch,
		setShowSearch,
		shippingInfo,
	} = useCatalogUtility();

	const subTotalPrice = useMemo(() => {
		const subTotalPrice =
			fashCart?.length > 0 ? calcSubtotalPrice(fashCart) : 0;

		return subTotalPrice;
	}, [fashCart]);

	const paystackCharge = useMemo(() => {
		return subTotalPrice < 2500
			? subTotalPrice * 0.015
			: subTotalPrice * 0.015 + 100 < 2000
				? subTotalPrice * 0.015 + 100
				: 2000;
	}, [subTotalPrice]);

	const preChargesTotal = useMemo(() => {
		const totalWithShipping =
			calcSubtotalPrice(fashCart) + shippingInfo?.shippingFee;
		const totalWithoutShipping = calcSubtotalPrice(fashCart);

		const subTotalPrice =
			fashCart?.length > 0 &&
			totalWithoutShipping > shippingInfo?.freeShippingForOrderAbove &&
			shippingInfo?.freeShippingForOrderAbove > 0
				? totalWithoutShipping
				: fashCart?.length > 0
					? totalWithShipping
					: 0;

		return subTotalPrice;
	}, [fashCart, shippingInfo?.shippingFee]);

	return (
		<main>
			<CatalogHomeHeader
				filtering={filtering}
				setFiltering={setFiltering}
				setCollectionQuery={setCollectionQuery}
				data={data}
				setSearchQuery={setSearchQuery}
				setCurrencyCode={setCurrencyCode}
				showSearch={showSearch}
				setShowSearch={setShowSearch}
			/>
			<section className={styles.cart}>
				<div className={styles.cart__title}>
					<h3>Your Cart</h3>
					<MimaButton
						variant="text"
						title="Continue Shopping"
						onClick={goToCatalogHome}
					/>
				</div>
				<div className={styles.cart__items}>
					<div
						className={`${styles.cart__items__head} ${styles.cart__items__grid}`}>
						<h5>PRODUCT</h5>
						<h5 className={styles.cart__items__head__quantity}>QUANTITY</h5>
						<h5 style={{ textAlign: 'right' }}>TOTAL</h5>
					</div>
					{fashCart?.length > 0 ? (
						<>
							<div>
								{fashCart?.map((item, i) => (
									<div
										className={`${styles.cart__items__body} ${styles.cart__items__grid}`}
										key={i}>
										<div>
											<img src={item?.imageUrl} alt="item" />
										</div>
										<div className={styles.cart__items__qty}>
											<div>
												<MimaText>{item?.name}</MimaText>
												<MimaText variant="small">
													{amountFormatter(item?.currencyCode).format(
														item?.amountPerItem
													)}
												</MimaText>
												{item?.standardSize ? (
													<MimaText variant="small">
														Size: {item?.standardSize}
													</MimaText>
												) : (
													''
												)}
											</div>
											<div className={styles.cart__items__qty__group}>
												<button
													onClick={() => {
														handleDecrement(i, item);
													}}>
													<AiOutlineMinus />
												</button>
												<input
													type="number"
													value={quantities?.[i]}
													onChange={event => handleQtyChange(i, event, item)}
													disabled
												/>
												<button
													onClick={() => {
														handleIncrement(i, item);
													}}>
													<AiOutlinePlus />
												</button>
												<span>
													<RiDeleteBin5Line
														className={styles.cart__items__delete}
														onClick={() => {
															removeItemHandler(i);
														}}
													/>
												</span>
											</div>
										</div>
										<div>
											<MimaText variant="smallBold" align="right">
												{amountFormatter(item?.currencyCode).format(
													item?.amount
												)}
											</MimaText>
										</div>
									</div>
								))}
							</div>

							<div className={styles.cart__items__subtotal}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<MimaText mb={0.5} mr={1}>
										Subtotal:
									</MimaText>
									<MimaText variant="bodyBold" mb={0.5}>
										{amountFormatter(
											fashCart?.[0]?.currencyCode || 'NGN'
										).format(subTotalPrice)}
									</MimaText>
								</div>
								<div
									style={{
										content: null,
										margin: '1rem  0',
										borderTop: '1px solid var(--color-grey)',
									}}></div>
								{shippingInfo?.shippingFee > 0 ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<MimaText mb={0.5} mr={1}>
											Shipping Fee:
										</MimaText>
										<MimaText variant="bodyBold" mb={0.5}>
											{calcSubtotalPrice(fashCart) >
												shippingInfo?.freeShippingForOrderAbove &&
											shippingInfo?.freeShippingForOrderAbove > 0
												? 'Free'
												: amountFormatter(
														fashCart?.[0]?.currencyCode || 'NGN'
													).format(shippingInfo?.shippingFee)}
										</MimaText>
									</div>
								) : (
									''
								)}
								{fashCart?.[0]?.currencyCode === 'NGN' ? (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginTop: '1rem',
										}}>
										<MimaText mb={0.5} mr={1}>
											Paystack Charges:
										</MimaText>
										<MimaText variant="bodyBold" mb={0.5}>
											{amountFormatter(
												fashCart?.[0]?.currencyCode || 'NGN'
											).format(paystackCharge)}
										</MimaText>
									</div>
								) : (
									''
								)}

								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										marginTop: '2rem',
										borderTop: '1px solid var(--color-grey)',
									}}>
									<MimaText mb={0.5} mr={1}>
										Total:
									</MimaText>
									{fashCart?.[0]?.currencyCode === 'NGN' ? (
										<MimaText variant="bodyBold" mb={0.5}>
											{amountFormatter(
												fashCart?.[0]?.currencyCode || 'NGN'
											).format(preChargesTotal + paystackCharge)}
										</MimaText>
									) : (
										<MimaText variant="bodyBold" mb={0.5}>
											{amountFormatter(
												fashCart?.[0]?.currencyCode || 'USD'
											).format(preChargesTotal)}
										</MimaText>
									)}
								</div>
								{/* <MimaText variant="small" mb={1}>
									Taxes and Transaction charges calculated at checkout
								</MimaText> */}
								<MimaButton
									title="Checkout"
									width={28}
									onClick={checkoutHandler}
									mt={2}
								/>
							</div>
						</>
					) : (
						<div className={styles.cart__empty}>
							<MimaText variant="subtitle" mb={3}>
								Your cart is currently empty
							</MimaText>
							<MimaButton
								title="Start Shopping"
								// width={28}
								onClick={goToCatalogHome}
							/>
						</div>
					)}
				</div>
			</section>
			<Footer data={data} />
		</main>
	);
};

export default CatalogCart;
