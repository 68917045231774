import React, { useMemo, useState } from 'react';
import * as yup from 'yup';
import constant from '../../utils/constant';
import { MimaToastUtil } from '../../components';
import { StyleCatalogController } from '../../controllers';
import { RequestOrder } from '../../pages';
import { phoneRegExp } from '../../utils/utils';

const RequestOrderContainer = ({ closeModal, data }) => {
	const [loading, setLoading] = useState(false);
	const [stage, setStage] = useState('STAGE1');

	// const subdomain = window.location.hostname.split('.')[0];
	const hostname = window.location.hostname;
	const fashSubdomain = hostname.split('.')[0];
	const domainParts = hostname.split('.');
	const customDomain = domainParts.length > 2 ? domainParts[1] : domainParts[0];
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	const subdomain = useMemo(() => {
		if (isCustomDomain) {
			return customDomain;
		}

		return fashSubdomain;
	}, [isCustomDomain, customDomain, fashSubdomain]);

	const validationSchemaStage1 = () => {
		return yup.object().shape({
			customer: yup.object({
				fullname: yup.string().required('Enter Customer Name'),
				mobile: yup
					.string()
					.matches(phoneRegExp, 'Mobile number is not valid')
					.required('Mobile number is required'),
				address: yup.string(),
			}),
		});
	};
	const validationSchemaStage2 = () => {
		return yup.object().shape({
			currencyCode: yup.string().required('Select Currency Code'),
			note: yup
				.string()
				.max(
					150,
					'Note length must be less than or equal to 150 characters long'
				),
			amountPaid: yup.string().required('Enter Amount Paid'),
			dueDate: yup.date().required('Enter Order due date'),
			shipping: yup.string(),
			items: yup.array(
				yup.object({
					item: yup.string(),
					unitPrice: yup.number().required('Enter unit price'),
				})
			),
		});
	};
	const validationSchemaStage3 = () => {
		return yup.object().shape({
			imageUrls: yup
				.array()
				.min(1, 'At least one image is required')
				.required('Required'),
		});
	};

	const onSubmit = async (values, { resetForm }) => {
		setLoading(true);

		const payload = {
			customer: values.customer,
			subDomain: subdomain,
			currencyCode: values.currencyCode,
			amountPaid: values.amountPaid,
			note: values.note,
			dueDate: values.dueDate,
			shipping: 0,
			imageUrls: values.imageUrls,
			items: values.items.map(item => ({
				item: item.item,
				unitPrice: item.unitPrice,
				quantity: item.quantity,
			})),
		};

		const { status, errorMessage } =
			await StyleCatalogController.createOrder(payload);
		if (status === constant.Success) {
			MimaToastUtil.success({ message: constant.Success });
			setLoading(false);
			resetForm();
			return setStage('STAGE1');
		} else {
			setLoading(false);
			MimaToastUtil.error({ message: errorMessage });
		}
	};

	const goBack = () => {
		if (stage === 'STAGE2') {
			setStage('STAGE1');
		} else {
			setStage('STAGE2');
		}
	};

	const goToNextStage = async validateForm => {
		const errors = await validateForm();
		if (Object.keys(errors).length === 0 && stage === 'STAGE1') {
			setStage('STAGE2');
		} else if (Object.keys(errors).length === 0 && stage === 'STAGE2') {
			setStage('STAGE3');
		}
	};

	return (
		<RequestOrder
			validationSchemaStage1={validationSchemaStage1}
			validationSchemaStage2={validationSchemaStage2}
			loading={loading}
			closeModal={closeModal}
			data={data}
			onSubmit={onSubmit}
			stage={stage}
			goToNextStage={goToNextStage}
			goBack={goBack}
			validationSchemaStage3={validationSchemaStage3}
		/>
	);
};
export default RequestOrderContainer;
