import FashWhiteLogo from './Fash creative white logo.png';
import FashMainLogo from './Fash creative.png';
import FashShape from './fash-shape.png';
import FashShape2 from './fash-shape2.png';

export { FashWhiteLogo, FashMainLogo, FashShape, FashShape2 };

const imageList = {
	createStyleImg:
		'https://res.cloudinary.com/mima-business/image/upload/v1712969163/fash%20creatives%20web/iphone_half_ubikga.png',
	packOrder:
		'https://res.cloudinary.com/mima-business/image/upload/v1713028245/fash%20creatives%20web/pexels-ron-lach-9594415_antrk8.jpg',
	orderItem:
		'https://res.cloudinary.com/mima-business/image/upload/v1713028244/fash%20creatives%20web/close-up-man-shopping-with-laptop_k7m21c.jpg',
	tailor:
		'https://res.cloudinary.com/mima-business/image/upload/v1713028242/fash%20creatives%20web/pexels-pavel-danilyuk-6461396_ox5qwr.jpg',
	appMenu:
		'https://res.cloudinary.com/mima-business/image/upload/v1713028240/fash%20creatives%20web/iphone_half_2_uc5a5p.png',
	paybills:
		'https://res.cloudinary.com/mima-business/image/upload/v1713029608/fash%20creatives%20web/iphone_half3_o8a1bb.png',
	globeWorld:
		'https://res.cloudinary.com/mima-business/image/upload/v1713059311/fash%20creatives%20web/globe_world_xeivpx.png',
	ThemeImg1:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/1cde7004-0544-485c-9756-b46690030ada.webp',
	ThemeImg2:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/48b3c205-343a-4659-987a-ead1d0d2def4.webp',
	ThemeImg3:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/6fb3d1d6-1c8c-4d28-89c8-ab03861ad46d.webp',
	ThemeImg4:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/b7536e43-98c9-44af-b518-9da34b4ac439.webp',
	ThemeImg5:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/7ae18f12-8b6c-4ea4-881d-a4b0f7253160.webp',
	ThemeImg6:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/9ac34a7b-4bfd-4de9-bef6-6ed947829d95.webp',
	ThemeImg7:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/87a9d986-5b2d-4507-9988-51e96c9b3ce7.webp',
	ThemeImg8:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/c71a3865-6188-400d-8142-1f50e5fc0ee3.webp',
	ThemeImg9:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/0087e2cc-39c4-434b-b271-251eeb863614.webp',
	ThemeImg10:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/c787a1b2-2ee1-4070-82a0-6534694e7756.avif',
	ThemeImg11:
		'https://s3bc7d7a5a59a4dca7c1c17bfa0270e4b1.s3.us-east-2.amazonaws.com/66173e2e-1ccf-4207-b69e-4b5f5e4fdd9e.avif',
};

export default imageList;
