// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_label__5uQY1 {
  font-size: var(--default-font-small);
  font-family: inherit;
  padding-left: 2rem;
  margin-bottom: 1rem;
}
.Button_label__wide__LB\\+IG {
  padding-left: 4.5rem;
}

.Button_numberedPage__VoIlC {
  width: 3rem;
  height: 3rem;
  font-size: var(--default-font-small);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--color-white);
}
.Button_numberedPage__VoIlC:hover {
  border: 1px solid #2267ce;
  color: var(--color-primary);
}
.Button_numberedPage__VoIlC:disabled {
  color: var(--color-grey);
  border: 1px solid var(--color-grey);
}

.Button_numberPageActive__Krk4q {
  border: 1px solid #2267ce;
  color: var(--color-primary);
  box-shadow: 0px 0px 7px rgba(34, 103, 206, 0.5);
}

.Button_pagination__z15Cf {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  width: 100%;
}
.Button_pagination__z15Cf > :not(:last-child) {
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Button.module.scss"],"names":[],"mappings":"AAEA;EACC,oCAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;AADD;AAGC;EACC,oBAAA;AADF;;AAMA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,oCAAA;AAHA;AAKC;EACC,yBAAA;EACA,2BAAA;AAHF;AAOC;EACC,wBAAA;EACA,mCAAA;AALF;;AAUA;EACC,yBAAA;EACA,2BAAA;EACA,+CAAA;AAPD;;AAUA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AAPD;AASC;EACC,kBAAA;AAPF","sourcesContent":["@import './variables/breakpoints';\n\n.label {\n\tfont-size: var(--default-font-small);\n\tfont-family: inherit;\n\tpadding-left: 2rem;\n\tmargin-bottom: 1rem;\n\n\t&__wide {\n\t\tpadding-left: 4.5rem;\n\t}\n}\n\n\n.numberedPage {\nwidth: 3rem;\nheight: 3rem;\nfont-size: var(--default-font-small);\nborder-radius: 0.5rem;\ndisplay: flex;\nalign-items: center;\njustify-content: center;\ncursor: pointer;\nbackground-color: var(--color-white);\n\n\t&:hover{\n\t\tborder: 1px solid #2267ce;\n\t\tcolor: var(--color-primary);\n\t}\n\n\n\t&:disabled {\n\t\tcolor: var(--color-grey);\n\t\tborder: 1px solid var(--color-grey);\n\n\t}\n}\n\n.numberPageActive {\n\tborder: 1px solid #2267ce;\n\tcolor: var(--color-primary);\n\tbox-shadow: 0px 0px 7px rgba(34, 103, 206, 0.5);\n}\n\n.pagination {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin-top: 2rem;\t\n\twidth: 100%;\n\n\t& > :not(:last-child) {\n\t\tmargin-right: 1rem;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Button_label__5uQY1`,
	"label__wide": `Button_label__wide__LB+IG`,
	"numberedPage": `Button_numberedPage__VoIlC`,
	"numberPageActive": `Button_numberPageActive__Krk4q`,
	"pagination": `Button_pagination__z15Cf`
};
export default ___CSS_LOADER_EXPORT___;
