// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MimaFilter_filter__gv8jg {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 3rem;
  background-color: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;
  height: fit-content;
}
.MimaFilter_filter_button__JCcXo {
  display: flex;
  align-items: center;
  color: var(--color-primary);
  cursor: pointer;
  font-size: var(--default-font-small);
}
.MimaFilter_filter_button__JCcXo:hover {
  color: var(--color-primary-4);
}
.MimaFilter_filter_button_icon__kkU8o {
  font-size: 3rem;
  margin-right: 1rem;
}
.MimaFilter_filter_line__Z7nig {
  margin-top: 1rem;
  border-top: 1px solid var(--color-primary);
}
.MimaFilter_filter_content__tbKEd {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 2rem;
  gap: 1rem;
}
.MimaFilter_filter_content__tbKEd > :not(last-child) {
  margin-right: 1rem;
}
@media only screen and (max-width: 43.75em) {
  .MimaFilter_filter_content__tbKEd > :not(last-child) {
    margin-bottom: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/MimaFilter/MimaFilter.module.scss"],"names":[],"mappings":"AAEA;EACC,WAAA;EACA,sBAAA;EACA,kBAAA;EACA,oCAAA;EACA,mBAAA;EACA,4CAAA;EACA,mBAAA;EACA,mBAAA;AADD;AAGC;EACC,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,oCAAA;AADF;AAGE;EACC,6BAAA;AADH;AAIE;EACC,eAAA;EACA,kBAAA;AAFH;AAMC;EACC,gBAAA;EACA,0CAAA;AAJF;AAOC;EACC,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AALF;AAOE;EACC,kBAAA;AALH;AAOG;EAHD;IAIE,mBAAA;EAJF;AACF","sourcesContent":["@import '../variables/breakpoints';\n\n.filter {\n\twidth: 100%;\n\tbox-sizing: border-box;\n\tpadding: 1rem 3rem;\n\tbackground-color: var(--color-white);\n\tborder-radius: 1rem;\n\tbox-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);\n\tmargin-bottom: 2rem;\n\theight: fit-content;\n\n\t&_button {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tcolor: var(--color-primary);\n\t\tcursor: pointer;\n\t\tfont-size: var(--default-font-small);\n\n\t\t&:hover {\n\t\t\tcolor: var(--color-primary-4);\n\t\t}\n\n\t\t&_icon {\n\t\t\tfont-size: 3rem;\n\t\t\tmargin-right: 1rem;\n\t\t}\n\t}\n\n\t&_line {\n\t\tmargin-top: 1rem;\n\t\tborder-top: 1px solid var(--color-primary);\n\t}\n\n\t&_content {\n\t\tdisplay: flex;\n\t\tflex-wrap: wrap;\n\t\talign-items: flex-start;\n\t\tmargin-bottom: 2rem;\n\t\tgap: 1rem;\n\n\t\t&> :not(last-child) {\n\t\t\tmargin-right: 1rem;\n\n\t\t\t@media only screen and (max-width: $bp-700) {\n\t\t\t\tmargin-bottom: 2rem;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter": `MimaFilter_filter__gv8jg`,
	"filter_button": `MimaFilter_filter_button__JCcXo`,
	"filter_button_icon": `MimaFilter_filter_button_icon__kkU8o`,
	"filter_line": `MimaFilter_filter_line__Z7nig`,
	"filter_content": `MimaFilter_filter_content__tbKEd`
};
export default ___CSS_LOADER_EXPORT___;
