import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export default class WalletStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'WalletStore',
			properties: [
				'balance',
				'transferLimit',
				'accountName',
				'accountNumber',
				'transferPayload',
				'subWallets',
				'selectedSubWallet',
				'wallets',
				'selectedWallet',
				'dynamicAccountNumbers',
				'creatingAccNo'
			],
		});
	}

	balance = '';

	transferLimit = '';
	accountName = '';
	accountNumber = '';
	transferPayload = {};
	subWallets = [];
	selectedSubWallet = {};
	wallets = [];
	selectedWallet = {};
	dynamicAccountNumbers = {};
	creatingAccNo = false;

	reset = () => {
		this.balance = '';
		this.transferLimit = '';
		this.accountName = '';
		this.accountNumber = '';
		this.transferPayload = {};
		this.subWallets = [];
		this.selectedSubWallet = {};
		this.wallets = [];
		this.selectedWallet = {};
		this.dynamicAccountNumbers = {};
		this.creatingAccNo = false;
	};

	setBalance = async balance => {
		runInAction(async () => {
			this.balance = balance;
		});
	};
	setWallets = async state => {
		runInAction(async () => {
			this.wallets = state;
		});
	}
	setSelectedSubWallet = async state => {
		runInAction(async () => {
			this.selectedSubWallet = state;
		});
	};
	setTransferPayload = async transferPayload => {
		runInAction(async () => {
			this.transferPayload = transferPayload;
		});
	};
	setSubWallets = async state => {
		runInAction(async () => {
			this.subWallets = state;
		});
	};
	setAccountName = async name => {
		runInAction(async () => {
			this.accountName = name;
		});
	};

	setAccountNumber = async number => {
		runInAction(async () => {
			this.accountNumber = number;
		});
	};

	setTransferLimit = async limit => {
		runInAction(async () => {
			this.transferLimit = limit;
		});
	};

	setSelectedWallet = async state => {
		runInAction(async () => {
			this.selectedWallet = state;
		});
	};

	setDynamicAccountNumbers = async state => {
		runInAction(async () => {
			this.dynamicAccountNumbers = state;
		});
	};

	setCreatingAccNo = async state => {
		runInAction(async () => {
			this.creatingAccNo = state;
		});
	};
}
