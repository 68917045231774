import { useEffect } from 'react';

const useIntersectionObserver = ({
	target,
	enabled = true,
	onIntersect,
	root,
	rootMargin = '0px',
	threshold = 0.1,
}) => {
	useEffect(() => {
		if (!enabled) {
			return;
		}

		const observer = new IntersectionObserver(
			entries =>
				entries.forEach(entry => entry.isIntersecting && onIntersect()),
			{
				root: root && root.current,
				rootMargin,
				threshold,
			}
		);

		const el = target && target.current;

		if (!el) {
			return;
		}

		observer.observe(el);

		return () => {
			observer.unobserve(el);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enabled, target.current]);
};

export default useIntersectionObserver;
