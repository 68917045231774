import { MimaText, PageLoader } from '../../components';
import styles from '../../assets/styles/Catalogue/StyleCatalogue.module.scss';
import React, { useMemo, useState } from 'react';
import Footer from './Footer';
import { CatalogHomeHeader } from '.';
import { GrFormAdd, GrFormSubtract } from 'react-icons/gr';
import { useCatalogUtility } from '../../hooks';

const Faqs = () => {
	const [answer, setAnswer] = useState(-1);
	const showAnswer = i => {
		if (answer === i) {
			return setAnswer(-1);
		} else {
			setAnswer(i);
		}
	};

	const {
		filtering,
		setFiltering,
		setCollectionQuery,
		data,
		setSearchQuery,
		setCurrencyCode,
		showSearch,
		setShowSearch,
		isLoading,
	} = useCatalogUtility();

	const faqData = useMemo(() => {
		return data?.data?.websiteSettings?.content?.faqs || [];
	}, [data]);

	return (
		<main>
			<CatalogHomeHeader
				filtering={filtering}
				setFiltering={setFiltering}
				setCollectionQuery={setCollectionQuery}
				data={data}
				setSearchQuery={setSearchQuery}
				setCurrencyCode={setCurrencyCode}
				showSearch={showSearch}
				setShowSearch={setShowSearch}
			/>

			{isLoading ? (
				<div style={{ marginTop: '15rem' }}>
					<PageLoader />
				</div>
			) : (
				<>
					<div className={styles.content}>
						<MimaText variant="h3" align="center" mb={1}>
							Faqs
						</MimaText>
						<MimaText variant="subtitle" align="center" mb={3}>
							See answers to previously asked questions
						</MimaText>
						{faqData
							? faqData?.map((x, i) => {
									return (
										<div key={i} className={styles.aside__ans}>
											<div
												className={
													answer === i ? styles.signs__active : styles.signs
												}
												onClick={() => {
													showAnswer(i);
												}}>
												<div className={styles.icon}>
													{answer === i ? <GrFormSubtract /> : <GrFormAdd />}
												</div>
												<div>{x?.question}</div>
											</div>
											{answer === i && <MimaText ml={3}>{x?.text}</MimaText>}
										</div>
									);
								})
							: null}
					</div>
					<Footer data={data} />{' '}
				</>
			)}
		</main>
	);
};

export default Faqs;
