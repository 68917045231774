import React, { useCallback, useEffect, useState } from 'react';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

export const usePrevNextButtons = emblaApi => {
	const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
	const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

	const onPrevButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollPrev();
	}, [emblaApi]);

	const onNextButtonClick = useCallback(() => {
		if (!emblaApi) return;
		emblaApi.scrollNext();
	}, [emblaApi]);

	const onSelect = useCallback(emblaApi => {
		setPrevBtnDisabled(!emblaApi.canScrollPrev());
		setNextBtnDisabled(!emblaApi.canScrollNext());
	}, []);

	useEffect(() => {
		if (!emblaApi) return;

		onSelect(emblaApi);
		emblaApi.on('reInit', onSelect);
		emblaApi.on('select', onSelect);
	}, [emblaApi, onSelect]);

	return {
		prevBtnDisabled,
		nextBtnDisabled,
		onPrevButtonClick,
		onNextButtonClick,
	};
};

export const PrevButton = props => {
	const { children, ...restProps } = props;

	return (
		<button className={styles.catalog__embla__btn} type="button" {...restProps}>
			<FaArrowLeft />
			{children}
		</button>
	);
};

export const NextButton = props => {
	const { children, ...restProps } = props;

	return (
		<button className={styles.catalog__embla__btn} type="button" {...restProps}>
			<FaArrowRight />
			{children}
		</button>
	);
};
