import styles from '../../assets/styles/MimaInput.module.scss';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import * as inputStyles from './styles';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const propTypes = {
	labelTitle: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	width: PropTypes.any,
	mt: PropTypes.number,
	mr: PropTypes.number,
	mb: PropTypes.number,
	ml: PropTypes.number,
	error: PropTypes.any,
	touched: PropTypes.any,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
};

const MimaQuillInput = ({
	labelTitle,
	placeholder,
	width,
	id,
	name,
	mt,
	mr,
	mb,
	ml,
	touched,
	error,
	value,
	onChange,
	onBlur,
	...props
}) => {
	return (
		<DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
			{labelTitle && (
				<label className={styles.form__label} htmlFor={name}>
					{labelTitle}
				</label>
			)}
			<StyledQuill
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				name={name}
			/>
			{error ? <div className={styles.error}>{touched && error}</div> : null}
		</DivSkeleton>
	);
};

const DivSkeleton = styled.div`
	${inputStyles.divBase};
	${props => props.mt && `margin-top: ${props.mt}rem`};
	${props => props.mr && `margin-right: ${props.mr}rem`};
	${props => props.mb && `margin-bottom: ${props.mb}rem`};
	${props => props.ml && `margin-left: ${props.ml}rem`};
	${props => props.width && `width: ${props.width}rem`};
`;

const StyledQuill = styled(ReactQuill)`
	.ql-container {
		min-height: 27rem;
		max-height: 50rem;
		background-color: #f9f9f9;
		border: 1px solid var(--color-grey);
		border-radius: 0 0 0.8rem 0.8rem;
		overflow-y: auto;
	}

	.ql-toolbar {
		background-color: #f0f0f0;
		border: 1px solid var(--color-grey);
		border-radius: 0.8rem 0.8rem 0 0;
	}

	.ql-editor {
		padding: 1rem;
		font-size: 1.8rem;
		color: #333;
	}

	.ql-placeholder {
		color: #aaa;
	}
`;

MimaQuillInput.propTypes = propTypes;

export default MimaQuillInput;
