import {
	// RequestOrderContainer,
	PaymentCheckoutContainer,
	PublicCatalogueContainer,
	// SingleCatalogContainer,
	SinglePublicCatalogueContainer,
} from '../containers';

import LandingPage from '../pages/LandingPage/LandingPage';
import Error404 from '../pages/Error/Error404';
import constant from '../utils/constant';
// import {
// 	About,
// 	Faqs,
// 	Terms,
// 	RefundPolicy,
// 	Contact,
// 	CatalogShop,
// 	SizeGuide,
// } from '../pages/StyleCatalog';
// import { CatalogCartContainer } from '../containers/StyleCatalogContainer';

const openRoutes = [
	{
		path: constant.Routes.Home,
		element: <LandingPage />,
	},
	{
		path: constant.Routes.Error404,
		element: <Error404 />,
	},
	{
		path: constant.Routes.PaymentCheckout,
		element: <PaymentCheckoutContainer />,
	},
	{
		path: constant.Routes.SingleCatalog,
		// element: <SingleCatalogContainer />,
		component: 'Product',
	},
	{
		path: constant.Routes.WebsiteAbout,
		// element: <About />,
		component: 'About',
	},
	{
		path: constant.Routes.WebsiteTermsOfService,
		// element: <Terms />,
		component: 'Terms',
	},
	{
		path: constant.Routes.WebsiteContact,
		// element: <Contact />,
		component: 'Contact',
	},
	{
		path: constant.Routes.WebsiteRefundPolicy,
		// element: <RefundPolicy />,
		component: 'RefundPolicy',
	},
	{
		path: constant.Routes.WebsiteFaqs,
		// element: <Faqs />,
		component: 'Faqs',
	},
	{
		path: constant.Routes.WebsiteCart,
		// element: <CatalogCartContainer />,
		component: 'Cart',
	},
	{
		path: constant.Routes.WebsiteShop,
		// element: <CatalogShop />,
		component: 'Shop',
	},
	{
		path: constant.Routes.WebsiteSearch,
		// element: <CatalogSearch />,
		component: 'Search',
	},
	{
		path: constant.Routes.SizeGuide,
		// element: <SizeGuide />,
		component: 'SizeGuide',
	},
	{
		path: constant.Routes.PublicCatalogue,
		element: <PublicCatalogueContainer />,
	},
	{
		path: constant.Routes.SinglePublicCatalogue,
		element: <SinglePublicCatalogueContainer />,
	},
];

export default openRoutes;
