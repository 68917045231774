import styles from '../../assets/styles/Error/Error404.module.scss';
import { MimaText, MimaButton } from '../../components';
import ErrorImg from '../../assets/img/Mima-Error.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import constant from '../../utils/constant';
import React from 'react';

const Error404 = () => {
	const [searchParams] = useSearchParams();
	const error = searchParams.get('error');

	const navigate = useNavigate();
	const goHome = () => {
		navigate(constant.Routes.Home);
	};

	const goToFashSignup = () => {
		window.location.href = constant.Routes.SignUp;
		navigate(constant.Routes.SignUp);
	};

	return (
		<div className={styles.container}>
			<img src={ErrorImg} alt="Wandering away" className={styles.img} />
			<MimaText variant="h1"> OH WOW! </MimaText>
			<MimaText variant="h3" color="var(--color-grey)">
				{' '}
				Page not Found{' '}
			</MimaText>
			{error === constant.errorMsg.storeNotFound ? (
				<>
					<MimaText align="center" mt={2}>
						The URL you are trying to access is not available on our server or
						the user is not registered. <br />
						Please click the button below to sign up and start using Fash
						Creatives and get a website in 5mins.
					</MimaText>
					<MimaButton
						title="Start using Fash"
						mt={3}
						onClick={goToFashSignup}
					/>
				</>
			) : (
				<>
					<MimaText align="center" mt={2}>
						Seems you have wondered into the unknown, <br />
						please click on the button below to find your way back. <br />
						Thank you
					</MimaText>
					<MimaButton title="Go back Home" mt={3} onClick={goHome} />
				</>
			)}
		</div>
	);
};

export default Error404;
