// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error404_container__34wp7 {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}

.Error404_img__vZ2kx {
  height: 25rem;
}
@media only screen and (max-width: 37.5em) {
  .Error404_img__vZ2kx {
    height: 15rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Error/Error404.module.scss"],"names":[],"mappings":"AAEA;EACC,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AADD;;AAIA;EACC,aAAA;AADD;AAGC;EAHD;IAIE,aAAA;EAAA;AACF","sourcesContent":["@import '../variables/breakpoints';\n\n.container {\n\twidth: 100vw;\n\theight: 100vh;\n\tdisplay: flex;\n\tflex-direction: column;\n\tbox-sizing: border-box;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 5rem;\n}\n\n.img {\n\theight: 25rem;\n\n\t@media only screen and (max-width: $bp-600) {\n\t\theight: 15rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Error404_container__34wp7`,
	"img": `Error404_img__vZ2kx`
};
export default ___CSS_LOADER_EXPORT___;
