import React, { useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { MimaButton, MimaText, PoweredBy } from '../../components';
import styles from '../../assets/styles/PaymentLink/PaymentLinkCheckout.module.scss';
import { useStripe, useElements } from '@stripe/react-stripe-js';

const StripeCheckoutForm = ({ onSuccess, onClose, goBack }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [loading, setLoading] = useState(false);

	const handleSubmit = async e => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		setLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			// confirmParams: {
			// 	return_url: `${window.location.origin}`,
			// },
			redirect: 'if_required',
		});

		if (error) {
			setLoading(false);
			console.error(error?.message);
			return onClose();
		}

		setLoading(false);
		return onSuccess();
	};
	return (
		<form className={styles.checkout__form} onSubmit={handleSubmit}>
			<MimaText variant="subtitleBold">Payment</MimaText>
			<MimaText mb={3} color="#999">
				All transactions are secure and encrypted.
			</MimaText>
			<PaymentElement />
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '15rem 1fr',
					gap: '1rem',
					width: '100%',
				}}>
				<MimaButton
					title="Go Back"
					variant="form"
					mt={2}
					loading={loading}
					buttonColor="var(--color-dark)"
					onClick={goBack}
				/>
				<MimaButton
					title="Pay now"
					type="submit"
					variant="form"
					mt={2}
					loading={loading}
				/>
			</div>
			<PoweredBy />
		</form>
	);
};

export default StripeCheckoutForm;
