import React from 'react';
import styled from 'styled-components';
import maintenance from '../../assets/img/maintenance.png';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f7f7f7;
	padding: 20px;
	box-sizing: border-box;
`;

const MaintenanceBox = styled.div`
	text-align: center;
	background-color: #ffffff;
	padding: 40px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	width: 100%;
`;

const Image = styled.img`
	margin: 0 auto 18px; /* Centers the image horizontally */
	display: block; /* Ensures margin auto works for centering */
`;

const Title = styled.h1`
	font-size: 2.5em;
	margin-bottom: 10px;
`;

const Message = styled.p`
	font-size: 1.2em;
	margin-bottom: 20px;
`;

const MaintenancePage = () => {
	return (
		<Container>
			<MaintenanceBox>
				<Image src={maintenance} alt="Maintenance Image" />
				<>
					<Title>Website Under Maintenance!</Title>
					<Message>
						Our website is currently undergoing scheduled maintenance.
					</Message>
					<span>We will be back shortly. Thank you for your patience.</span>
				</>
			</MaintenanceBox>
		</Container>
	);
};

export default MaintenancePage;
