import React from 'react';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import { MimaText, PageLoader } from '../../components';
import { AnimatePresence } from 'framer-motion';
import { RDModalContainer } from '../../containers';
import SingleCatalogImage from './SingleCatalogImage';
import SingleCatalogDetails from './SingleCatalogDetails';
import { useCatalogUtility } from '../../hooks';
import CatalogHomeHeader from './CatalogHomeHeader';
import { useMediaQuery } from 'react-responsive';

const SingleCatalog = ({
	onSubmit,
	loading,
	loading2,
	loading3,
	closeModal,
	rdModal,
	initData,
	validationSchema,
}) => {
	const singleStyle = initData;

	const {
		filtering,
		setFiltering,
		setCollectionQuery,
		data,
		setSearchQuery,
		setCurrencyCode,
		showSearch,
		setShowSearch,
		shippingInfo,
		currencyCode,
	} = useCatalogUtility();

	const isBelow500 = useMediaQuery({
		query: '(max-width: 500px)',
	});

	return (
		<main className={styles.single}>
			{loading ? (
				<PageLoader />
			) : (
				<section>
					<CatalogHomeHeader
						filtering={filtering}
						setFiltering={setFiltering}
						setCollectionQuery={setCollectionQuery}
						data={data}
						setSearchQuery={setSearchQuery}
						setCurrencyCode={setCurrencyCode}
						showSearch={showSearch}
						setShowSearch={setShowSearch}
					/>

					<MimaText variant="smallBold" mb={3} mt={isBelow500 ? 14 : 5}>
						{singleStyle?.hashTags?.map((hash, i) => (
							<span style={{ marginRight: '1rem' }} key={i}>
								{hash}
							</span>
						))}
						{singleStyle?.hashTags?.length > 0 ? ' - ' : ''}
						{singleStyle.name}
					</MimaText>
					<div className={styles.single__grid}>
						<SingleCatalogImage
							singleStyle={singleStyle}
							currencyCode={currencyCode}
						/>
						<SingleCatalogDetails
							singleStyle={singleStyle}
							onSubmit={onSubmit}
							loading2={loading2}
							loading3={loading3}
							validationSchema={validationSchema}
							shippingInfo={shippingInfo}
							currencyCode={currencyCode}
						/>
					</div>
				</section>
			)}

			{rdModal && (
				<div className="modal">
					<AnimatePresence>
						{rdModal && <RDModalContainer closeModal={closeModal} />}
					</AnimatePresence>
				</div>
			)}
		</main>
	);
};

export default SingleCatalog;
