import {
	CatalogHome,
	SingleCatalog,
	RDModal,
	CatalogCart,
	CatalogShop,
	SizeGuide,
} from './StyleCatalog';
import RequestOrder from './RequestOrder';
import { PaymentCheckout } from './PaymentCheckout';

import { PublicCatalogue, SinglePublicCatalogue } from './PublicCatalogue';

export {
	CatalogHome,
	SingleCatalog,
	RDModal,
	PaymentCheckout,
	CatalogCart,
	CatalogShop,
	SizeGuide,
	PublicCatalogue,
	SinglePublicCatalogue,
	RequestOrder,
};
