import styles from '../../assets/styles/MimaInput.module.scss';

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import * as inputStyles from './styles';
import IconButton from '../IconButton';
import { MdClose } from 'react-icons/md';
import MimaText from '../MimaText';
import { BsInfoCircleFill } from 'react-icons/bs';

const propTypes = {
	labelTitle: PropTypes.string,
	type: PropTypes.oneOf([
		'text',
		'email',
		'number',
		'password',
		'radio',
		'date',
		'textarea',
		'tel',
		'time',
	]),
	placeholder: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.any,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf([
		'medium',
		'wide',
		'form',
		'mobile',
		'message',
		'exchange',
		'form2',
		'error',
		'formError',
		'checkoutForm',
	]),
	textAreaVariant: PropTypes.oneOf(['type1', 'type2', 'type3', 'error']),
	width: PropTypes.any,
	icon: PropTypes.element,
	mt: PropTypes.number,
	mr: PropTypes.number,
	mb: PropTypes.number,
	ml: PropTypes.number,
	rows: PropTypes.number,
	cols: PropTypes.number,
	error: PropTypes.any,
	touched: PropTypes.any,
	passwordIcon: PropTypes.bool,
	fontSize: PropTypes.number,
	suggestionData: PropTypes.array,
	handleFilter: PropTypes.func,
	note: PropTypes.string,
	showPageCount: PropTypes.bool,
	showTimeSelect: PropTypes.any,
	excludeTimes: PropTypes.array,
	excludeDates: PropTypes.array,
	excludeWeekends: PropTypes.bool,
	excludePastDays: PropTypes.bool,
	excludeFutureDays: PropTypes.bool,
};

const defaultProps = {
	labelTitle: '',
	type: 'text',
	placeholder: '',
	id: '',
	value: '',
	name: '',
	onBlur: () => {},
	onChange: () => {},
	disabled: false,
	variant: 'medium',
	width: '',
	textAreaVariant: 'type1',
	handleFilter: (item, text) => {},
	excludeWeekends: false,
	excludePastDays: false,
	excludeFutureDays: false,
};

export const MimaTagInput = ({
	labelTitle,
	placeholder,
	onBlur,
	onChange: origOnChange,
	value,
	disabled,
	type,
	variant,
	width,
	height,
	id,
	max,
	min,
	name,
	mt,
	mr,
	mb,
	ml,
	rows,
	cols,
	touched,
	error,
	passwordIcon,
	fontSize,
	suggestionData,
	handleFilter,
	tags,
	setTags,
	...props
}) => {
	//for tags
	// const [tags, setTags] = useState([]);

	//Add tags
	const handleKeyDown = event => {
		if (event.key === 'Enter' && event.target.value !== '') {
			const multiTags = inputValue.split(',');

			if (inputValue.includes(',')) {
				setTags(tags.concat(multiTags.filter(item => tags.indexOf(item) < 0)));
			} else if (!tags.includes(inputValue)) {
				setTags([...tags, inputValue]);
			}

			// setTags([...tags, event.target.value]);
			// event.target.value = "";
			setInputValue('');
		} else if (
			event.key === 'Backspace' &&
			tags.length &&
			event.target.value === 0
		) {
			const tagsCopy = [...tags];
			tagsCopy.pop();
			event.preventDefault();
			setTags(tagsCopy);
		}
	};

	//Remove tags
	const removeTags = index => {
		setTags([...tags.filter(tag => tags.indexOf(tag) !== index)]);
	};

	//Making tags come from a particular data selection
	const [inputValue, setInputValue] = useState('');
	const [menuVisible, setMenuVisible] = useState(false);
	const [filteredData, setFilteredData] = useState([]);

	const filterData = text => {
		return suggestionData.filter(item =>
			handleFilter(item, text?.toLowerCase())
		);
	};

	const onChangeHandler = e => {
		const text = e.target.value;

		setInputValue(text);
		if (text && text.length > 0) {
			setFilteredData(filterData(text));
		} else {
			setFilteredData([]);
		}
		setMenuVisible(true);

		if (inputValue === '') {
			setMenuVisible(false);
		}
	};

	const onBlurHandler = event => {
		if (event.target.value !== '') {
			const multiTags = inputValue.split(',');

			if (inputValue.includes(',')) {
				setTags(tags.concat(multiTags.filter(item => tags.indexOf(item) < 0)));
			} else if (!tags.includes(inputValue)) {
				setTags([...tags, inputValue]);
			}

			// setTags([...tags, event.target.value]);
			// event.target.value = "";
			setInputValue('');
		}
	};
	return (
		<DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
			<label className={styles.form__label}>{labelTitle}</label>
			<InnerDivSkeleton>
				<div className={styles.tags}>
					{tags.map((tag, index) => (
						<div className={styles.singleTag} key={index}>
							<span>{tag}</span>
							<i onClick={() => removeTags(index)}>
								<MdClose />
							</i>
						</div>
					))}
				</div>
				<TagInputSkeleton
					placeholder={placeholder}
					onBlur={e => {
						onBlur(e);
						onBlurHandler(e);
					}}
					disabled={disabled}
					onChange={onChangeHandler}
					width={width}
					value={inputValue}
					id={id}
					name={name}
					height={height}
					rows={rows}
					cols={cols}
					fontSize={fontSize}
					onKeyDown={handleKeyDown}
				/>

				{menuVisible && filteredData && (
					<div>
						<MimaText variant="xsmall" mb={0.5}>
							Suggestions
						</MimaText>
						<div className={styles.suggestions__all}>
							{filteredData.map((item, i) => (
								<div
									key={i}
									onClick={e => {
										if (!tags.includes(item.a)) {
											// arr.push(str);
											setTags([...tags, item.a]);
										}
										setInputValue('');
										setMenuVisible(false);
									}}
									className={styles.suggestions}>
									{item.a}
								</div>
							))}
						</div>
					</div>
				)}
			</InnerDivSkeleton>

			{error ? <div className={styles.error}>{touched && error}</div> : null}
		</DivSkeleton>
	);
};

export const MimaTextArea = ({
	labelTitle,
	placeholder,
	onBlur,
	onChange,
	value,
	disabled,
	width,
	height,
	id,
	name,
	mt,
	mr,
	mb,
	ml,
	rows,
	cols,
	touched,
	error,
	fontSize,
	textAreaVariant,
	showPageCount,
	...props
}) => {
	const handleKeyDown = e => {
		let valueLength = e.target.value.length;
		e.target.style.height = 'inherit';
		// e.target.style.height = `${e.target.scrollHeight}px`;
		// // In case you have a limitation
		e.target.style.height = `${Math.min(e.target.scrollHeight, 500)}px`;

		if (valueLength === 0) {
			e.target.style.height = `60px`;
		}
	};

	const errorVariant = useMemo(() => {
		if (textAreaVariant === 'type3') {
			return 'type3Error';
		}

		return 'error';
	}, [textAreaVariant]);

	return (
		<DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml}>
			<label className={styles.form__label}>{labelTitle}</label>
			<TextAreaSkeleton
				placeholder={placeholder}
				onBlur={onBlur}
				disabled={disabled}
				onChange={onChange}
				width={width}
				value={value}
				id={id}
				name={name}
				height={height}
				rows={rows}
				cols={cols}
				fontSize={fontSize}
				onKeyDown={handleKeyDown}
				textAreaVariant={error && touched ? errorVariant : textAreaVariant}
			/>
			{showPageCount ? (
				<div className={styles.pageCount}>
					Page Count: {Math.ceil(value.length / 160)} / {value.length % 160}
				</div>
			) : null}
			{error ? <div className={styles.error}>{touched && error}</div> : null}
		</DivSkeleton>
	);
};

export const MimaDateInput = ({
	labelTitle,
	placeholder,
	onBlur,
	onChange,
	value,
	disabled,
	type,
	variant,
	width,
	height,
	id,
	name,
	mt,
	mr,
	mb,
	ml,
	touched,
	error,
	fontSize,
	showTimeSelect,
	excludeDates,
	excludeTimes,
	excludeWeekends,
	excludePastDays,
	excludeFutureDays,
	...props
}) => {
	// const [selectedDate, setSelectedDate] = useState(null);

	const excludeWeekendsAction = date => {
		const day = date.getDay();
		const isWeekend = day === 6 || day === 0;
		return !isWeekend;
	};

	const pastDays = date => {
		const currentDate = new Date();
		const isPastDay = date < currentDate.setHours(0, 0, 0, 0);
		return !isPastDay;
	};

	const futureDays = date => {
		const currentDate = new Date();
		const isFutureDay = date > currentDate.setHours(0, 0, 0, 0);
		return !isFutureDay;
	};

	const excludeWeekendsPastDays = date => {
		const day = date.getDay();
		const currentDate = new Date();

		// Check if the day is Saturday (6) or Sunday (0)
		const isWeekend = day === 6 || day === 0;

		// Check if the date is in the past
		const isPastDay = date < currentDate.setHours(0, 0, 0, 0);

		return !isWeekend && !isPastDay;
	};

	// const filterto8am = (time) => {
	// 	const currentDate = new Date();
	// 	const selectedDate = new Date(time);

	// 	// Set the start time (12am) and end time (8am) for filtering
	// 	const startTime = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 8, 0, 0);
	// 	const endTime = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59, 59);

	// 	return selectedDate >= startTime && selectedDate <= endTime;
	// };

	return (
		<DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
			<label className={styles.form__label}>{labelTitle}</label>

			<DatePicker
				// selected={selectedDate}
				// onChange={value => setSelectedDate(value)}
				dateFormat={showTimeSelect ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
				showYearDropdown
				scrollableYearDropdown
				placeholderText="dd/mm/yyyy"
				calendarClassName={styles.form__calendar}
				onBlur={onBlur}
				disabled={disabled}
				onChange={onChange}
				selected={value}
				id={id}
				name={name}
				error={error}
				fontSize={fontSize}
				height={height}
				showTimeSelect={showTimeSelect}
				showTimeInput={showTimeSelect}
				excludeTimes={excludeTimes}
				excludeDates={excludeDates}
				excludeWeekends={excludeWeekends}
				excludePastDays={excludePastDays}
				excludeFutureDays={excludeFutureDays}
				filterDate={
					excludeWeekends && excludePastDays
						? excludeWeekendsPastDays
						: excludeWeekends
							? excludeWeekendsAction
							: excludePastDays
								? pastDays
								: excludeFutureDays
									? futureDays
									: props.filterDate
				}
				filterTime={props.filterTime}
				timeIntervals={props.timeIntervals}
				minDate={props.minDate}
				variant={variant}
				wrapperClassName={styles.dateInput}
				customInput={
					<InputSkeleton variant={variant ? variant : 'form'} width={width} />
				}
				{...props}
			/>

			{error ? <div className={styles.error}>{touched && error}</div> : null}
		</DivSkeleton>
	);
};

export const MimaDateFilter = ({
	labelTitle,
	placeholder,
	onBlur,
	onChange,
	value,
	disabled,
	type,
	variant,
	width,
	height,
	id,
	name,
	mt,
	mr,
	mb,
	ml,
	touched,
	error,
	...props
}) => {
	// const [selectedDate, setSelectedDate] = useState(null);

	return (
		<DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
			<label className={styles.form__label}>{labelTitle}</label>

			<DatePicker
				// selected={selectedDate}
				// onChange={value => setSelectedDate(value)}
				dateFormat="dd/MM/yyyy"
				showYearDropdown
				scrollableYearDropdown
				className={styles.form__input_filter}
				placeholderText="dd/mm/yyyy"
				calendarClassName={styles.form__calendar}
				onBlur={onBlur}
				disabled={disabled}
				onChange={onChange}
				selected={value}
				id={id}
				name={name}
				error={error}
				{...props}
			/>

			{error ? <div className={styles.error}>{touched && error}</div> : null}
		</DivSkeleton>
	);
};

const MimaInput = ({
	labelTitle,
	placeholder,
	onBlur,
	onChange,
	value,
	disabled,
	type,
	variant,
	width,
	height,
	id,
	max,
	min,
	name,
	mt,
	mr,
	mb,
	ml,
	rows,
	cols,
	touched,
	error,
	passwordIcon,
	fontSize,
	note,
	...props
}) => {
	const [hide, setHide] = useState(true);

	let eye;
	!hide ? (eye = 'eyeOpen') : (eye = 'eyeClose');

	const showpassword = () => {
		setHide(!hide);
	};

	const errorVariant = useMemo(() => {
		if (variant === 'form' || variant === 'checkoutForm') {
			return 'formError';
		}
		if (variant === 'form2') {
			return 'form2Error';
		}
		return 'error';
	}, [variant]);

	return (
		<DivSkeleton mt={mt} mb={mb} mr={mr} ml={ml} width={width}>
			<label className={styles.form__label}>{labelTitle}</label>
			<WithIconSkeleton>
				<InputSkeleton
					type={passwordIcon && hide ? 'password' : type}
					max={max}
					min={min}
					placeholder={placeholder}
					onBlur={onBlur}
					disabled={disabled}
					onChange={onChange}
					variant={error && touched ? errorVariant : variant}
					width={width}
					onWheel={event => event.currentTarget.blur()}
					value={value}
					id={id}
					name={name}
					height={height}
					rows={rows}
					cols={cols}
					passwordIcon={passwordIcon}
					error={error}
					fontSize={fontSize}
				/>

				{passwordIcon && <IconButton variant={eye} onClick={showpassword} />}
			</WithIconSkeleton>

			{error ? <div className={styles.error}>{touched && error}</div> : null}
			{note ? (
				<div className={styles.note}>
					<BsInfoCircleFill style={{ fontSize: '2rem', marginRight: '1rem' }} />{' '}
					{note}
				</div>
			) : null}
		</DivSkeleton>
	);
};

const InputSkeleton = styled.input`
	${inputStyles.base};
	${props => inputStyles[props.variant]};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
	${props => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
	${touched => (touched ? `color: var(--color-dark)` : '')}
`;

const TextAreaSkeleton = styled.textarea`
	${inputStyles.textArea};
	${props => inputStyles[props.textAreaVariant]};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
	${props => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
	${touched => (touched.id || touched.name ? `color: var(--color-dark)` : '')}
`;

const TagInputSkeleton = styled.input`
	${inputStyles.tagInput};
	${props => (props.width ? `width: ${props.width}rem` : '')};
	${props => (props.height ? `height: ${props.height}rem` : '')};
	${touched => (touched.id || touched.name ? `color: var(--color-dark)` : '')}
`;

const DivSkeleton = styled.div`
	${inputStyles.divBase};
	${props => (props.mt ? `margin-top: ${props.mt}rem` : '')};
	${props => (props.mr ? `margin-right: ${props.mr}rem` : '')};
	${props => (props.mb ? `margin-bottom: ${props.mb}rem` : '')};
	${props => (props.ml ? `margin-left: ${props.ml}rem` : '')};
	${props => (props.fontSize ? `font-size: ${props.fontSize}rem` : '')};
	${props => (props.width ? `width: ${props.width}rem` : '')};
`;

const InnerDivSkeleton = styled.div`
	${inputStyles.innerDivBase};
`;

const WithIconSkeleton = styled.div`
	${inputStyles.pwIcon};
`;

MimaInput.propTypes = propTypes;

MimaInput.defaultProps = defaultProps;

export default MimaInput;
