// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MimaText_label__KkD9R {
  font-size: var(--default-font-small);
  font-family: inherit;
  margin-bottom: 2rem;
  color: var(--color-dark);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/MimaText.module.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;EACA,oBAAA;EACA,mBAAA;EACA,wBAAA;AACD","sourcesContent":[".label {\n\tfont-size: var(--default-font-small);\n\tfont-family: inherit;\n\tmargin-bottom: 2rem;\n\tcolor: var(--color-dark);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `MimaText_label__KkD9R`
};
export default ___CSS_LOADER_EXPORT___;
