import { MimaText } from '../../components';
import styles from '../../assets/styles/PaymentLink/PaymentLinkCheckout.module.scss';
import React from 'react';
import { CiShoppingCart } from 'react-icons/ci';
import OrderSummary from './OrderSummary';
import DeliveryForm from './DeliveryForm';

const PaymentCheckout = ({
	validationSchema,
	onSubmit,
	loading,
	catalogOrderInfo,
	goToCart,
	invoice,
	goToHome,
	clientSessionId,
	stripePromise,
	onSuccess,
	onClose,
	goBack,
	foreignAccount,
	primaryLocation,
}) => {
	return (
		<main>
			<section className={styles.main}>
				<div className={styles.header} id="checkoutHeader">
					{catalogOrderInfo[0].business.logoUrl ? (
						<img
							src={catalogOrderInfo[0].business.logoUrl}
							className={styles.main__img}
							alt="Logo"
							onClick={goToHome}
						/>
					) : (
						<MimaText variant="subtitle" onClick={goToHome}>
							{catalogOrderInfo[0]?.business?.tradingName}
						</MimaText>
					)}
					<CiShoppingCart onClick={goToCart} />
				</div>
				<div className={styles.checkout}>
					<DeliveryForm
						validationSchema={validationSchema}
						onSubmit={onSubmit}
						loading={loading}
						clientSessionId={clientSessionId}
						stripePromise={stripePromise}
						onSuccess={onSuccess}
						onClose={onClose}
						goBack={goBack}
						foreignAccount={foreignAccount}
						invoice={invoice}
						catalogOrderInfo={catalogOrderInfo}
						primaryLocation={primaryLocation}
					/>
					<OrderSummary />
				</div>

				{/* {fashCart?.[0]?.currencyCode === 'NGN' ? (
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											marginTop: '1rem',
										}}>
										<MimaText mb={0.5} mr={1}>
											Paystack Charges:
										</MimaText>
										<MimaText mb={0.5}>
											{amountFormatter(
												fashCart?.[0]?.currencyCode || 'NGN'
											).format(paystackCharge)}
										</MimaText>
									</div>
								) : (
									''
								)} */}

				{/* {shippingInfo?.shippingFee > 0 ? (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<MimaText mb={0.5} mr={1}>
											Shipping Fee:
										</MimaText>
										<MimaText mb={0.5}>
											{calcSubtotalPrice(fashCart) >
												shippingInfo?.freeShippingForOrderAbove &&
											shippingInfo?.freeShippingForOrderAbove > 0
												? 'Free'
												: amountFormatter(
														fashCart?.[0]?.currencyCode || 'NGN'
													).format(shippingInfo?.shippingFee)}
										</MimaText>
									</div>
								) : (
									''
								)} */}
			</section>
		</main>
	);
};

export default PaymentCheckout;
