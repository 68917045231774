import React, { useMemo, useState } from 'react';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';

const SingleCatalogImage = ({ singleStyle, currencyCode }) => {
	const [mainImgUrl, setMainImgUrl] = useState(
		singleStyle?.imageUrls?.[0] || ''
	);
	const [selectedImgIndex, setSelectedImgIndex] = useState(0);

	//Zoom Image Handling

	const [isZoomed, setIsZoomed] = useState(false);
	const [zoomedImagePosition, setZoomedImagePosition] = useState({
		x: 0,
		y: 0,
	});


	const sellingPrice = useMemo(() => {
		return (
			singleStyle?.sellingPrices?.filter(
				x => x?.currencyCode === currencyCode
			)[0] || {}
		);
	}, [singleStyle, currencyCode]);

	return (
		<div className={styles.single__grid__images}>
			<div className={styles.single__grid__images__list}>
				{singleStyle?.imageUrls?.length > 0 ? (
					<>
						{singleStyle?.imageUrls?.map((url, i) => (
							<div
								key={i}
								className={
									selectedImgIndex === i
										? styles.single__grid__images__list__sel
										: ''
								}>
								<img
									src={url}
									alt="Product selectedProduct"
									key={i}
									onClick={() => {
										setMainImgUrl(url);
										setSelectedImgIndex(i);
									}}
								/>
							</div>
						))}
					</>
				) : (
					''
				)}
			</div>
			<div
				className={`${styles.single__grid__images__main} ${isZoomed ? styles.zoomed : ''}`}
				// onMouseEnter={() => setIsZoomed(true)}
				// onMouseLeave={() => setIsZoomed(false)}
				// onMouseMove={handleMouseMove}
			>
				<img
					src={mainImgUrl || singleStyle?.imageUrls?.[0]}
					alt="Product selectedProduct"
				/>
				{sellingPrice?.onSaleSlashPrice > 0 ? (
					<div className={styles.catalog__embla__onSale}>SALE</div>
				) : (
					''
				)}
				{isZoomed && (
					<div
						className={styles.zoomed__image}
						style={{
							backgroundImage: `url(${mainImgUrl})`,
							backgroundPosition: `${zoomedImagePosition.x * 100}% ${zoomedImagePosition.y * 100}%`,
						}}></div>
				)}
			</div>
		</div>
	);
};

export default SingleCatalogImage;
