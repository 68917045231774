import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
export default class CatalogStore {
	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: 'CatalogStore',
			properties: [
				'singleCatalog',
				'catalogOrderInfo',
				'collections',
				'locationInfo',
				'primaryLocation',
				'onePublicCatalog',
				'styleList',
				'shippingInfo',
				'exchangeRates',
				'newShippingInfo',
				'exchangeRateTimes',
				'userTheme',
				'nextCollectionsCall',
				'metaDetails',
				'editMode',
				'themeData',
				'themeId',
				'configThemeData',
				'webConfig',
			],
		});
	}

	singleCatalog = {};
	catalogOrderInfo = [];
	collections = [];
	locationInfo = {};
	primaryLocation = '';
	onePublicCatalog = {};
	styleList = [];
	shippingInfo = {};
	exchangeRates = {};
	newShippingInfo = {};
	exchangeRateTimes = {};
	userTheme = {};
	nextCollectionsCall = '';
	metaDetails = {};
	editMode = false;
	themeData = {};
	configThemeData = {};
	themeId = '';
	webConfig = {};

	reset = () => {
		this.singleCatalog = {};
		this.catalogOrderInfo = [];
		this.collections = [];
		this.locationInfo = {};
		this.primaryLocation = '';
		this.onePublicCatalog = {};
		this.styleList = [];
		this.shippingInfo = {};
		this.exchangeRates = {};
		this.newShippingInfo = {};
		this.exchangeRateTimes = {};
		this.userTheme = {};
		this.nextCollectionsCall = '';
		this.metaDetails = {};
		this.editMode = false;
		this.themeData = {};
		this.configThemeData = {};
		this.themeId = '';
		this.webConfig = {};
	};

	setSingleCatalog = async state => {
		runInAction(async () => {
			this.singleCatalog = state;
		});
	};

	setCatalogOrderInfo = async state => {
		runInAction(async () => {
			this.catalogOrderInfo = state;
		});
	};

	setCollections = async state => {
		runInAction(async () => {
			this.collections = state;
		});
	};

	setLocationInfo = async state => {
		runInAction(async () => {
			this.locationInfo = state;
		});
	};

	setPrimaryLocation = async state => {
		runInAction(async () => {
			this.primaryLocation = state;
		});
	};

	setOnePublicCatalog = async state => {
		runInAction(async () => {
			this.onePublicCatalog = state;
		});
	};
	setStyleList = async state => {
		runInAction(async () => {
			this.styleList = state;
		});
	};
	setShippingInfo = async state => {
		runInAction(async () => {
			this.shippingInfo = state;
		});
	};
	setExchangeRates = async state => {
		runInAction(async () => {
			this.exchangeRates = state;
		});
	};
	setNewShippingInfo = async state => {
		runInAction(async () => {
			this.newShippingInfo = state;
		});
	};
	setExchangeRateTimes = async state => {
		runInAction(async () => {
			this.exchangeRateTimes = state;
		});
	};
	setUserTheme = async state => {
		runInAction(async () => {
			this.userTheme = state;
		});
	};
	setNextCollectionsCall = async state => {
		runInAction(async () => {
			this.nextCollectionsCall = state;
		});
	};
	setMetaDetails = async state => {
		runInAction(async () => {
			this.metaDetails = state;
		});
	};
	setEditMode = async state => {
		runInAction(async () => {
			this.editMode = state;
		});
	};
	setThemeData = async state => {
		runInAction(async () => {
			this.themeData = state;
		});
	};
	setConfigThemeData = async state => {
		runInAction(async () => {
			this.configThemeData = state;
		});
	};
	setThemeId = async state => {
		runInAction(async () => {
			this.themeId = state;
		});
	};
	setWebConfig = async state => {
		runInAction(async () => {
			this.webConfig = state;
		});
	};
}
