import React, { useEffect, useMemo, useState } from 'react';
import { IoIosSearch } from 'react-icons/io';
import CatalogCountryCurrency from './CatalogCountryCurrency';
import styles from '../../assets/styles/Catalogue/Catalogue.module.scss';
import constant from '../../utils/constant';
import { observer } from 'mobx-react';
import { BsCart3 } from 'react-icons/bs';
import CollectionInHeaderMenu from './CollectionInHeaderMenu';
import CollectionInShopMenu from './CollectionInShopMenu';
import { useNavigate } from 'react-router-dom';
import CatalogSearch from './CatalogSearch';

const CatalogHomeHeader = ({
	setCollectionQuery,
	filtering,
	setFiltering,
	setSearchQuery,
	data,
	setCurrencyCode,
	showSearch,
	setShowSearch,
}) => {
	const [fashCart, setFashCart] = useState([]);

	useEffect(() => {
		const storedFashCart = JSON.parse(localStorage.getItem('fashCart')) || [];
		setFashCart(storedFashCart);
	}, []);

	const showSearchHandler = () => {
		setShowSearch(!showSearch);
	};

	const businessName = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles?.[0]?.business.tradingName || '';
		}
		return '';
	}, [data]);

	const businessLogo = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.styles?.[0]?.business?.logoUrl || '';
		}
		return '';
	}, [data]);

	const webSettings = useMemo(() => {
		if (data?.status === constant.Success) {
			return data?.data?.websiteSettings || {};
		}
		return '';
	}, [data]);

	const navigate = useNavigate();

	const goToCart = () => {
		navigate(constant.Routes.WebsiteCart);
	};

	return (
		<div className={styles.catalog__header}>
			<div className={styles.catalog__header__inner}>
				<div>
					<CatalogCountryCurrency setCurrencyCode={setCurrencyCode} />
				</div>
				<div className={styles.catalog__header__business}>
					{businessLogo ? <img src={businessLogo} alt="logo" /> : ''}
					<div className={styles.catalog__header__business__name}>
						{businessName.toUpperCase()}
					</div>
				</div>
				<div>
					<div className={styles.catalog__icons}>
						<div
							onClick={showSearchHandler}
							className={styles.catalog__search__icon}>
							<IoIosSearch />
						</div>
						<div onClick={goToCart} className={styles.cartIcon}>
							<BsCart3 />
							{fashCart?.length > 0 ? (
								<div className={styles.cartIcon__nos}>{fashCart?.length}</div>
							) : (
								''
							)}
						</div>
					</div>
				</div>
				{showSearch ? (
					<CatalogSearch
						filtering={filtering}
						setFiltering={setFiltering}
						setSearchQuery={setSearchQuery}
						showSearchHandler={showSearchHandler}
					/>
				) : (
					''
				)}
			</div>
			{webSettings?.content?.collectionsMenuStyle ===
			constant.fashStyle.collectionInHeaderMenu ? (
				<CollectionInHeaderMenu
					setCollectionQuery={setCollectionQuery}
					webSettings={webSettings}
				/>
			) : (
				<CollectionInShopMenu
					setCollectionQuery={setCollectionQuery}
					webSettings={webSettings}
					data={data}
				/>
			)}
		</div>
	);
};

export default observer(CatalogHomeHeader);
