import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { catalogStore } from '../../stores';
// import fallbackFavicon from '../../assets/img/favicon.ico';

const MetaTags = ({ title, metas = [], canonicalPath, structuredData }) => {
	const getCurrentDomain = () => {
		return window.location.origin;
	};

	const currentDomain = getCurrentDomain();
	const canonicalUrl = `${currentDomain}${canonicalPath}`;

	const { metaDetails } = catalogStore;

	const favicon = useMemo(() => {
		return metaDetails?.favicon || '';
	}, [metaDetails]);

	return (
		<Helmet>
			{title ? <title>{title}</title> : ''}
			{metas?.length > 0
				? metas.map((meta, index) => <meta key={index} {...meta} />)
				: ''}
			<meta property="og:type" content="website" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:image:alt" content="" />

			{canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : ''}
			<link rel="icon" href={favicon} />
			<meta name="twitter:image" content={favicon} />
			{structuredData ? (
				<script type="application/ld+json">
					{JSON.stringify(structuredData)}
				</script>
			) : (
				''
			)}
		</Helmet>
	);
};

export default MetaTags;
