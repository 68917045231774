import styles from '../../assets/styles/Catalogue/StyleCatalogue.module.scss';
import { MimaText, PageLoader } from '../../components';
import React, { useMemo } from 'react';
import Footer from './Footer';
import CatalogHomeHeader from './CatalogHomeHeader';
import { useCatalogUtility } from '../../hooks';

const About = () => {
	const {
		filtering,
		setFiltering,
		setCollectionQuery,
		data,
		setSearchQuery,
		setCurrencyCode,
		showSearch,
		setShowSearch,
		isLoading,
	} = useCatalogUtility();

	const aboutImg = useMemo(() => {
		return data?.data?.websiteSettings?.content?.aboutUs?.imageUrl || '';
	}, [data]);
	const aboutText = useMemo(() => {
		return data?.data?.websiteSettings?.content?.aboutUs?.text || '';
	}, [data]);
	const businessName = useMemo(() => {
		return data?.data?.websiteSettings?.business?.tradingName || '';
	}, [data]);

	return (
		<main>
			<CatalogHomeHeader
				filtering={filtering}
				setFiltering={setFiltering}
				setCollectionQuery={setCollectionQuery}
				data={data}
				setSearchQuery={setSearchQuery}
				setCurrencyCode={setCurrencyCode}
				showSearch={showSearch}
				setShowSearch={setShowSearch}
			/>
			{isLoading ? (
				<div style={{ marginTop: '15rem' }}>
					<PageLoader />
				</div>
			) : (
				<>
					<section className={styles.content}>
						<MimaText variant="h2" align="center" mb={3}>
							About Us
						</MimaText>{' '}
						<div className={styles.about}>
							<div>
								<img src={aboutImg} alt="About Us" />
							</div>
							<div className={styles.about__text}>
								<MimaText variant="bodyBold">About {businessName} </MimaText>
								<p>{aboutText}</p>
							</div>
						</div>
					</section>
					<Footer data={data} />
				</>
			)}
		</main>
	);
};

export default About;
