import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { StyleCatalogController } from '../../controllers';
import constant from '../../utils/constant';
import { MimaToastUtil } from '../../components';
import { catalogStore } from '../../stores';
import { updateCartWithCurrencyCheck } from '../../utils/utils';

const useProduct = (setFashCart, switchedPrice, currencyCode) => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(false);
	const [loading3, setLoading3] = useState(false);
	const [rdModal, setRDModal] = useState(false);
	const [initData, setInitData] = useState({});

	const closeModal = () => {
		setRDModal(false);
	};

	// const subdomain = window.location.hostname.split('.')[0];
	const hostname = window.location.hostname;
	const fashSubdomain = hostname.split('.')[0];
	const domainParts = hostname.split('.');
	const customDomain = domainParts.length > 2 ? domainParts[1] : domainParts[0];
	const isCustomDomain = !['fash.style', 'localhost'].some(domain =>
		hostname.includes(domain)
	);

	const subdomain = useMemo(() => {
		if (isCustomDomain) {
			return customDomain;
		}

		return fashSubdomain;
	}, [isCustomDomain, customDomain, fashSubdomain]);
	const { slug } = useParams();

	const query = useMemo(() => {
		return `slug=${slug}&subDomain=${subdomain}`;
	}, [slug, subdomain]);

	useEffect(() => {
		const getSingleCatalog = async () => {
			setLoading(true);
			const { data, errorMessage } =
				await StyleCatalogController.getSingleStyleCatalog(query);

			if (errorMessage === constant.errorMsg.styleNotFound) {
				return navigate(constant.Routes.Error404);
			}
			setInitData(data);
			setLoading(false);
		};

		getSingleCatalog();
	}, [query]);

	const goToCatalogHome = () => {
		navigate(constant.Routes.Home);
	};

	const validationSchema = yup.object().shape({
		action: yup.string().optional(),
		validate: yup.boolean().optional(),
		amount: yup.number().when('validate', {
			is: true,
			then: yup
				.number()
				.required('Amount is required')
				.positive('Amount must be positive')
				.min(1, 'Amount is required'),
			otherwise: yup.number().optional(),
		}),
		amountPerItem: yup.number().when('validate', {
			is: true,
			then: yup
				.number()
				.required('Select Price')
				.positive('Price must be positive')
				.min(1, 'Select Price'),
			otherwise: yup.number().optional(),
		}),
		quantity: yup
			.number()
			.positive('Quantity must be positive')
			.min(1, 'Quantity is required')
			.required('Quantity is required'),
		standardSize: yup.string().when('hasSize', {
			is: true,
			then: yup.string().required('Size is required'),
			otherwise: yup.string().optional(),
		}),
		color: yup.object().when('hasColor', {
			is: true,
			then: yup.object().shape({
				title: yup.string().required('Color is required'),
				code: yup.string().optional(),
			}),
			otherwise: yup.object().optional(),
		}),
		accessories: yup.string().optional(),
		// Additional fields and validations
	});

	const onSubmit = values => {
		const storedFashCart = JSON.parse(localStorage.getItem('fashCart')) || [];

		let fashCart;

		if (values.action === 'ADD TO CART') {
			setLoading2(true);
			const differentCurrency = storedFashCart.some(
				item => item.currencyCode !== values.currencyCode
			);
			if (differentCurrency) {
				setLoading2(false);

				fashCart = updateCartWithCurrencyCheck(
					storedFashCart,
					values,
					true,
					switchedPrice
				);
			} else {
				fashCart = updateCartWithCurrencyCheck(
					storedFashCart,
					values,
					false,
					switchedPrice
				);
			}

			localStorage.setItem('fashCart', JSON.stringify(fashCart));
			setFashCart(fashCart);
			MimaToastUtil.success({ message: 'Added to Cart' });
			setLoading2(false);
			return;
		}

		if (values.action === 'MAKE PAYMENT') {
			setLoading2(true);
			const differentCurrency = storedFashCart.some(
				item => item.currencyCode !== values.currencyCode
			);
			if (differentCurrency) {
				setLoading2(false);

				fashCart = updateCartWithCurrencyCheck(
					storedFashCart,
					values,
					true,
					switchedPrice
				);
			} else {
				fashCart = updateCartWithCurrencyCheck(
					storedFashCart,
					values,
					false,
					switchedPrice
				);
			}
			localStorage.setItem('fashCart', JSON.stringify(fashCart));
			setFashCart(fashCart);
			MimaToastUtil.success({ message: 'Added to Cart' });
			navigate(constant.Routes.WebsiteCart);

			setLoading2(false);
		}

		if (values.action === 'BOOK CONSULTATION') {
			setLoading3(true);
			catalogStore.setCatalogOrderInfo(values);
			window.open(catalogStore.singleCatalog?.bookingLinkUrl, '_self');
			setLoading3(false);
		}

		if (values.action === 'REQUEST DESIGN') {
			setLoading3(true);
			catalogStore.setCatalogOrderInfo(values);
			setRDModal(true);
			setLoading3(false);
		}
	};

	const sellingPrice = useMemo(() => {
		return (
			initData?.sellingPrices?.filter(
				x => x?.currencyCode === currencyCode
			)[0] || {}
		);
	}, [initData, currencyCode]);

	const convertedPrice = useMemo(() => {
		return switchedPrice(
			sellingPrice?.currencyCode,
			sellingPrice?.withMaterial
				? sellingPrice?.withMaterial
				: sellingPrice?.withoutMaterial
		);
	}, [
		sellingPrice?.currencyCode,
		sellingPrice?.withMaterial,
		sellingPrice?.withoutMaterial,
		switchedPrice,
	]);

	const convertedPromoPrice = useMemo(() => {
		return switchedPrice(
			sellingPrice?.currencyCode,
			sellingPrice?.onSaleSlashPrice
		);
	}, [
		sellingPrice?.currencyCode,
		sellingPrice?.onSaleSlashPrice,
		switchedPrice,
	]);

	const convertedWithMaterialPrice = useMemo(() => {
		return switchedPrice(
			sellingPrice?.currencyCode,
			sellingPrice?.withMaterial
		);
	}, [sellingPrice, switchedPrice]);

	const convertedWithoutMaterialPrice = useMemo(() => {
		return switchedPrice(
			sellingPrice?.currencyCode,
			sellingPrice?.withoutMaterial
		);
	}, [sellingPrice, switchedPrice]);

	const initialValues = useMemo(() => {
		return {
			amountPerItem:
				convertedPromoPrice?.price > 0
					? convertedPromoPrice?.price
					: convertedPrice?.price
						? convertedPrice?.price
						: 0,
			quantity: 1,
			business: initData?.business || {},
			amount:
				convertedPromoPrice?.price > 0
					? convertedPromoPrice?.price
					: convertedPrice?.price
						? convertedPrice?.price
						: 0,
			standardSize: '',
			action: '',
			currencyCode: convertedPrice?.currencyCode,
			name: initData?.name || '',
			styleId: initData?._id || '',
			withMaterial: sellingPrice?.withMaterial
				? 'with'
				: sellingPrice?.withoutMaterial
					? 'without'
					: '0',
			hasSize:
				initData?.sizesAvailable?.length > 0 || initData?.sizes?.length > 0
					? true
					: false,
			hasColor: initData?.colorsAvailable?.length > 0 ? true : false,
			color: {
				title: '',
				code: '',
			},
			accessory: '',
			imageUrl: initData?.imageUrls?.[0] || '',
			validate: true,
			sellingPrices: initData?.sellingPrices,
		};
	}, [convertedPrice, convertedPromoPrice, initData, sellingPrice]);

	const defaultThemesInitialValues = useMemo(() => {
		return {
			amountPerItem:
				convertedPromoPrice?.price > 0
					? convertedPromoPrice?.price
					: convertedWithMaterialPrice?.price
						? convertedWithMaterialPrice?.price
						: convertedWithoutMaterialPrice?.price
							? convertedWithoutMaterialPrice?.price
							: 0,
			quantity: 1,
			business: initData?.business || {},
			amount:
				convertedPromoPrice?.price > 0
					? convertedPromoPrice?.price
					: convertedWithMaterialPrice?.price
						? convertedWithMaterialPrice?.price
						: convertedWithoutMaterialPrice?.price
							? convertedWithoutMaterialPrice?.price
							: 0,
			standardSize: '',
			action: '',
			currencyCode: convertedWithMaterialPrice?.currencyCode,
			name: initData?.name || '',
			styleId: initData?._id || '',
			withMaterial: sellingPrice?.withMaterial
				? 'with'
				: sellingPrice?.withoutMaterial
					? 'without'
					: '0',
			hasSize:
				initData?.sizesAvailable?.length > 0 || initData?.sizes?.length > 0
					? true
					: false,
			hasColor: initData?.colorsAvailable?.length > 0 ? true : false,
			color: {
				title: '',
				code: '',
			},
			accessory: '',
			imageUrl: initData?.imageUrls?.[0] || '',
			validate: true,
			sellingPrices: initData?.sellingPrices,
		};
	}, [
		convertedPromoPrice,
		convertedWithMaterialPrice,
		convertedWithoutMaterialPrice,
		initData,
		sellingPrice,
	]);

	return {
		goToCatalogHome,
		onSubmit,
		loading,
		loading2,
		loading3,
		closeModal,
		rdModal,
		initData,
		validationSchema,
		initialValues,
		sellingPrice,
		convertedPrice,
		convertedPromoPrice,
		convertedWithMaterialPrice,
		convertedWithoutMaterialPrice,
		defaultThemesInitialValues,
	};
};

export default useProduct;
