import DotLoader from 'react-spinners/DotLoader';
import { BarLoader } from 'react-spinners';
import MimaText from '../MimaText';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	title: PropTypes.string,
	loading: PropTypes.bool,
	disabled: PropTypes.bool,
	loaderColor: PropTypes.string,
	size: PropTypes.number,
	variant: PropTypes.oneOf(['dark', 'light']),
	type: PropTypes.oneOf(['bar', 'dot']),
};

const defaultProps = {
	title: '',
	loading: true,
	disabled: false,
	size: 50,
	variant: 'dark',
	type: 'dot',
};

const loaderContainer = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-evenly',
	alignItems: 'center',
	marginTop: '3rem',
};

const PageLoader = ({ title, loading, size, variant, type, ...props }) => {
	return (
		<>
			{type === 'dot' && (
				<>
					{variant === 'dark' && (
						<div style={loaderContainer}>
							<MimaText variant="subtitleBold"> Please Wait</MimaText>
							<MimaText mb={3}> {title}</MimaText>
							<DotLoader
								color={`var(--color-dark)`}
								loading={loading}
								size={size}
							/>
						</div>
					)}

					{variant === 'light' && (
						<div style={loaderContainer}>
							<MimaText variant="subtitleBold" color="var(--color-white)">
								{' '}
								Please Wait
							</MimaText>
							<MimaText mb={3} color="var(--color-white)">
								{' '}
								{title}
							</MimaText>
							<DotLoader
								color={`var(--color-white)`}
								loading={loading}
								size={size}
							/>
						</div>
					)}
				</>
			)}

			{type === 'bar' && (
				<>
					{variant === 'dark' && (
						<div style={loaderContainer}>
							<MimaText variant="bodyBold"> Please Wait</MimaText>
							<MimaText mb={3}> {title}</MimaText>
							<BarLoader
								color={`var(--color-dark)`}
								loading={loading}
								size={size}
							/>
						</div>
					)}

					{variant === 'light' && (
						<div style={loaderContainer}>
							<MimaText variant="subtitleBold" color="var(--color-white)">
								{' '}
								Please Wait
							</MimaText>
							<MimaText mb={3} color="var(--color-white)">
								{' '}
								{title}
							</MimaText>
							<BarLoader
								color={`var(--color-white)`}
								loading={loading}
								size={size}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
};

PageLoader.propTypes = propTypes;

PageLoader.defaultProps = defaultProps;

export default PageLoader;
